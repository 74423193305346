import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function HowItWorks() {
    const { t } = useTranslation();
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <section>
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{t('How it works')}</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">{t('Home')}</Link></li>
                                <li>{t('How it works')}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div className="section-full p-t50 p-b50 site-bg-white twm-how-it-work-area">
                <div className="container">
                    {/* <div className="section-head center wt-small-separator-outer">
                        <h2 className="wt-title">How it works</h2>
                    </div> */}

                    <div className="twm-how-it-work-section">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <div className="twm-w-process-steps">
                                    <span className="twm-large-number">01</span>
                                    <div className="twm-w-pro-top bg-clr-sky">
                                        <div className="twm-media">
                                            <span><img src="assets/images/work-process/icon1.png" alt="icon1" /></span>
                                        </div>
                                        <h4 className="twm-title">{t('Select')}<br />{t('e-vignette')}</h4>
                                    </div>
                                    <p>{t('Enter vehicle category and registration number.')}</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <div className="twm-w-process-steps">
                                    <span className="twm-large-number">02</span>
                                    <div className="twm-w-pro-top bg-clr-pink">
                                        <div className="twm-media">
                                            <span>
                                                <img src="assets/images/work-process/icon2.png" alt="icon1" /></span>
                                        </div>
                                        <h4 className="twm-title">{t('Pay')} <br /> {t('securely online')}</h4>
                                    </div>
                                    <p>{t('Pay tolls simply and securely online')}.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <div className="twm-w-process-steps">
                                    <span className="twm-large-number">03</span>
                                    <div className="twm-w-pro-top bg-clr-green">
                                        <div className="twm-media">
                                            <span><img src="assets/images/work-process/icon3.png" alt="icon1" /></span>
                                        </div>
                                        <h4 className="twm-title">{t('Set off with')} <br />{t('no worries')}</h4>
                                    </div>
                                    <p>{t('Receive the e-vignette immediately by email or text message.')}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12">
                                <div className="cabdidate-de-info">
                                    <h4 className="twm-s-title">{t('Register vehicle')}</h4>
                                    <div className="twm-timing-list-wrap">
                                        <div className="twm-timing-list">
                                            {/* <div className="twm-time-list-date">2012 to 2016</div> */}
                                            <div className="twm-time-list-title">{t('First step')}</div>
                                            <div className="twm-time-list-position">{t('The information required includes, for example, the registration number and the vehicle category (car, trailer, bus, etc.).')}</div>
                                            <div className="twm-time-list-discription">
                                                <p>{t('The information required includes, for example, the registration number and the vehicle category (car, trailer, bus, etc.).')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <h4 className="twm-s-title">{t('Select')}<br/>{t('e-vignette')}</h4>
                                    <div className="twm-timing-list-wrap">
                                        <div className="twm-timing-list">
                                            {/* <div className="twm-time-list-date">2004 to 2006</div> */}
                                            <div className="twm-time-list-title">{t('Second step')}</div>
                                            <div className="twm-time-list-position">{t('The second step is to select the vignette you want. You can choose from different vignette validity periods, such as 10 days or 1 year. Various validity periods are offered depending on the country.')}</div>
                                            <div className="twm-time-list-discription">
                                                <p>{t('If you do not intend to set off on the day of registration but at a later date, you can also select a date from which the e-vignette becomes valid (start date).')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <h4 className="twm-s-title">{t('Pay online and hit the road')}</h4>
                                    <div className="twm-timing-list-wrap">
                                        <div className="twm-timing-list">
                                            {/* <div className="twm-time-list-date">2004 to 2006</div> */}
                                            <div className="twm-time-list-title">{t('Final step')}</div>
                                            <div className="twm-time-list-position">{t('The final step is to pay the fee. The cost of the registration service is based on the selected period of validity for the e-vignette. The total price due is calculated and displayed in the registration process.')}</div>
                                            <div className="twm-time-list-discription">
                                                <p>{t('You can pay via PayPal and with all major credit cards. Following payment, registration of your number plate will be completed in a few moments. We wish you a safe journey at all times!')}</p>
                                                <p>{t('Please note that once your vignette has been successfully registered, it is not possible to withdraw from the contract. Click here for further information.')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-md-12 text-center">
                                <Link to="/home">
                                    <button type="button" className="site-button">{t('Register for an e-vignette now')}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};
