import './App.css';
import Home from './pages/home';
import Footer from './components/footer';
import Header from './components/header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about';
import React, { useState, useEffect } from 'react';
import Blog from './pages/blog';
import Employers from './pages/employers';
import Candidates from './pages/candidates';
import Jobs from './pages/jobs';
import Order from './pages/order';
import Thankyou from './pages/thank.js';
import HowItWorks from './pages/footer_pages/how_it_works';
import Affiliate from './pages/footer_pages/affiliate';
import AutomaticRenewal from './pages/footer_pages/automatic_renewal';
import Careers from './pages/footer_pages/careers';
import CustomerAccount from './pages/footer_pages/customer_account';
import Faqs from './pages/footer_pages/faqs';
import FlexService from './pages/footer_pages/flex-service';
import HelpSupport from './pages/footer_pages/help_support';
import SearchOrder from './pages/footer_pages/search_order';
import TermsConditions from './pages/footer_pages/terms_conditions';
import InfoConsumers from './pages/footer_pages/info_consumers';
import PrivacyNotice from './pages/footer_pages/privacy_notice';
import CookieSettings from './pages/footer_pages/cookie_settings';
import CompanyInfo from './pages/footer_pages/company_info';
import Login from './pages/login';
import Forgetpassword from './pages/forgetpassword'
import Registration from './pages/registration';
import Dashboard from './pages/dashboard_pages/my-dashboard';
import MyOrders from './pages/dashboard_pages/my-orders.js';
import MyProfile from './pages/dashboard_pages/my-profile.js';
import ChangeEmail from './pages/dashboard_pages/change-email.js';
import AddVehicle from './pages/dashboard_pages/add-vehicle.js';
// import Myaddress from './pages/dashboard_pages/my-profile.js';
import AutomaticExtensions from './pages/dashboard_pages/automatic-extensions.js';
import PaymentMethods from './pages/dashboard_pages/payment-methods.js';
import { ToastContainer } from 'react-toastify';
import AppContext from './appContext.js'
import Auth from './authguard'
import { readRecord } from "./Redux/Action/Action";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [defaultCurrency, setDefaultCurrency] = useState({});
  const [exchangeRates, setExchangeRates] = useState({});
  const [orderSaveAsDraft, setOrderSaveAsDraft] = useState({});
  const [loggedUser, setLoggedUser] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('vignetteLoginUser')) {
      const loginUser = JSON.parse(localStorage.getItem('vignetteLoginUser'));
      setIsLoggedIn(true);
      getUserDetials(loginUser);
    }
  }, [])

  const getUserDetials = (loginUser) => {
    let Body = {
      "table": "customers",
      "selectFields": {},
      "whereCondition": {
        "_id": loginUser._id
      },
      "sortBy": {},
      "limit": "1",
      "populate": [],
      "page": "1"
    }
    readRecord(Body).then(async (res) => {
      if (res.noOfRecords) {
        setLoggedUser(res.data[0])
      } else {
        logout();
      }
    })
  }

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
  }
  
  function PageNotFound() {
    return (
      <div className="page-content">
      <div className="wt-bnr-inr overlay-wraper bg-center">
          <div className="overlay-main site-bg-white opacity-01"></div>
          <div className="container">
              <div className="wt-bnr-inr-entry">
                  <div className="banner-title-outer">
                      <div className="banner-title-name">
                          <h2 className="wt-title">Page Not Found</h2>
                      </div>
                  </div>
                  <div>
                      <ul className="wt-breadcrumb breadcrumb-style-2">
                          <li><Link to="/home">{t('Home')}</Link></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      </div>
     );
  }

  return (
    <div className="App">
      <AppContext.Provider value={{ isLoggedIn, setIsLoggedIn, defaultCurrency, setDefaultCurrency, exchangeRates, setExchangeRates, orderSaveAsDraft, setOrderSaveAsDraft, loggedUser, setLoggedUser }}>
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/job" element={<Jobs />} />
            <Route path="/employer" element={<Employers />} />
            <Route path="/candidate" element={<Candidates />} />
            <Route path="/order" element={<Order />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/affiliate" element={<Affiliate />} />
            <Route path="/automatic_renewal" element={<AutomaticRenewal />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/customer_account" element={<CustomerAccount />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/info-consumers" element={<InfoConsumers />} />
            <Route path="/privacy-notice" element={<PrivacyNotice />} />
            <Route path="/cookie-settings" element={<CookieSettings />} />
            <Route path="/company-info" element={<CompanyInfo />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/flex_service" element={<FlexService />} />
            <Route path="/help_support" element={<HelpSupport />} />
            <Route path="/search_order" element={<SearchOrder />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
            <Route path="/dashboard" element={<Auth Component={Dashboard} />}>
              <Route path="orders" element={<Auth Component={MyOrders} />} />
              <Route path="change-email" element={<Auth Component={ChangeEmail} />} />
              <Route path="add-vehicle" element={<Auth Component={AddVehicle} />} />
              <Route path="my-profile" element={<Auth Component={MyProfile} />} />
              {/* <Route path="my-address" element={<Auth Component={Myaddress} />} /> */}
              <Route path="automatic-extensions" element={<AutomaticExtensions />} />
              <Route path="payment-methods" element={<PaymentMethods />} />
            </Route>
             <Route path="*" element={<PageNotFound />} /> 
          </Routes>
          <ToastContainer />
          <Footer />

        </Router>
      </AppContext.Provider>
    </div>
  );
}


export default App;
