import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { readRecord, readRecordJoin, updateRecord, homedata, formatAmount } from "../../Redux/Action/Action";
import { successToast, errorToast, warningToast } from '../../components/toastify'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import AppContext from '../../appContext';
import { useTranslation } from 'react-i18next';
import data from '../../data.json'; 
const licensePlateData = require('../../assets/files/licensePlate.json');

export default function MyOrders() {
   const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";
   const licensePlatePattern = /^(?=.*[A-Za-z\d])[A-Za-z\d—ÜÖÄČŠŽĆĐ-]{1,15}$/;
   let maxDate = new Date();
   maxDate.setDate(maxDate.getDate() + 30);
   let minDate = new Date();
   minDate.setDate(minDate.getDate() + 1);
   const { exchangeRates } = useContext(AppContext);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { t } = useTranslation();
   const [show1, setShow1] = useState(false); // edit order modal
  
   const [myAllOrder, setMyAllOrder] = useState([]);
   const [orderDetail, setOrderDetail] = useState({});
   const [editOrderDetail, setEditOrderDetail] = useState({});
   const [noOfRecords, setNoOfRecords] = useState(0);
   const [cancelReason, setCancelReason] = useState({
      cancel_reason: '',
      agree: false
   });
   const [isLoading, setIsLoading] = useState(true);

   // Pagination
   const [itemsPerPage] = useState(10);
   const [currentItems, setCurrentItems] = useState([]);

   let loginUser = {};
   if (localStorage.getItem('vignetteLoginUser')) {
      loginUser = JSON.parse(localStorage.getItem('vignetteLoginUser'))
   }

   // ReOrder
   const [countryOptions, setCountry] = useState(data.Countries);
   const [allVehicles, setAllVehicles] = useState(data.Vehicles);
   const [allDurations, setAllDurations] = useState(data.Durations);
   const [durationOptions, setDuration] = useState([]);

   const [selectedCountry, setSelectedCountry] = useState({ _id: '', value: '', filename: null, processing: null });
   const [selectedVehicle, setSelectedVehicle] = useState({ _id: '', value: '', filename: null });
   const [selectedDuration, setSelectedDuration] = useState({ _id: '', value: '', absolute_value: 1, price: 0 });
   const [license_plate, setLicense_plate] = useState({ license_plate: "", clicense_plate: "" });
   const [licensePlateerror, setLicensePlateerror] = useState(false);
   const [licensePlateerror1, setLicensePlateerror1] = useState(false);
   const [isLoading1, setIsLoading1] = useState(true);
   const [startDate, setStartDate] = useState(new Date());
   const [tempStartDate, setTempStartDate] = useState(null); // It's temp date
   const [benefitsBy, setBenefitsBy] = useState("");
   const [flexiChange, setFlexiChange] = useState(0);
   const [flexiTepmChange, setTepmFlexiChange] = useState(0);
   const [flexiObj, setFlexiObj] = useState({});
   const [timeStamp, setTimeStamp] = useState("");
   const [registrationCountry, setRegistrationCountry] = useState(null);


   useEffect(() => {
      for (let i in licensePlateData) {
         licensePlateData[i].value = licensePlateData[i].id;
      }
      getMyOrder();
   }, []);

   useEffect(() => {
   }, [selectedVehicle]);

   function calcEndDate(startDate2, durationName) {
      let end_date = new Date(startDate2);
      if (durationName === "Weekly") {
          end_date.setDate(startDate2.getDate() + 7);
      }
      if (durationName === "Monthly") {
          end_date.setDate(startDate2.getDate() + 30);
      }
      if (durationName === "Quarterly") {
          end_date.setDate(startDate2.getDate() + 90);
      }
      if (durationName === "Yearly" || durationName == 'Annual') {
          end_date.setDate(startDate2.getDate() + 365);
      }
      if (durationName == "2-month") {
          end_date.setDate(startDate2.getDate() + 60);
      }
      if (durationName == "10-days") {
          end_date.setDate(startDate2.getDate() + 10);
      }
      if (durationName == "1-day") {
          end_date.setDate(startDate2.getDate() + 1);
      }
      if (durationName == "Weekend") {
          end_date.setDate(startDate2.getDate() + 2);
      }
      console.log("End date is now calculated as",end_date);
      return end_date;
   }

   const getMyOrder = () => {
      let todayDate = new Date();
      setTempStartDate(todayDate.toLocaleDateString('en-GB', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric'
      }));
      let Body = {
         "table": "orders",
         "selectFields": {},
         "whereCondition": {
            "customer": loginUser._id
         },
         "sortBy": {
            "createdAt": "-1"
         },
         "limit": "0",
         "populate": [{ path: 'country', select: 'value code' }, { path: 'duration', select: 'value code absolute_value price' }, { path: 'vehicle_type', select: 'value code filename' }],
         "page": "1"
      }
      readRecord(Body).then(async (res) => {

         for (let i in res.data) {
            if (res.data[i].vehicle_country_of_registration) {
               res.data[i].vehicle_country_of_registration = JSON.parse(res.data[i].vehicle_country_of_registration);
            }
            if (res.data[i].digi_partners_sale) {
               res.data[i].digi_partners_sale = JSON.parse(res.data[i].digi_partners_sale);
            }
            const start_date = new Date((res.data[i].start_date).split('/').reverse().join('/'));
            const end_date = new Date((res.data[i].end_date).split('/').reverse().join('/'));
            if (res.data[i].order_status === "Cancelled") {
               res.data[i].order_status = "Cancelled";
            } else if (todayDate >= start_date && todayDate <= end_date) {
               res.data[i].order_status = "Active";
            } else if (todayDate <= start_date) {
               res.data[i].order_status = "Not Active";
            } else if (todayDate >= end_date) {
               res.data[i].order_status = "Expired";
            }
         }
         setMyAllOrder(res.data);
         setNoOfRecords(res.noOfRecords);
         const lastIndex = 1 * itemsPerPage;
         const firstIndex = lastIndex - itemsPerPage;
         const currentItems = res.data.slice(firstIndex, lastIndex);

         setCurrentItems(currentItems);
         setIsLoading(false)
      })
   }

   const reBuy = (e, item) => {
      // e.preventdefault();
      let endDate = calcEndDate(startDate, selectedDuration.value);
      const newVig = {
         countryId: selectedCountry._id,
         vehicleId: selectedVehicle._id,
         durationId: selectedDuration._id,
         countryName: selectedCountry.value,
         durationName: selectedDuration.value,
         price: (selectedDuration.price * exchangeRates.price).toFixed(2),
         priceNum: selectedDuration.price,
         flexiCharge: flexiChange,
         startDate: startDate,
         endDate: endDate, 
         vehicleImage: imageURL+selectedVehicle.filename,
         vehicleName: selectedVehicle.value,
         countryFilename: selectedCountry.filename,
         processing: selectedCountry.processing,
         isBuyNow: false
       }; 
      console.log("newVig",newVig);
      console.log("tempStartDate",tempStartDate);
      let addlVignettes = [];
      addlVignettes.push(newVig);
      dispatch(homedata({
         country: selectedCountry._id,
         vehiclecategory: selectedVehicle._id,
         duration: selectedDuration._id,
         license_plate: license_plate,
         vehicle_country_of_registration: registrationCountry,
         activeTab: registrationCountry ? 4 : 3,
         start_date: tempStartDate,
         flex_service_charge: flexiChange,
         timeStamp: timeStamp,
         allVignettes: addlVignettes,
      }))
      localStorage.removeItem('saveAsDraft');
      //
      let tempSaved = {};
      tempSaved["allVignettes"] = addlVignettes;
      tempSaved["vehicle_country_of_registration"] = registrationCountry;
      tempSaved["license_plate"] = license_plate;
      // tempSaved["activeTab"] = 3;
      // tempSaved["stepThree"] = "active show";
      tempSaved["activeTab"] = 4;
      tempSaved["showCheckout"] = true;
      tempSaved["stepOne"] = "";
      tempSaved["stepTwo"] = "";
      tempSaved["stepThree"] = "";
      tempSaved["stepFour"] = "active show";
      tempSaved["startDate"] = new Date(startDate);
      tempSaved["endDate"] = new Date(endDate);
      localStorage.setItem("saveAsDraft", JSON.stringify(tempSaved));
      //
      navigate("/order");
   }

   const cancellationSubmit = (e) => {
      e.preventDefault();

      if (!cancelReason.agree || !cancelReason.cancel_reason) {
         warningToast("All fields are required");
         return
      }

      updateRecord({
         "record": {
            "cancel_reason": cancelReason.cancel_reason,
            "canceledAt": new Date().toISOString(),
            "canceledby": loginUser['_id'],
            "order_status": "Cancelled"
         },
         "table": "orders",
         "_id": orderDetail._id,
         "uniqueFields": {},
         "uniqueFieldErrMsg": "Email alrady exist."
      }).then(async (upRes) => {
         if (upRes.status) {
            successToast("Order cancelled successfully");
            setCancelReason(cancelReason => ({ ...cancelReason, cancel_reason: '', agree: false }))
            getMyOrder();
         } else {
            errorToast(upRes.message);
         }
      })
   }

   const handlePageClick = (event) => {
      const newOffset = ((event.selected + 1) * itemsPerPage);
      const lastIndex = newOffset === 0 ? 1 : newOffset;
      const firstIndex = lastIndex - itemsPerPage;
      const currentItems = myAllOrder.slice(firstIndex, lastIndex);
      setCurrentItems(currentItems);
   };

   function handler(date) {
      setTimeStamp("");
      if (date) {
         setTempStartDate(date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
         }));
         setStartDate(date);
         handlerFlexiChange(date);
      }
   }

   function setReOrder(item) {
      setIsLoading(true);
      const selCtry = countryOptions.find((country) => country._id === item.country);
      if (selCtry) {
         setSelectedCountry({ ...selectedCountry, _id: selCtry._id, value: selCtry.value, filename: selCtry.filename, processing: selCtry.processing});
      }
      const selVeh = allVehicles.find((veh) => veh._id === item.vehicle_type);
      if (selVeh) {
         setSelectedVehicle({_id: selVeh._id, value: selVeh.value, filename: selVeh.filename});
      }
      const selDur = allDurations.find((dur) => dur._id === item.duration);
      if (selDur) {
         setSelectedDuration(selectedDuration => ({ ...selectedDuration, _id: selDur._id, value: selDur.value, absolute_value: selDur.price, price: selDur.price }));
      }
      setLicense_plate(license_plate => ({ ...license_plate, license_plate: item.license_plate, clicense_plate: item.license_plate }));
      setOrderDetail(item);
      setRegistrationCountry(item.vehicle_country_of_registration)
      // let todayDate = new Date();
      // setTimeStamp(todayDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })); // it's Time Stamp & if buyImmediately add than use it
      // handlerFlexiChange(new Date((item.start_date).split('/').reverse().join('/')))
      setIsLoading(false);
      {/*
      let Body = {
         table1: "codedomains",
         table2: "codedomain_values",
         table1JoinColumn: "_id",
         table2JoinColumn: "codedomain",
         table1SelectFields: { "name": 1, "code": 1 },
         table2SelectFields: { "value": 1, "code": 1, "_id": 1, "status": 1, "deleted": 1, "absolute_value": 1 },
         whereCondition: { "code": "COUNTRY", "deleted": null, "codedomain_values.status": "ACTV", "codedomain_values.deleted": null },
         sortBy: { "codedomain_values.position": 1 },
      }
      readRecordJoin(Body).then(async (res) => {
         if (res.data && res.data.length) {
            for (let i in res.data[0].items) {
               res.data[0].items[i].label = res.data[0].items[i].value;
            }

            setCountry(res.data[0].items);
            let Body2 = {
               "table": "codedomains",
               "selectFields": {},
               "whereCondition": {
                  "code": "VEHICLETYP"
               },
               "sortBy": {},
               "limit": "1",
               "populate": [],
               "page": "1"
            }

            let Body3 = {
               "table": "related_domains",
               "selectFields": {},
               "whereCondition": {
                  "codedomain_values1": ("ObjectId-" + item.country._id),
                  "codedomain2": ""
               },
               "sortBy": {},
               "type": "Country", // for related domains pick
               "limit": "0",
               "populate": [{ path: 'codedomain_values2', select: 'value code filename absolute_value status deleted price' }],
               "page": "1"
            }

            readRecord(Body2).then(async (cd2Res) => { // codedomains
               Body3['whereCondition']['codedomain2'] = "ObjectId-" + cd2Res.data[0]._id;
               readRecord(Body3).then(async (vclRes) => { // related_domains
                  if (vclRes.data && vclRes.data.length) {
                     let tempData = [];
                     for (let i in vclRes.data) {
                        vclRes.data[i].codedomain_values2.label = vclRes.data[i].codedomain_values2.value;
                        if (vclRes.data[i].codedomain_values2.status === 'ACTV' && vclRes.data[i].codedomain_values2.deleted === null) {
                           tempData.push(vclRes.data[i].codedomain_values2);
                        }
                     }
                     setVehicleCat(tempData);
                     // setIsLoading1(false);
                     if (item.vehicle_type._id) {
                        Body2['whereCondition']['code'] = "DURATION";
                        readRecord(Body2).then(async (cd2Res1) => {
                           Body3["type"] = "Duration";
                           Body3['whereCondition']['codedomain_values1'] = item.vehicle_type._id ? "ObjectId-" + item.vehicle_type._id : null;
                           Body3['whereCondition']['codedomain2'] = "ObjectId-" + cd2Res1.data[0]._id;
                           readRecord(Body3).then(async (vclRes) => {
                              if (vclRes.data && vclRes.data.length) {
                                 let tempData1 = [];
                                 for (let i in vclRes.data) {
                                    vclRes.data[i].codedomain_values2.label = vclRes.data[i].codedomain_values2.value;
                                    if (vclRes.data[i].codedomain_values2.status === 'ACTV' && vclRes.data[i].codedomain_values2.deleted === null) {
                                       tempData1.push(vclRes.data[i].codedomain_values2);
                                    }
                                 }
                                 setDuration(tempData1);
                              } else {
                                 setDuration([]);
                              }
                              setIsLoading(false);
                           })
                        })
                     }
                  } else {
                     setVehicleCat([]);
                     setIsLoading(false);
                  }
               })
            })
         }
         window.scrollTo({
            top: 0,
            behavior: 'smooth'
         });
      })
   */}

   }
   const changeLicense = (e) => {
      const { name, value } = e.target;
      setLicense_plate(license_plate => ({ ...license_plate, license_plate: value.toUpperCase(), clicense_plate: value.toUpperCase() }));

      if (licensePlatePattern.test(value.toUpperCase())) {
         setLicensePlateerror(false)
      } else {
         setLicensePlateerror(true)
      }
   }
   const editLicense = (e) => {

      const { name, value } = e.target;
      setEditOrderDetail({ ...editOrderDetail, license_plate: value.toUpperCase() })
      if (licensePlatePattern.test(value.toUpperCase())) {
         setLicensePlateerror1(false)
      } else {
         setLicensePlateerror1(true)
      }
   }

   const editOrderView = (item) => {
      modalOrderEditShow();
      setOrderDetail(item);
      handlerFlexiChange(new Date((item.start_date).split('/').reverse().join('/')))

      const data = {
         start_date: new Date((item.start_date).split('/').reverse().join('/')),
         license_plate: item.license_plate,
      }

      setStartDate(data.start_date);
      setEditOrderDetail(data);
   }

   const orderEditSubmit = (e) => {
      e.preventDefault();
      if(licensePlateerror1 || !editOrderDetail.license_plate || !startDate){
         return;
      }
      setIsLoading(true);
      let date = new Date(startDate);
      let end_date = new Date(startDate);
      end_date.setDate(date.getDate() + Number(orderDetail.duration.absolute_value || 0));
      end_date = end_date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
      const Body2 = {
         "record": {
            license_plate: editOrderDetail.license_plate,
            start_date: (startDate.toLocaleDateString('en-GB', {
               day: '2-digit',
               month: '2-digit',
               year: 'numeric'
            })),
            end_date: end_date,
            updatedAt: new Date().toISOString(),
            edit_user: loginUser['_id'],
            flexi_time: flexiObj.flexi_date,
            // "order_status": "Not Active" // for cancel check
         },
         "table": "orders",
         "_id": orderDetail._id,
         "uniqueFields": {},
         "uniqueFieldErrMsg": "Email alrady exist."
      }
      // return;
      updateRecord(Body2).then(async (upRes) => {
         setIsLoading(false);
         if (upRes.status) {
            getMyOrder();
            successToast("Details updated successfully");
            modalOrderEditClose();
         } else {
            errorToast(upRes.message);
         }
      });
   }

   const handleclick = (event) => {
      setSelectedVehicle({ ...selectedVehicle, _id: event._id, value: event.value });
      setSelectedDuration({ _id: '', value: '', absolute_value: 1, price: 0 });
      setIsLoading1(true);
      let Body2 = {
         "table": "codedomains",
         "selectFields": {},
         "whereCondition": {
            "code": "DURATION"
         },
         "sortBy": {},
         "limit": "1",
         "populate": [],
         "page": "1"
      }
      let Body3 = {
         "table": "related_domains",
         "selectFields": {},
         "whereCondition": {
            "codedomain_values1": "ObjectId-" + event._id,
            "codedomain2": ""
         },
         "sortBy": {},
         "limit": "0",
         "populate": [{ path: 'codedomain_values2', select: 'value code filename absolute_value status deleted price' }],
         "page": "1"
      }
   }

   const getCountryName = (_id) => {
      let selCountry = countryOptions.find((country) => country._id === _id);
      if (selCountry != null)
         return selCountry.value;
      else 
         return "NA";
   }

   const getVehicleName = (_id) => {
      let selVeh = allVehicles.find(obj => obj._id === _id);
      if (selVeh != null)
         return selVeh.value;
      else 
         return "NA";
   }

   const getVehicleFileName = (_id) => {
      let selVeh = allVehicles.find(obj => obj._id === _id);
      if (selVeh != null)
         return selVeh.filename;
      else 
         return "NA";
   }

   const getDurationName = (_id) => {
      let selDur = allDurations.find(obj => obj._id === _id);
      if (selDur != null)
         return selDur.value;
      else 
         return "NA";
   }

   const changeDuration = (event) => {
      setSelectedDuration(selectedDuration => ({ ...selectedDuration, _id: event._id, value: event.value, absolute_value: event.absolute_value, price: event.price }));
   }

   // flexi charge check box
   const flexichargefun = (e) => {
      if (e.target.checked) {
         setFlexiChange(flexiObj.flex_service_charge);
      } else {
         setFlexiChange(0);
      }
   }

   function handlerFlexiChange(date) {
      readRecord({
         "table": "siteconfig",
         "selectFields": {},
         "whereCondition": {},
         "sortBy": {},
         "limit": "1",
         "populate": [],
         "page": "1"
      }).then(async (configRes) => {

         let todayDate = new Date();
         let dateNew = new Date(configRes['data'][0].flexi_time * 1000);
         const specificTime = dateNew.toLocaleString([], { hour: 'numeric', minute: '2-digit', hour12: false });
         const [specificHour, specificMinute] = specificTime.split(':');

         todayDate.setHours(specificHour);
         todayDate.setMinutes(specificMinute);
         todayDate.setDate(todayDate.getDate() + Number(configRes['data'][0].flexi_days || 3));

         setFlexiObj(configRes['data'][0]);
         if (date > todayDate) {
            const flexiDate = new Date(date);
            flexiDate.setHours(specificHour);
            flexiDate.setMinutes(specificMinute);
            flexiDate.setDate(flexiDate.getDate() - (Number(configRes['data'][0].flexi_days || 1)));
            configRes['data'][0].flexi_date = flexiDate;
            const formattedDate = flexiDate.toLocaleString('en-GB', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
            setBenefitsBy(formattedDate)
            setTepmFlexiChange(configRes['data'][0].flex_service_charge)
         } else {
            setBenefitsBy("");
            setFlexiChange(0);
         }
      })
   }

   // change country of registration
   const changeRegCountry = (event) => {
      setRegistrationCountry(event);
   }

   // Edit order modal
   const modalOrderEditClose = () => setShow1(false);
   const modalOrderEditShow = () => setShow1(true);


   return (
      <>
         <div className="twm-D_table table-responsive panel panel-default p-0">
            <table id="jobs_bookmark_table" className="table twm-candidate-save-job-list-wrap">
               <thead>
                  <tr>
                     <th>{t('Vehicle Type')}</th>
                     <th>{t('My Vignette Id')}</th>
                     <th>{t('Plate No')}</th>
                     <th>{t('Start date')}</th>
                     <th>{t('End date')}</th>
                     <th className='text-end'>{t('Amount')}</th>
                     <th>{t('Status')}</th>
                     <th>{t('Action')}</th>
                  </tr>
               </thead>
               <tbody>
                  {currentItems && currentItems.map((item, index) => (
                     <tr key={index}>
                        <td>
                           <div className="twm-candidate-save-job-list">
                              <div className="twm-media">
                                 <div className="twm-media-pic">
                                    {item?.vehicle_type && <img src={`${imageURL}${getVehicleFileName(item.vehicle_type)}`} alt="#" />}
                                 </div>
                              </div>
                              <div className="twm-mid-content twm-job-title">
                                    <h4>{t(getVehicleName(item?.vehicle_type))}</h4>
                              </div>

                           </div>
                        </td>
                        <td>
                           {item.digi_partners_sale ?
                              <span>
                                 <div>{item.digi_partners_sale.saleRows[0].kapschProperties.id}</div>
                                 {item.is_flex_date === 'Yes' && <small className='text-them'><b>{t('This is flexi order')}</b></small>}
                              </span> : 
                              <span>
                                 <div>{item.vignette_id}</div>
                                 {item.is_flex_date === 'Yes' && <small className='text-them'><b>{t('This is flexi order')}</b></small>}
                              </span>                              
                           }
                        </td>
                        <td>
                           <div>{item.license_plate}</div>
                        </td>
                        {/* <td><a>{item.duration.value}</a></td> */}
                        <td>
                           <div>{item.start_date}</div>
                        </td>
                        <td>
                           <div>{item.end_date}</div>
                        </td>
                        {/* <td>
                           <div>{item.license_plate}</div>
                        </td> */}
                        <td className='text-end'>
                           <div>{item.ex_currency} {formatAmount(item.ex_order_amount)}</div>
                        </td>
                        <td>
                           <div className='text-center' >
                              <span className="custom-toltip">
                                 {item.order_status === 'Active' && <i className="fa-solid fa-circle " style={{ color: "green" }}></i>}
                                 {item.order_status === 'Expired' && <i className="fa-solid fa-circle " style={{ color: "red" }}></i>}
                                 {item.order_status === 'Not Active' && <i className="fa-solid fa-circle " style={{ color: "orange" }}></i>}
                                 {item.order_status === 'Cancelled' && <i className="fa-solid fa-circle " style={{ color: "blue" }}></i>}
                                 <span className="custom-toltip-block">{t(item.order_status)}</span>
                              </span>
                           </div>
                        </td>
                        <td>
                           <div className="twm-table-controls d-block">
                              <ul className="twm-DT-controls-icon list-unstyled">
                                 <li>
                                    <button data-bs-toggle="modal" data-bs-target="#orderViewModal" data-bs-placement="top" role="button"
                                       className="custom-toltip p-3" onClick={(e) => setReOrder(item)}>
                                       <span className="fa fa-eye" style={{ fontSize: '16px' }}></span>
                                       <span className="custom-toltip-block">{t('View')}</span>
                                    </button>
                                 </li>
                                 <li>
                                    <button data-bs-toggle="modal" data-bs-target="#reorderModal" data-bs-placement="top" 
                                       className="custom-toltip p-3" onClick={(e) => setReOrder(item)}>
                                       <i className="fa-solid fa-history" style={{ fontSize: '16px' }}></i>
                                       <span className="custom-toltip-block">{t('Reorder')}</span>
                                    </button>
                                 </li>
                                 {item.is_flex_date === 'Yes' && item.flexi_time > new Date().toISOString() && item.order_status !== 'Cancelled' && (
                                    <li>
                                       <button className="custom-toltip p-3" onClick={(e) => editOrderView(item)}>
                                          <i className="fa-solid fa-pencil" style={{ fontSize: '16px' }}></i>
                                          <span className="custom-toltip-block">{t('Edit Order')}</span>
                                       </button>
                                    </li>
                                 )}

                                 {(item.is_flex_date === 'Yes' && item.order_status === 'Not Active') && (
                                    <li>
                                       <button onClick={(e) => setOrderDetail(item)} data-bs-target="#cancelOrderModal" data-bs-toggle="modal" data-bs-dismiss="modal" role="button" style={{ backgroundColor: '#fef0f0', color: 'red' }} className="custom-toltip p-3 me-4">
                                          <span className="fa fa-times" style={{ fontSize: '16px' }}></span>
                                          <span className="custom-toltip-block">{t('Cancel Order')}</span>
                                       </button>
                                    </li>
                                 )}
                              </ul>
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
            {/* BEGIN: Pagination */}
            <div className="react-paginate d-flex justify-content-center mt-2">
               <ReactPaginate
                  breakLabel="..."
                  nextLabel={(<i className="fa-solid fa-chevron-right" style={{ padding: "5px 0px" }}></i>)}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={(noOfRecords / itemsPerPage)}
                  previousLabel={(<i className="fa-solid fa-chevron-left" style={{ padding: "5px 0px" }}></i>)}
                  renderOnZeroPageCount={null}
               />

            </div>
            {/* END: Pagination */}
            <div className='container-fluid'>
               <div className='row text-center pb-2'>
                  <div className='col-md-3 '>
                     <i className="fa-solid fa-circle " style={{ color: "orange" }}></i><span className='ms-2'>{t('Not Active')}</span>
                  </div>
                  <div className='col-md-3'>
                     <i className="fa-solid fa-circle " style={{ color: "green" }}></i><span className='ms-2'>{t('Active')}</span>
                  </div>
                  <div className='col-md-3'>
                     <i className="fa-solid fa-circle " style={{ color: "red" }}></i><span className='ms-2'>{t('Expired')}</span>
                  </div>
                  <div className='col-md-3'>
                     <i className="fa-solid fa-circle " style={{ color: "blue" }}></i><span className='ms-2'>{t('Cancelled')}</span>
                  </div>
               </div>
            </div>

         </div>



         {/* Order View Modal */}
         <div className="modal fade" id="orderViewModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="orderViewModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
               <div className="modal-content">
                  <div className="modal-header border-none">
                     <h4 className="twm-title">{t('Order Details')}</h4>
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">

                     <div className="twm-s-info3-wrap mb-5">
                        <div className="twm-s-info3 mt-5">
                           <div className="twm-s-info-logo-section">
                              <div className="twm-media">
                                 {selectedVehicle.filename!=null && <img src={`${imageURL}${selectedVehicle.filename}`} alt="#" />}
                              </div>
                              <h4 className="twm-title">{orderDetail?.vehicle_type?.value}</h4>
                           </div>
                           <div className="row d-flex justify-content-center">
                              <div className="col-lg-6 col-md-12 rightSidebar">
                                 <ul>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-building"></i>
                                          <span className="twm-title">{t('Order No')}</span>
                                          <div className="twm-s-info-discription"><b>{orderDetail?.order_number}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-map-marker-alt"></i>
                                          <span className="twm-title">{t('Country')}</span>
                                          <div className="twm-s-info-discription"><b>{getCountryName(orderDetail.country)}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-building"></i>
                                          <span className="twm-title">{t('Duration')}</span>
                                          <div className="twm-s-info-discription"><b>{getDurationName(orderDetail?.duration)}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-calendar-alt"></i>
                                          <span className="twm-title">{t('Start Date - End Date')}</span>
                                          <div className="twm-s-info-discription"><b>{orderDetail?.start_date} to {orderDetail?.end_date}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-bell"></i>
                                          <span className="twm-title">{t('Order Status')}</span>
                                          <div className="text-start" >
                                             <span className="custom-toltip">
                                                {orderDetail.order_status === 'Active' && <i className="fa-solid fa-circle " style={{ color: "green" }}></i>}
                                                {orderDetail.order_status === 'Expired' && <i className="fa-solid fa-circle " style={{ color: "red" }}></i>}
                                                {orderDetail.order_status === 'Not Active' && <i className="fa-solid fa-circle " style={{ color: "orange" }}></i>}
                                                {orderDetail.order_status === 'Cancelled' && <i className="fa-solid fa-circle " style={{ color: "blue" }}></i>}
                                                <span className="custom-toltip-block">{orderDetail?.order_status}</span>
                                             </span>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="col-lg-6 col-md-12 rightSidebar">
                                 <ul>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-money-bill-wave"></i>
                                          <span className="twm-title">{t('Order Amount')}</span>
                                          <div className="twm-s-info-discription"><b>{orderDetail?.ex_currency} {formatAmount(orderDetail?.ex_order_amount)}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-car"></i>
                                          <span className="twm-title">{t('Vehicle')}</span>
                                          <div className="twm-s-info-discription"><b>{getVehicleName(orderDetail?.vehicle_type)}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-file-signature"></i>
                                          <span className="twm-title">{t('License Plate')}</span>
                                          <div className="twm-s-info-discription"><b>{orderDetail?.license_plate}</b></div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-clock"></i>
                                          <span className="twm-title">{t('Order Date')}</span>
                                          <div className="twm-s-info-discription"><b>{orderDetail?.createdAt?.substring(0, 10)}</b></div>
                                       </div>
                                    </li>

                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         {/* Reorder Modal */}
         <div className="modal fade" id="reorderModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="orderViewModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
               <div className="modal-content">
                  <div className="modal-header border-none">
                     <h4 className="twm-title">{t('Reorder')}</h4>
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">

                     <div className="twm-s-info3-wrap mb-5">
                        <div className="twm-s-info3 mt-5">
                           <div className="twm-s-info-logo-section">
                              <div className="twm-media">
                                 {selectedVehicle.filename!=null && <img src={`${imageURL}${selectedVehicle.filename}`} alt="#" />}
                              </div>
                              <h4 className="twm-title">{t(orderDetail?.vehicle_type?.value)}</h4>
                           </div>
                           <div className="row d-flex justify-content-center">
                              <div className="col-lg-6 col-md-12 rightSidebar">
                                 <ul>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-file-signature"></i>
                                          <span className="twm-title">{t('License Plate')}</span>
                                          <input type="text" name="license_plate" disabled value={license_plate.license_plate} onChange={changeLicense} className="form-control" placeholder={orderDetail?.vehicle_country_of_registration?.placeholder} />
                                          {(licensePlateerror) ? <p className="text-danger">The registration number does not correspond.</p> : ""}
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-map-marker-alt"></i>
                                          <span className="twm-title">{t('Country of destination')}</span>
                                          <Select
                                             className="form-control selectInput"
                                             options={countryOptions}
                                             value={countryOptions.find((country) => country._id === selectedCountry._id)}
                                             formatOptionLabel={(option) => (
                                                <div>
                                                  <img
                                                    src={imageURL+option.filename}
                                                    alt={option.code}
                                                    style={{ width: '20px', marginRight: '5px'}}
                                                  />
                                                  {option.code} {option.value}
                                                </div>
                                              )}
                                             isDisabled={true}
                                          />
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-building"></i>
                                          <span className="twm-title">{t('Duration')}</span>
                                          <Select
                                             className="form-control selectInput"
                                             options={allDurations}
                                             value={allDurations.find(obj => obj._id === selectedDuration._id)}
                                             isDisabled={true}
                                             formatOptionLabel={(option) => (
                                                <div>
                                                  {option.value}
                                                </div>
                                              )}
                                             //  name="vehiclecategory"
                                             onChange={changeDuration}
                                          />
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-money-bill-wave"></i>
                                          <span className="twm-title">{t('Order Amount')}</span>
                                          <div className="twm-s-info-discription"><b>{exchangeRates.absolute_value} {(selectedDuration.price * exchangeRates.price).toFixed(2)}</b></div>
                                       </div>
                                    </li>

                                 </ul>

                              </div>
                              <div className="col-lg-6 col-md-12 rightSidebar">
                                 <ul>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-map-marker-alt"></i>
                                          <span className="twm-title">{t('Country of Registration')}</span>
                                          <Select
                                             className="form-control selectInput"
                                             isDisabled={true}
                                             options={licensePlateData} // set list of the data
                                             value={registrationCountry}
                                             onChange={changeRegCountry}
                                             placeholder="Select Country"
                                          />
                                       </div>
                                    </li>

                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-car"></i>
                                          <span className="twm-title">{t('Vehicle')}</span>
                                          <Select
                                             className="form-control selectInput"
                                             options={allVehicles}
                                             value={allVehicles.find(obj => obj._id === selectedVehicle._id)}
                                             //  name="vehiclecategory"
                                             formatOptionLabel={(option) => (
                                                <div>
                                                  {option.value}
                                                </div>
                                              )}
                                             isDisabled={true}
                                             onChange={handleclick}
                                          />
                                       </div>
                                    </li>
                                    <li>
                                       <div className="twm-s-info-inner">
                                          <i className="fas fa-calendar-alt"></i>
                                          <span className="twm-title">{t('Start Date')}</span>
                                          <DatePicker selected={startDate} className="form-control" placeholderText="Select Start date" dateFormat="dd/MM/yyyy" minDate={minDate} maxDate={maxDate} onChange={(date) => handler(date)} />
                                          {timeStamp && <p className="mb-0 text-end">From {timeStamp}</p>}
                                          {/* <div className="twm-s-info-discription"><b>{orderDetail?.start_date} to {orderDetail?.end_date}</b></div> */}
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className="col-md-12 rightSidebar">
                                 {benefitsBy ? (
                                    <div className="col-md-12">
                                       <div className="twm-candidates-grid-style1 p-0 border-0 form-group">
                                          <div className="twm-fot-content m-0">
                                             <div className="wt-post-info text-start">
                                                <div className="wt-post-text p-2">
                                                   <h4>{t('Benefits')} <span style={{ fontSize: '14px', fontWeight: 400 }}>({benefitsBy})</span></h4>
                                                </div>
                                                <ul className="description-list-2 m-0">
                                                   <li>
                                                      <i className="feather-thumbs-up"></i>
                                                      {t('Registration number can be changed')}
                                                   </li>
                                                   <li>
                                                      <i className="feather-thumbs-up"></i>
                                                      {t('Travel date can be changed')}
                                                   </li>
                                                </ul>
                                             </div>
                                             <div className="twm-left-info">
                                                <div className="form-check mt-2 ">
                                                   <input className="form-check-input mt-2" type="checkbox" defaultChecked={flexiChange ? true : false} onClick={flexichargefun} id="flexCheckCheckedd" />
                                                   <label className="form-check-label text-them" htmlFor="flexCheckCheckedd">
                                                      <b>{t('Activate flex service for')} </b> <span style={{ fontSize: "17px" }}><b>{exchangeRates.absolute_value} {(flexiTepmChange * exchangeRates.price).toFixed(2)}</b></span>
                                                   </label>
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 ) : ''}
                              </div>
                              <div className="d-flex justify-content-center mt-3">
                                 <div className="text-center">
                                    <button type="button" data-bs-dismiss="modal" className="site-button" onClick={(e) => reBuy(e, orderDetail)}>{t('Confirm')}</button>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         {/* Cancel Order Modal */}
         <div className="modal fade twm-sign-up" id="cancelOrderModal" aria-hidden="true" aria-labelledby="cancelOrderModalLabel" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content">
                  <form onSubmit={cancellationSubmit}>
                     <div className="modal-header">
                        <h2 className="modal-title" id="cancelOrderModalLabel">{t('Order Cancellation')}</h2>
                        <span>{t('Please specify Reason for Cancel')}</span>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     </div>
                     <div className="modal-body">
                        <div className="twm-tabs-style-2">
                           {/* Signup Candidate Content */}
                           <div className="row">

                              <div className="col-lg-12">
                                 <div className="form-group mb-3">
                                    <input name="cancel_reason" type="text" required="" onChange={(e) => setCancelReason({ ...cancelReason, cancel_reason: e.target.value })} className="form-control" placeholder="Specify Reason*" />
                                 </div>
                              </div>

                              <div className="col-lg-12">
                                 <div className="form-group mb-3">
                                    <div className=" form-check">
                                       <input type="checkbox" className="form-check-input" id="agree1" onChange={(e) => setCancelReason({ ...cancelReason, agree: e.target.checked })} />
                                       <label className="form-check-label" htmlFor="agree1">{t('Are you sure? You want to Cancel Order')}*</label>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-12 text-center">
                                 <button type="submit" className="site-button w-auto" style={{ display: 'unset' }}>
                                 {t('Submit')}
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>


         {/* Edit order for  */}
         <Modal className="modal fade twm-sign-up" show={show1} onHide={modalOrderEditClose}>
            <Modal.Header closeButton>
               <Modal.Title>{t('Order Edit')}</Modal.Title>
            </Modal.Header>
            {/* <div className="modal fade twm-sign-up" id="editOrder" aria-hidden="true" aria-labelledby="editOrderLabel" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content"> */}
            <form onSubmit={orderEditSubmit}>
               {/* <div className="modal-header">
                        <h2 className="modal-title" id="editOrderLabel">Order Edit</h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     </div> */}
               <div className="modal-body">
                  <div className="twm-tabs-style-2">
                     {/* Signup Candidate Content */}
                     <div className="row">

                        <div className="col-lg-12">
                           <div className="form-group mb-3">
                              <span className="twm-title">{t('Start Date')}</span>
                              <div className="ls-inputicon-box">
                                 <DatePicker selected={startDate} className="form-control" placeholderText="Select Start date" dateFormat="dd/MM/yyyy" minDate={minDate} maxDate={maxDate} onChange={(date) => handler(date)} />
                                 <i className="fs-input-icon fa-solid fa-calendar"></i>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-12">
                           <div className="form-group mb-3">
                              <span className="twm-title">{t('License Plate')}</span>
                              <input name="license_plate" type="text" value={editOrderDetail?.license_plate} required="" onChange={editLicense} className="form-control" placeholder="License Plate*" />
                              {(licensePlateerror1) ? <p className="text-danger">The registration number does not correspond.</p> : ""}
                           </div>
                        </div>
                        <div className="col-md-12 text-center">
                           <button type="submit" className="site-button w-auto" style={{ display: 'unset' }}>
                              {t('Submit')}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
            {/* </div>
            </div>
         </div> */}
         </Modal>

         {isLoading &&
            <div className="sub-loading">
               <div className="loader" >
                  <span className="text-large font-weight-bolder">
                     <img src="/assets/images/loader.svg" width={100} />
                  </span>
               </div>
            </div>
         }
      </>
   )
};