import React from 'react';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { readRecord, sendEmail } from "../Redux/Action/Action";
import { successToast, warningToast, errorToast } from '../components/toastify';
import { useTranslation } from 'react-i18next';

const Forgetpassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [forget_pwd, setForget_pwd] = useState({ email_address: "" })
    const [isLoading, setIsLoading]=useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setForget_pwd({ ...forget_pwd, [name]: value })
    }
    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     navigate("/dashboard/my-profile")
    // }

    const signInSubmit = (e) => {
        e.preventDefault()
        if (!forget_pwd.email_address) {
            warningToast("Please Enter email address");
            return;
        }
        setIsLoading(true)
        let Body = {
            "table": "customers",
            "selectFields": {},
            "whereCondition": {
                "email": forget_pwd.email_address,
                "deleted" : null
            },
            "sortBy": {},
            "populate": [],
            "limit": "1",
            "page": "1"
        }
        readRecord(Body).then(async (res) => {
            setIsLoading(false)
            if (res.status && res.data.length) {
                sendEmail({
                    userId: res.data[0]._id,
                    password: res.data[0].password,
                    eventname: "Forgot Password"
                }).then(async (res) => {
                    if (res.status) {
                        successToast("Password sent on your email");
                        navigate("/login")
                       
                    }else{
                        errorToast(res.message);
                    }
                    
                })
            } else {
                errorToast("Email address not Registered");
            }
            
        },err=>{setIsLoading(false)})
    }

    return (
        <>
            <div className="page-content p-t120">
                <div className="section-full site-bg-white">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-6 col-md-7 shadow mb-3 rounded">
                                <div className="twm-log-reg-form-wrap pb-0">

                                    <div className="twm-log-reg-inner">
                                        <div className="twm-log-reg-head">
                                            <div className="twm-log-reg-logo">
                                                <p className="log-reg-form-title">{t('Forget password')}</p>
                                                <p>{t('Enter your Email to get your password in your Email')}.</p>
                                            </div>
                                        </div>
                                        <div className="twm-tabs-style-2">

                                            <div className="tab-content" id="myTab2Content">
                                                <div className="tab-pane fade show active" id="twm-login-candidate">
                                                    <div className="row">
                                                        <form onSubmit={signInSubmit}>
                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-3">

                                                                    <input name="email_address" type="text" value={forget_pwd.email_address} onChange={handleChange} className="form-control" placeholder="Your Email address*" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    {/* <Link to="/dashboard/my-profile"> */}
                                                                    <button type="submit" className="site-button">{t('Request Password')}</button>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 mt-4">
                                                                <button type="button" onClick={() => { navigate("/login") }} className="twm-nav-sign-up site-button outline-primary py-2">{t('Back to the login')}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading && 
                <div className="sub-loading">
                    <div className="loader">
                        <span className="text-large font-weight-bolder">
                            <img src="/assets/images/loader.svg" />
                        </span>
                    </div>
                </div>
         }
        </>
    );
}

export default Forgetpassword;
