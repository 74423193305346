import React from 'react';

export default function About() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div className="page-content p-t120">
        <h1 className='text-center'>About</h1>
    </div>
  )
};