import React, { useState, useContext, useEffect } from 'react';
import { digitollPost, readRecord, createRecord, formatAmount } from "../Redux/Action/Action";
import { successToast, warningToast, errorToast } from './toastify';
import AppContext from '../appContext';
import { useHistory, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Popup from 'reactjs-popup';
import PopupDialog from './mypopup';

const SecretForm = (props) => {

  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const { exchangeRates } = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [registration, setRegistration] = useState({ vignette: "", password: "" })

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setRegistration({ ...registration, [name]: value });
  }

  const handlePayNow = async (event) => {
     console.log("SecretForm handlePayNow");
     try {
        let processPaymentAtEnd = false;
        let openPopupNeeded = false;
        let savePartnersSaleRes = null;
        let vigForBulgaria = null;
        let token = {};
        if (!(event && event.id)) {
            event.preventDefault();
        }
        let sendInfo = {};
        if (registration.password) {
           sendInfo.password = registration.password==''?'abaracadabara':registration.password;
        }
        else {
           console.log("password section is empty");
           return;
        }
        readRecord({
         "table": "siteconfig",
         "selectFields": {},
         "whereCondition": sendInfo,
         "sortBy": {},
         "limit": "1",
         "populate": [],
         "page": "1"
        }).then(async (configRes) => {
          console.log("configRes", configRes);
          if (!configRes['status']) {
              console.log("Wrong password info",configRes.message);    
              return;
          }
          if (props.allInfo.allVignettes != null && props.allInfo.allVignettes.length > 0) {
            setIsLoading(true);
            for (let loopIndex in props.allInfo.allVignettes) {
                let vigInfo = props.allInfo.allVignettes[loopIndex];
                console.log(">>> Now processing VigInfo",vigInfo);
                if (vigInfo.processing == 'PaymentOnly') {  // For PaymentOnly eg. Austria just complete the payment and invoke REST to send email
                    processPaymentAtEnd = true;
                    openPopupNeeded = true;
                    continue;
                }
                if (vigInfo.processing !== 'PaymentAndDigitoll') {
                    console.log("AAAAA - Unknown processing option or not implemented - "+vigInfo.processing+" for country "+vigInfo.countryName);
                    continue;
                }
                processPaymentAtEnd = true;
                vigForBulgaria = vigInfo;
            }  // end of for loop
            console.log("Loop has ended, we are here with flag",processPaymentAtEnd);
            if (processPaymentAtEnd) {
              if (vigForBulgaria != null) {
                let vigInfo = vigForBulgaria;
                console.log("AAAAA - Now processing digitoll for Destination country "+vigInfo.countryName);
                //
                // process and Digitoll Token only for Bulgaria
                // 
                digitollPost({
                  "url": "user/authenticate",
                  "delvyBody": {}
                }).then((tokenRes) => {
                    if (tokenRes.status) {
                      //
                      // Get digitoll products
                      //
                      digitollPost({
                        "token": tokenRes.data.token,
                        "url": "products",
                        "method": "GET",
                      }).then((res1) => {
                        let kapschProduct = {};
                        if (res1.status) {
                          // console.log("Digitoll Products",res1.data);
                          for (let i in res1.data) {
                            //  console.log("Comparing ["+res1.data[i].validityTypeText+"] with ["+vigInfo.durationName+"]");
                            if (res1.data[i].validityTypeText === vigInfo.durationName) {
                              if (res1.data[i].vehicleType === 'car' && vigInfo.vehicleName === 'Car') {
                                kapschProduct = res1.data[i];
                              }
                              if (res1.data[i].vehicleType === 'trailer' && vigInfo.vehicleName !== 'Car') {
                                kapschProduct = res1.data[i];
                              }
                            }
                          }
                        } else {
                          saveErrorLogs({
                            error: res1?.message,
                            function_name: "products",
                            type: "Digitoll products get",
                          })
                        }
                        let startDate = new Date();
                        if (vigInfo.isBuyNow) {
                          startDate.setMinutes(startDate.getMinutes() + 2);
                        } else {
                          startDate = vigInfo.startDate; // props.allInfo.startDate;
                        }
                        console.log("Selected Digitoll product",kapschProduct);
                        console.log("UserInfo",props.allInfo.userInfo.email);
                        console.log("ActivationDate startDate",startDate);
                        digitollPost({
                          "token": tokenRes.data.token,
                          "url": "partners/sale",
                          "delvyBody": {
                            "posId": "123",
                            "saleRows": [
                              {
                                "activationDate": new Date(startDate).toISOString(),
                                "email": props.allInfo.userInfo.email,
                                "kapschProductId": kapschProduct.id,
                                "remoteClientId": "",
                                "vehicle": {
                                  "countryCode": props.allInfo.registrationCountry.countryCode || "BG",
                                  "lpn": props.allInfo.license_plate.license_plate
                                }
                              }
                            ]
                          }
                        }).then((partnersSaleRes) => {
                          console.log("SecretForm partnersSaleRes",partnersSaleRes);
                          if (partnersSaleRes.status) {
                            savePartnersSaleRes = partnersSaleRes;
                            processPaymentAtEnd = true;
                            console.log("Now invoking completeStripePaymentOnly with",savePartnersSaleRes);
                            successToast("Payment successful for Auto-vignette");
                            console.log("SecretForm invoking callback1 with savePartnersSaleRes",savePartnersSaleRes);
                            props.callback(token, "Stripe", savePartnersSaleRes, registration);            
                            if (openPopupNeeded) {
                              openPopup();
                            }            
                          } 
                          else {
                            let errorMsg = "";
                            let errorMsg2 = "";
                            saveErrorLogs({
                              error: partnersSaleRes?.message,
                              function_name: "Digitoll Place order",
                              type: "Digitoll (partners/sale)",
                            })
                            if (partnersSaleRes?.message) {
                              errorMsg = partnersSaleRes.message;
                            }
                            if (partnersSaleRes?.message?.message) {
                              errorMsg2 = partnersSaleRes.message.message;
                            }
                            Swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              titleText: errorMsg2 || 'Something went wrong',
                              text: errorMsg || 'Digitoll Server Error',
                            })
                            savePartnersSaleRes = null;  // as we encountered error, we don't want to process this vignette 
                            errorToast("Order not placed");
                            setIsLoading(false);
                          }
                        }, err => {
                          console.log(">>>> Falls on this error ???");
                        })
                      }, err => {
                        console.log("1111 >>", err);
                        setIsLoading(false);
                      }, err => {
                        console.log("222 >>", err);
                        setIsLoading(false);
                      })
                    } 
                    else {
                      console.log("333 >>");
                      setIsLoading(false);
                    }
                }, err => {
                  console.log("444 >>", err);
                  setIsLoading(false);
                })
              }  // if vigForBulgaria
              else {
                successToast("Payment successful for Auto-vignette");
                console.log("SecretForm invoking callback1 with savePartnersSaleRes",savePartnersSaleRes);
                props.callback(token, "Stripe", savePartnersSaleRes, registration);            
                if (openPopupNeeded) {
                    openPopup();
                }
                }
            }
            setIsLoading(false);
            return;
          }  // end of if block to process all the vignettes
          else {
            console.log("Sorry nothing to process");
            return;
          }     
          setIsLoading(false);
        });
    } catch (error) {
      // Handle the error here
      setIsLoading(false);
      console.log(error.message);
      console.log("error >>>>>>>>>>", error.message);
    }
  };

  const saveErrorLogs = (data) => {
    // add
    const Body = {
      "record": {
        site: "Vignette frontend",
        error: JSON.stringify(data.error || {}),
        function_name: data.function_name,
        type: data.type,
        create_user: props.loginUser['_id'],
        status: "ACTV",
        deleted: null,
        deletedby: null,
      },
      "table": "error_logs",
      "uniqueFields": {},
      "uniqueFieldErrMsg": "Error in error_logs save"
    }

    createRecord(Body).then(async (res) => {
      console.log("error_logs >>>>>", res);
      setIsLoading(false)

    }, err => { setIsLoading(false) })
  }
  
  return (
    <div className="card-number-display">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="form-group mb-3">
              <div className="ls-inputicon-box">
              <input name="vignette" value={registration.vignette} type="text" onChange={handleChange} className="form-control" placeholder={t("Vignette Id")} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <div className="ls-inputicon-box">
              <input name="password" value={registration.password} type={"password"} onChange={handleChange} className="form-control" placeholder={t("Password*")} />
              </div>
            </div>
          </div>
        </div>
        {!isLoading ?
        <div className="row">
        <div className="col-md-12 text-center">
          {showPopup && <PopupDialog message={t("Order placed sucessfully")} onClose={closePopup} />}
          {(registration.vignette && registration.password) ?
            <button type="button" className="site-button" onClick={handlePayNow} ><i className="feather-credit-card"></i> {t('Pay Now')} {exchangeRates.absolute_value} {formatAmount(props.allInfo.amount)}</button> :
            <button type="button" className="site-button btn-disabled" disabled ><i className="feather-credit-card"></i> {t('Pay Now')} {exchangeRates.absolute_value} {formatAmount(props.allInfo.amount)}</button>
          }
          {/* AA - Feb 20 2024 - removed GiroPay as per Nurettin on Feb 19th meeting
          {props.allInfo.giropay_payment_show &&
            <div className='mt-3'>
              <img className='pointer' onClick={handlePayment} src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Giropay.svg/2560px-Giropay.svg.png" width="135px" alt="" />
            </div>
          }
        */}
          {/* <button type="button" className="site-button " onClick={() => handlePayPalCheckout()}>
        </button> */}
        </div></div> :
        <div className="row">
        <div className="col-md-12 text-center">
          <button type="button" className="site-button btn-disabled" disabled ><i className="feather-credit-card"></i> <img src="assets/images/loader.svg" width={25} /></button>
          <p style={{ color: 'blue' }}>Processing...</p>
        </div>
        </div>
      }
      </div>
    </div>
  );
};

export default SecretForm;