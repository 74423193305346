import React, { useEffect, useContext, useState } from "react";
import CategorySlide from '../components/swiper'
// import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { homedata } from "../Redux/Action/Action";
// import { readRecordJoin, readRecord } from "../Redux/Action/Action";
import Popup from 'reactjs-popup';
import { useNavigate } from "react-router-dom";
import { warningToast } from '../components/toastify';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FacebookProvider, Page } from 'react-facebook';
import { useTranslation } from 'react-i18next';
import data from '../data.json'; 
import AppContext from '../appContext';

const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";

export default function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { swiperdata } = useSelector(state => state.data)

    const [selectedValue, setSelectedValue] = useState({});
    const [countries, setCountries] = useState(data.Countries);

    const [countryOptions, setCountry] = useState([]);
    const [categoryOptions, setVehicleCat] = useState([]);
    const [durationOptions, setDuration] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMobileSkeleton, setIsMobileSkeleton] = useState(false);
    const { exchangeRates } = useContext(AppContext);

    const onBundleSelect= () => {
          console.log("onBundleSelect");
          // ctr1 - veh1 - dur1
          const newVig1 = {
            countryId: 'ctr1',
            vehicleId: 'veh1',
            durationId: 'dur1',
            countryName: 'Bulgaria',
            durationName: 'Weekly',
            price: (7.0 * exchangeRates.price).toFixed(2),
            priceNum: 7.0,
            flexiCharge: 0.0,
            startDate: new Date(),
            endDate: new Date() + 7, 
            vehicleImage: imageURL+'file-1684297555814.png',
            vehicleName: 'Car',
            countryFilename: 'file-1685352570377.png',
            processing: 'PaymentAndDigitoll',
            isBuyNow: false
          }; 
         console.log("newVig",newVig1);
          // ctr6 - veh4 - dur12
          const newVig2 = {
            countryId: 'ctr6',
            vehicleId: 'veh4',
            durationId: 'dur12',
            countryName: 'Austria',
            durationName: '10-days',
            price: (12.5 * exchangeRates.price).toFixed(2),
            priceNum: 12.5,
            flexiCharge: 0.0,
            startDate: new Date(),
            endDate: new Date() + 10, 
            vehicleImage: imageURL+'file-1684297555814.png',
            vehicleName: 'Car',
            countryFilename: 'file-1685352697201.png',
            processing: 'PaymentOnly',
            isBuyNow: false
          }; 
         console.log("newVig",newVig2);
          // ctr2 - veh7 - dur18
          const newVig3 = {
            countryId: 'ctr2',
            vehicleId: 'veh7',
            durationId: 'dur18',
            countryName: 'Hungary',
            durationName: '10-days',
            price: (18.0 * exchangeRates.price).toFixed(2),
            priceNum: 18.0,
            flexiCharge: 0.0,
            startDate: new Date(),
            endDate: new Date() + 10, 
            vehicleImage: imageURL+'file-1684297555814.png',
            vehicleName: 'Car',
            countryFilename: 'file-1686725164770.png',
            processing: 'PaymentOnly',
            isBuyNow: false
          }; 
         console.log("newVig",newVig3);
         let addlVignettes = [];
         addlVignettes.push(newVig1);   
         addlVignettes.push(newVig2);   
         addlVignettes.push(newVig3);   
         setSaveAsDraft('addlVignettes', addlVignettes);        
         setSaveAsDraft('activeTab', 1);
         setSaveAsDraft('duration', '');
         setSaveAsDraft('license_plate', '');
         navigate("/order");
    };

    const setSaveAsDraft = (key, value) => {
        let tempSaved = {};
        if (localStorage.getItem('saveAsDraft')) {
            tempSaved = JSON.parse(localStorage.getItem('saveAsDraft'));
        }
        tempSaved[key] = value;
        localStorage.setItem("saveAsDraft", JSON.stringify(tempSaved));
    }

    const handleChange = (e, action) => {
        const inputName = action.name;
        setSelectedValue({ ...selectedValue, [inputName]: e._id });
        setIsLoading(true);
        if (inputName === 'country') {
            setVehicleCat([]);
            setDuration([]);
            // setSelectedValue({ ...selectedValue, ['country']: "", ['duration']: "" });
        }
        if (inputName === 'country' || inputName === 'vehiclecategory') {
            let Body = {
                "table": "codedomains",
                "selectFields": {},
                "whereCondition": {
                    "code": "VEHICLETYP"
                },
                "sortBy": {},
                "limit": "1",
                "populate": [],
                "page": "1"
            }
            let Body2 = {
                "table": "related_domains",
                "selectFields": {},
                "whereCondition": {
                    "codedomain_values1": "ObjectId-" + e._id,
                    "codedomain2": ""
                },
                "sortBy": {},
                "limit": "0",
                "populate": [{ path: 'codedomain_values2', select: 'value code filename status deleted' }],
                "page": "1"
            }
            if (inputName === 'vehiclecategory') {
                Body['whereCondition']['code'] = "DURATION";
                setDuration([]);
            }
            {/*
            readRecord(Body).then(async (res) => {
                Body2['whereCondition']['codedomain2'] = "ObjectId-" + res.data[0]._id;
                readRecord(Body2).then(async (vclRes) => {
                    if (vclRes.data && vclRes.data.length) {
                        let tempData = [];
                        for (let i in vclRes.data) {
                            vclRes.data[i].codedomain_values2.label = vclRes.data[i].codedomain_values2.value;
                            if (vclRes.data[i].codedomain_values2.status === 'ACTV' && vclRes.data[i].codedomain_values2.deleted === null) {
                                tempData.push(vclRes.data[i].codedomain_values2);
                            }
                        }
                        if (inputName === 'country') {
                            setVehicleCat(tempData);
                        }
                        if (inputName === 'vehiclecategory') {
                            setDuration(tempData);
                        }
                        setIsLoading(false);
                    }
                }, err => { setIsLoading(false) })
            }, err => { setIsLoading(false) })
           */}
        } else {
            setIsLoading(false);
        }
    }


    const handlesubmit = (e) => {
        e.preventDefault();
        if (!selectedValue.country || !selectedValue.vehiclecategory || !selectedValue.duration) {
            warningToast("Please fill the required field");
            return;
        }
        dispatch(homedata(selectedValue))
        navigate("/order");
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        let Body = {
            table1: "codedomains",
            table2: "codedomain_values",
            table1JoinColumn: "_id",
            table2JoinColumn: "codedomain",
            table1SelectFields: { "name": 1, "code": 1 },
            table2SelectFields: { "value": 1, "code": 1, "_id": 1, "status": 1, "deleted": 1, "filename": 1 },
            whereCondition: { "code": "COUNTRY", "deleted": null, "codedomain_values.deleted": null },
            sortBy: { "codedomain_values.position": 1 },
        };
        console.log(countries);
        setCountry(countries);
        setIsLoading(false)
        {countries.map((country) => (
            console.log(country)
          ))}
          {/*
        readRecordJoin(Body).then(async (res) => {
            if (res.data && res.data.length) {
                for (let i in res.data[0].items) {
                    res.data[0].items[i].label = res.data[0].items[i].value;
                    if (res.data[0].items[i].status !== "ACTV") {
                        res.data[0].items[i].isDisabled = true;
                    }
                }
                setCountry(res.data[0].items);
                setIsLoading(false)
            }
        })
       */}
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileSkeleton(window.innerWidth <= 576)

        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    return (
        <div className="page-content">

            <div className="twm-home-11-banner-section">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-xl-8 col-lg-8 col-md-12">
                            {/* <div className="twm-bnr-left-section"> */}
                                {/* <div className="twm-bnr-title-large">It’s Easy to Find Your <br /><span className="site-text-primary">Vignette </span></div> */}
                                <h3 className="twm-bnr-discription mb-md-0 text-center">{t('Please select your single destination')}</h3>
                                {/* Do not remove comment by Paras */}
                                {/* <div className="twm-bnr-search-bar">
                                    <form onSubmit={handlesubmit}>
                                        <div className="row">
                                            <div className="form-group col-xl-3 col-lg-12 col-md-12">
                                                <label>Country</label>
                                                <Select options={countryOptions}
                                                    value={countryOptions.find(obj => obj.country === selectedValue)}
                                                    name="country"
                                                    onChange={handleChange}
                                                />

                                            </div>
                                            <div className="form-group col-xl-3 col-lg-12 col-md-12">
                                                <label>Vehicle category</label>

                                                <Select options={categoryOptions}
                                                    value={categoryOptions.find(obj => obj.value === selectedValue)}
                                                    name="vehiclecategory"
                                                    onChange={handleChange}
                                                />

                                            </div>
                                            <div className="form-group col-xl-3 col-lg-12 col-md-12">
                                                <label>Duration</label>
                                                <Select options={durationOptions}
                                                    value={durationOptions.find(obj => obj.value === selectedValue)}
                                                    name="duration"
                                                    onChange={handleChange}
                                                />

                                            </div>

                                            <div className="form-group col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                                <button type="submit" className="site-button">Order Now</button>
                                            </div>

                                        </div>
                                    </form>
                                </div> */}

                                <div className="swiper-container category-5-slider mt-3">
                                    {isLoading && <div style={{ display: 'flex' }}>
                                        <SkeletonTheme >
                                            {/* <Skeleton count={1}  height={160} width={173} />
                                        <Skeleton count={1} className="mx-4" height={160} width={173} />
                                        <Skeleton count={1} height={160} width={173} />
                                        <Skeleton count={1} className="mx-4" height={160} width={173} /> */}
                                            <div className={isMobileSkeleton ? "grid-container-homed" : "grid-container-home"}>
                                                <div className="grid-item item1"><Skeleton count={1} height={160} /></div>
                                                <div className="grid-item item2"><Skeleton count={1} height={160} /></div>
                                                <div className="grid-item item3"><Skeleton count={1} height={160} /></div>
                                                <div className="grid-item item4"><Skeleton count={1} height={160} /></div>

                                            </div>
                                        </SkeletonTheme>
                                    </div>}
                                    <div className="swiper-wrapper">
                                        <CategorySlide countryData={countryOptions} slides="5" />
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12 twm-bnr-right-main">
                            <div className="twm-bnr-right-content">
                            {/* Bundle changes
                            <h3 className="twm-bnr-discription mb-md-0 text-center">{t('( OR ) buy this bundle')}</h3>
                            */}
                                <div className="bnr-media-wrap">
                                    <div className="bnr-media">
                                        <img src="assets/images/drawing-car.png" alt="#" />
                                        {/* Start Bundle changes
                                        <div className="job-categories-home-5" style={{border: "8px solid #D3D3D3" }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onBundleSelect()}>
                                                <div>
                                                <div className="twm-media cat-bg-clr-1" >
                                                        <div className="flags" style={{
                                                            backgroundImage: `url('${imageURL}file-1685352570377.png')`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            borderColor: 'rgba(0,0,0,.1)',
                                                            borderRadius: '0.375rem',
                                                            borderWidth: '1px',
                                                            height: '38px',
                                                            width: '50px'
                                                        }}>
                                                        </div>
                                                </div>
                                                <div className="twm-content" key={1}>
                                                    <a>{t('Bulgaria')}</a>
                                                </div>
                                                </div>
                                                <div style={{
                                                        marginTop: "20px",
                                                        marginLeft: "20px",
                                                        marginRight: "20px",
                                                        backgroundImage: `url('${imageURL}plus.png')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center center',
                                                        backgroundRepeat: 'no-repeat',
                                                        borderColor: 'rgba(0,0,0,.1)',
                                                        borderWidth: '1px',
                                                        height: '32px',
                                                        width: '32px'
                                                    }}>
                                                </div>
                                                <div>
                                                    <div className="twm-media cat-bg-clr-1" >
                                                        <div className="flags" style={{
                                                            backgroundImage: `url('${imageURL}file-1685352697201.png')`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'top',
                                                            backgroundRepeat: 'no-repeat',
                                                            borderColor: 'rgba(0,0,0,.1)',
                                                            borderRadius: '0.375rem',
                                                            borderWidth: '1px',
                                                            height: '38px',
                                                            width: '50px'
                                                        }}>
                                                        </div>
                                                    </div>
                                                    <div className="twm-content" key={1}>
                                                    <a>{t('Austria')}</a>
                                                    </div>
                                                </div>
                                                <div style={{
                                                        marginTop: "20px",
                                                        marginLeft: "20px",
                                                        marginRight: "20px",
                                                        backgroundImage: `url('${imageURL}plus.png')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'top',
                                                        backgroundRepeat: 'no-repeat',
                                                        borderColor: 'rgba(0,0,0,.1)',
                                                        borderRadius: '0.375rem',
                                                        borderWidth: '1px',
                                                        height: '32px',
                                                        width: '32px'
                                                    }}>
                                                </div>
                                                <div>
                                                    <div className="twm-media cat-bg-clr-1" >
                                                        <div className="flags" style={{
                                                            backgroundImage: `url('${imageURL}file-1686725164770.png')`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            borderColor: 'rgba(0,0,0,.1)',
                                                            borderRadius: '0.375rem',
                                                            borderWidth: '1px',
                                                            height: '38px',
                                                            width: '50px'
                                                        }}>
                                                        </div>
                                                    </div>
                                                    <div className="twm-content" key={1}>
                                                        <a>{t('Hungary')}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="section-full p-t120 site-bg-white job-categories-home-5-wrap twm-bdr-bottom-1">
                <div className="container">
                    <div className="section-head center wt-small-separator-outer">
                        <h2 className="wt-title">Browse By Country</h2>
                    </div>
                </div>

                <div className="section-content twm-jobs-grid-h5-section-outer">
                    <div className="twm-jobs-grid-h5-section overlay-wraper swiper_mobile_bg" style={{
                        backgroundImage: `url('assets/images/home-5/cate-bg.jpg')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '364px',
                    }}>
                        <div className="overlay-main site-bg-primary opacity-09"></div>
                        <div className="swiper-container category-5-slider">
                            <div className="swiper-wrapper">

                                <CategorySlide countryData={countryOptions} slides="5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="section-full p-t70 p-b70 px-2 px-sm-5 px-md-5 px-lg-5 site-bg-white twm-about-1-area">

                <div className="container-fluid ">
                    <div className="twm-job-categories-section">

                        <div className="job-categories-style1 m-b30">
                            <div className="job-categories-carousel owl-btn-left-bottom row justify-content-center">

                                <div className="item col-md-4 p-md-0">
                                    <div className="job-categories-block m-0 ">
                                        <div className="twm-media">
                                            <div className="flaticon-deal"></div>
                                        </div>
                                        <div className="twm-content ms-3">
                                            <div className="twm-jobs-available">{t('Quicker')}</div>
                                            <a>{t('Save yourself the hassle of pulling off at the motorway and queuing.')}</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="item col-md-4 my-3 my-md-0 my-lg-0 my-xl-0 p-md-0">
                                    <div className="job-categories-block m-0 mx-md-2">
                                        <div className="twm-media">
                                            <div className="flaticon-project-management"></div>
                                        </div>
                                        <div className="twm-content ms-3">
                                            <div className="twm-jobs-available">{t('Real-time activation')}</div>
                                            <a>{t('Digital vignettes are valid immediately upon request and can also be purchased spontaneously before departure.')}</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="item col-md-4 p-md-0">
                                    <div className="job-categories-block m-0 ">
                                        <div className="twm-media">
                                            <div className="flaticon-bank"></div>
                                        </div>
                                        <div className="twm-content ms-3">
                                            <div className="twm-jobs-available">{t('Safer')}</div>
                                            <a>{t('With our Flex Vignette, you can change the validity date at a later date.')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Facebook Profile */}
            {/* <div className="section-full pt-5 p-b120 twm-for-employee-9-area site-bg-light-purple">
                <div className="container">
                    <div className="section-content">
                        <div className="twm-for-employee-9">
                            <div className="row">

                                <div className="col-lg-5 col-md-12">
                                    <div className="twm-explore-9-media-wrap">
                                        <div className="twm-media">
                                            <img src="assets/images/gir-large-2.png" alt="" />
                                            <div className="rectangle1-wrap">
                                                <div className="rectangle1 rotate-center"></div>
                                            </div>
                                            <div className="rectangle2-wrap">
                                                <div className="rectangle2"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-7 col-md-12">
                                    <div className="twm-explore-content-outer-3">

                                        <div className="twm-explore-content-3">
                                            <div className="twm-title-small">{t('Facebook Profile')}</div>
                                            <FacebookProvider appId="3397005297208221">
                                                <Page
                                                    href="https://www.facebook.com/izinyolugurbetcilerburaya"
                                                    tabs="timeline"
                                                    width={1000}
                                                    height={800}
                                                />
                                            </FacebookProvider>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div> */}

            {/* <div className="section-full p-t120 p-b90 site-bg-light-purple">
                <div className="container">
                    <div className="section-head center wt-small-separator-outer">
                        <h2 className="wt-title">News from our travel blog</h2>
                    </div>
                    <div className="section-content">
                        <div className="twm-blog-post-3-outer-wrap">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6">
                                    <FacebookProvider appId="3397005297208221">
                                        <Page
                                            href="https://www.facebook.com/izinyolugurbetcilerburaya"
                                            tabs="timeline"
                                            width={800}
                                        />
                                    </FacebookProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}


        </div>

    )
}

