import React from 'react';
import '../../App.css';
import { Link, Outlet } from 'react-router-dom';
import Sidebar from './sidebar.js';

export default function Dashboard() {
   window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  return (
    <div className="page-content">
      {/* <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("../assets/images/banner/1.jpg")' }}>
         <div className="overlay-main site-bg-white opacity-01"></div>
         <div className="container">
               <div className="wt-bnr-inr-entry">
                     <div className="banner-title-outer">
                        <div className="banner-title-name">
                           <h2 className="wt-title">Customer area</h2>
                        </div>
                     </div>

                     <div>
                        <ul className="wt-breadcrumb breadcrumb-style-2">
                           <li>
                             <Link to="/home">
                                 <a>Home</a>
                             </Link>
                           </li>
                           <li>Customer area</li>
                        </ul>
                     </div>
               </div>
         </div>
      </div> */}
      <div className="section-full p-t120 p-b50 site-bg-white">
         <div className="container">
            <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30">
                      <Sidebar />
                  </div>
                  <div className="col-xl-9 col-lg-8 col-md-12 m-b30">
                     <div className="twm-right-section-panel site-bg-light-purple p-3">
                       <Outlet />
                     </div>
                  </div>
            </div>
         </div>
      </div>
    </div>
  )
};