import React from 'react';
import { Link } from 'react-router-dom';

export default function Blog() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content p-t120">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{backgroundImage:'url(assets/images/banner/1.jpg)'}}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Blog detail</h2>
                            </div>
                        </div>
                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">Home</Link></li>
                                <li>Blog detail</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30">
                                                <img src="assets/images/blog/blog-single/1.jpg" alt="" />
                                            </div>

                                            <div className="wt-post-title ">
                                                <div className="wt-post-meta-list">
                                                    <div className="wt-list-content post-date">April 05, 2023</div>
                                                    <div className="wt-list-content post-author">By Mark Petter</div>
                                                </div>
                                                <h3 className="post-title">How to convince recruiters and get your dream vignette</h3>

                                            </div>

                                            <div className="wt-post-discription">

                                                <p>
                                                    Please make sure you understand what rights you are claiming before you
                                                    submit a DMCA takedown
                                                    notice because it is a serious legal document. Consider whether you need
                                                    legal advice. It's really
                                                    important not to make false claims as this could have serious legal
                                                    consequences.
                                                </p>
                                                <p>
                                                    penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer
                                                    tristique elit lobortis purus bibendum, quis dictum metus mattis. Phasellus
                                                    posuere felis sed eros porttitor mattis. Curabitur massa magna, tempor in
                                                    blandit id, porta in ligula. Aliquam laoreet nisl massa, at interdum mauris
                                                    sollicitudin et.Harvel is a copyright protection platform for next-gen
                                                    creators, crawling the web on a daily basis in order to find piracy links
                                                    and copyright infringement of your content. I
                                                </p>

                                                <h4 className="twm-blog-s-title">About Business Network</h4>
                                                <p>
                                                    Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla
                                                    tellus, eleifend euismod and pellentesque vel, sagittis vel justo. In libero
                                                    urna, venenatis sit amet ornare non, suscipit nec risus. Sed consequat justo
                                                    non mauris pretium at tempor justo sodales.
                                                </p>
                                                <div className="wt-post-discription">
                                                    <blockquote>
                                                        <p><span>"</span> A business consulting agency is involved in the
                                                            planning, implementation,
                                                            and education of businesses.
                                                        </p>
                                                        <strong>Richard Anderson</strong>
                                                    </blockquote>
                                                </div>

                                                <h4 className="twm-blog-s-title">Get Your Resume Done Right</h4>
                                                <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla
                                                    tellus, eleifend euismod and pellentesque vel, sagittis vel justo. In libero
                                                    urna, venenatis sit amet ornare non, suscipit nec risus. Sed consequat justo
                                                    non mauris pretium at tempor justo sodales. </p>
                                            </div>

                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    <li>
                                                        <i className="feather-check"></i>
                                                        You need to create an account to find the best and preferred vignette.
                                                    </li>
                                                    <li>
                                                        <i className="feather-check"></i>
                                                        After creating the account, you have to apply for the desired vignette.
                                                    </li>
                                                    <li>
                                                        <i className="feather-check"></i>
                                                        After filling all the relevant information you have to upload your
                                                        resume.
                                                    </li>

                                                </ul>

                                            </div>


                                            <div className="twm-posts-author">
                                                <div className="twm-post-author-pic">
                                                    <img src="assets/images/blog/post-author.jpg" alt="#" />
                                                </div>
                                                <div className="twm-post-author-content">
                                                    <span>435 Posts Since 2018</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                        tempor
                                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    </p>
                                                    <strong>Rosalina William</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12 rightSidebar">

                                <div className="side-bar">

                                    <div className="widget recent-posts-entry">
                                        <h4 className="section-head-small mb-4">Recent Article</h4>
                                        <div className="section-content">
                                            <div className="widget-post-bx">

                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media">
                                                        <img src="assets/images/blog/recent-blog/pic1.jpg" alt="" />
                                                    </div>
                                                    <div className="wt-post-info">
                                                        <div className="wt-post-header">
                                                            <span className="post-date">April 08, 2023</span>
                                                            <span className="post-title">
                                                                <a>Equipment you can count on. People
                                                                    you can trust.</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media">
                                                        <img src="assets/images/blog/recent-blog/pic2.jpg" alt="" />
                                                    </div>
                                                    <div className="wt-post-info">
                                                        <div className="wt-post-header">
                                                            <span className="post-date">April 12, 2023</span>
                                                            <span className="post-title">
                                                                <a>Advanced Service Functions by Air
                                                                    Transport</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media">
                                                        <img src="assets/images/blog/recent-blog/pic3.jpg" alt="" />
                                                    </div>
                                                    <div className="wt-post-info">
                                                        <div className="wt-post-header">
                                                            <span className="post-date">April 15, 2023</span>
                                                            <span className="post-title">
                                                                <a>Proper arrangement for keeping the
                                                                    goods in the warehouse</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media">
                                                        <img src="assets/images/blog/recent-blog/pic4.jpg" alt="" />
                                                    </div>
                                                    <div className="wt-post-info">
                                                        <div className="wt-post-header">
                                                            <span className="post-date">April 18, 2023</span>
                                                            <span className="post-title">
                                                                <a>Equipment you can count on. People
                                                                    you can trust.</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media">
                                                        <img src="assets/images/blog/recent-blog/pic5.jpg" alt="" />
                                                    </div>
                                                    <div className="wt-post-info">
                                                        <div className="wt-post-header">
                                                            <span className="post-date">April 20, 2023</span>
                                                            <span className="post-title">
                                                                <a>Proper arrangement for keeping the
                                                                    goods in the warehouse</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    );
}