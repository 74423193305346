import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FlexService() {
    const { t } = useTranslation();
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{t('Our flex service for e-vignettes')}</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">{t('Home')}</Link></li>
                                <li>{t('Flex Service')}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full p-t90 p-b90 site-bg-white">
                <div className="">
                    <div className="section-content">
                        <div className="twm-blog-post-h5-wrap container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer mb-5">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>{t('With our unique flex service, you can stay flexible until you set off, regardless of whether you need to use a different car or change the date.')}
                                                </h4>
                                                <p>
                                                {t('The flex service is an optional add-on service that can be booked for a fee when ordering an e-vignette. You cannot book the flex service at a later date.')}
                                                </p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{backgroundImage: 'url(assets/images/ftr-bg.jpg)'}}>
                            <div className="container p-5">
                                <h4 className="twm-s-title">{t('Flex service benefits')}</h4>
                                <ul className="description-list-2">
                                    <li>
                                        <i className="feather-help-circle"></i>
                                        {t('You can change your registration number as you please.')}
                                    </li>
                                    <li>
                                        <i className="feather-help-circle"></i>
                                        {t('Your travel date is flexible and can be changed based on your individual needs')}.
                                    </li>
                                    <li>
                                        <i className="feather-help-circle"></i>
                                        {t('No risk: you can cancel your e-vignette and be refunded the cost of the vignette')}.
                                    </li>
                                </ul>
                                <div className="twm-job-self-bottom">
                                    <a className="site-button" data-bs-toggle="modal" href="#apply_job_popup" role="button">
                                    {t('Register for an e-vignette now')}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="twm-blog-post-h5-wrap container pt-5">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text ">
                                                <p>
                                                {t('Our Affiliate Partner Programme connects your company or your personal blog with the online portal used throughout Europe for electronic vehicle tolls. Millions of motorists use the e-vignette every year for their journeys through Europe')}.
                                                </p>
                                                <p>
                                                {t('As a auto-vignette partner, you can integrate individual advertising material on your website and receive payments when a customer registers an e-vignette via our website. Thanks to our optimised website, we have above-average conversion rates that will benefit you')}.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};