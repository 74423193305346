import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { successToast, warningToast, errorToast } from '../components/toastify';
import { useState } from 'react';
import { createRecord, sendEmail } from "../Redux/Action/Action";
import { useTranslation } from 'react-i18next';


export default function Registration() {
   const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,1000}$/;
   const navigate = useNavigate();
   const { t } = useTranslation();
   const [registration, setRegistration] = useState({ first_name: "", last_name: "", email: "", password: "", confirm_password: "" })
   const [isLoading, setIsLoading] = useState(false)
   const [changePassword, setChangePassword] = useState(false);
   const [changeconfirmPassword, setChangeConfirmPassword] = useState(false);
   const [isPassOneError, setIsPassOneError] = useState(false);
   const [isPassTwoError, setIsPassTwoError] = useState(false);

   const showhidepaswd = () => {
      setChangePassword(!changePassword);
   }
   const showhideconfirmpaswd = () => {
      setChangeConfirmPassword(!changeconfirmPassword);
   }



   // const { email, password, confirm_password } = registration;
   const handleChange = (e) => {
      const { name, value } = e.target
      setRegistration({ ...registration, [name]: value });
      const isValid = passwordPattern.test(value);
      if (name === 'password') {
         setIsPassOneError(!isValid);
      }
      if (name === 'confirm_password') {
         setIsPassTwoError(!isValid);
      }

   }

   const signUpSubmit = (e) => {
      e.preventDefault()
      if (!registration.first_name || !registration.last_name || !registration.email || !registration.password || !registration.confirm_password) {
         warningToast("Please fill the required field");
         return;
      }
      if (isPassOneError || isPassTwoError) {
         warningToast("Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet.");
         return;
      }
      if (registration.password !== registration.confirm_password) {
         warningToast("Confirm password are not matching");
         return;
      }
      setIsLoading(true)
      const Body = {
         "record": {
            "first_name": registration.first_name.trim(),
            "last_name": registration.last_name.trim(),
            "email": registration.email.trim(),
            "password": registration.password,
            "company_name": "",
            "title": "",
            "address": "",
            "postcode": "",
            "city": "",
            "vat_id": "",
            "status": "ACTV",
            "user_type": "customer",
            "create_user": null,
            "deleted": null,
            "deletedby": null,
         },
         "table": "customers",
         "uniqueFieldsContionsOperator": "and",
         "uniqueFields": {
            "email": registration.email,
            "deleted": null,
         },
         "uniqueFieldErrMsg": "Record alrady exist."
      }

      createRecord(Body).then(async (res) => {
         setIsLoading(false)
         if (res.status) {
            sendEmail({
               userId: res.data._id,
               password: registration.password,
               eventname: "Customer signup"
            }).then(async (res) => {
               navigate("/login");

            }, err => {
               navigate("/login");
            })
            successToast("Customer registered successfully");


         } else {
            errorToast(res.message);
         }

      }, err => { setIsLoading(false) })

   }
   return (
      <>
         <div className="page-content p-t120">
            <div className="section-full site-bg-white">
               <div className="container-fluid">
                  <div className="row justify-content-center">
                     {/* <div className="col-xl-8 col-lg-6 col-md-5 twm-log-reg-media-wrap">
                            <div className="twm-log-reg-media">
                                <div className="twm-l-media">
                                    <img src="assets/images/login-bg.png" alt=""/>
                                </div>
                            </div>
                        </div> */}
                     <div className="col-xl-4 col-lg-6 col-md-7 shadow mb-3 rounded">
                        <div className="twm-log-reg-form-wrap pb-0">
                           {/* <div className="twm-log-reg-logo-head">
                           <Link to="/home">
                              <a>
                                 <img src="assets/images/logo-dark.png" alt="" className="logo" />
                              </a>
                           </Link>
                        </div> */}

                           <div className="twm-log-reg-inner">
                              <div className="twm-log-reg-head">
                                 <div className="twm-log-reg-logo">
                                    <span className="log-reg-form-title">{t('Sign Up')}</span>
                                    <p>{t('Please enter your email address and your chosen password')}</p>
                                 </div>
                              </div>
                              <div className='twm-sign-up'>
                                 <form onSubmit={signUpSubmit}>
                                    <div className="twm-tabs-style-2">
                                       <div className="tab-content" id="myTabContent">
                                          <div className="tab-pane fade show active" id="sign-candidate">
                                             <div className="row">

                                                <div className="col-lg-12">
                                                   <div className="form-group mb-3">
                                                      <input name="first_name" value={registration.first_name} type="text" onChange={handleChange} className="form-control" placeholder={t("First name*")} />
                                                   </div>
                                                </div>
                                                <div className="col-lg-12">
                                                   <div className="form-group mb-3">
                                                      <input name="last_name" value={registration.last_name} type="text" onChange={handleChange} className="form-control" placeholder={t("Last name*")} />
                                                   </div>
                                                </div>
                                                <div className="col-lg-12">
                                                   <div className="form-group mb-3">
                                                      <input name="email" value={registration.email} type="email" onChange={handleChange} className="form-control" placeholder={t("Email*")} />
                                                   </div>
                                                </div>

                                                <div className="col-lg-12">
                                                   <div className="form-group mb-3 position-relative d-flex">
                                                      <input name="password" value={registration.password} type={changePassword ? "text" : "password"} onChange={handleChange} className="form-control" placeholder={t("Password*")} />
                                                      <i className={"position-absolute fa-paswd-show-hide fa-regular " + `${changePassword ? "fa-eye" : "fa-eye-slash"}`} onClick={() => { showhidepaswd() }}></i>
                                                   </div>
                                                   {isPassOneError ? <p className="text-danger text-start">{t('Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet')}.</p> : ''}
                                                </div>

                                                <div className="col-lg-12">
                                                   <div className="form-group mb-3 position-relative d-flex">
                                                      <input name="confirm_password" value={registration.confirm_password} type={changeconfirmPassword ? "text" : "password"} onChange={handleChange} className="form-control" placeholder={t("Confirm password (repeat)*")} />
                                                      <i className={"position-absolute fa-paswd-show-hide fa-regular " + `${changeconfirmPassword ? "fa-eye" : "fa-eye-slash"}`} onClick={() => { showhideconfirmpaswd() }} ></i>
                                                   </div>
                                                   {isPassTwoError ? <p className="text-danger text-start">{t('Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet')}.</p> : ''}
                                                </div>

                                                <div className="col-md-12">
                                                   <div className="form-group">
                                                      {/* <Link to="/login"> */}
                                                      <button type="submit" className="site-button">{t('Register')}</button>
                                                      {/* </Link> */}
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {isLoading &&
            <div className="sub-loading">
               <div className="loader">
                  <span className="text-large font-weight-bolder">
                     <img src="/assets/images/loader.svg" />
                  </span>
               </div>
            </div>
         }
      </>
   )
};