import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function TermsConditions() {
    const { t } = useTranslation();

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{t('Terms and conditions / Right to cancellation')}</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">{t('Home')}</Link></li>
                                <li>{t('Terms and conditions / Right to cancellation')}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full p-t90 p-b90 site-bg-white">
                <div className="">
                    <div className="section-content">
                        <div className="twm-blog-post-h5-wrap container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer mb-5">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Scope')}</h4>
                                                <p>
                                                {t("The contractual relationship between you (hereinafter referred to as 'Customer') and us, ILTERLER Ltd trading as auto-vignette.com in UK (hereinafter referred to as 'Auto-vignette' or 'we'), shall be governed by and construed in accordance with these General Terms and Conditions. General customer terms and conditions that differ from these terms do not apply. These General Terms and Conditions shall not apply, even if we do not expressly object to them.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Object')}</h4>
                                                <p>
                                                {t("On the website auto-vignette, provides an online service through which a customer can request for issuing a digital motorway vignette for the number plate they specify from the competent authority, the competent motorway operator, or the competent sales outlet (hereinafter)")} 
                                                </p>
                                                <p>
                                                {t("On the basis of national regulations, ordinances, and laws, only the relevant authority in the destination country may issue motorway vignettes. A digital vignette can only be issued at the discretion of the relevant entity. Auto-vignette has no control over the responsible body's choice to issue or not issue a digital vignette.  In this regard, Auto-vignette owes the client just the execution of the application for the required digital vignette, not the issuance of the vignette itself. Auto-vignette specifically states that it does not sell or issue vignettes, operate highways, or give road usage rights.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Contract')}</h4>
                                                <p>
                                                {t("Only an invitation to order is made by the presentation of services and goods on our website; no legal commitment is implied.")} 
                                                </p>
                                                <p>
                                                {t("You bind the services and goods specified on the order page by clicking the 'Order subject to payment' button.  Auto-vignette will send you an email to let you know when your order has been received. This acknowledgement of receipt, however, does not imply that your offer to enter a contract has been accepted. Depending on the mode of payment the consumer chooses, Auto-vignette will decide when to end the contract. Depending on the payment method chosen, we start the payment process after receiving the order and, if necessary, transfer the customer straight to the payment provider they chose for this purpose, where he can confirm the payment instruction after entering his payment information. However, the Customer's subsequent payment of the purchase price or fee and the sending by us to an accepted immediate payment method do not yet represent an acceptance of auto-vignette's offer to enter a contract and do not, thus, result in the formation of a contract.")} 
                                                </p>
                                                <p>
                                                {t("Auto-vignette expressly states in text ( e.g. email)  that it accepts the terms of the contract.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Fulfilment')}</h4>
                                                <p>
                                                {t("As soon as auto-vignette submits an application to the appropriate body for the issuance of the vignette requested by the client, the contract will be deemed to have been performed by us.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Prices and payment')}</h4>
                                                <p>
                                                {t("The prices listed on our website include all applicable taxes, including the statutory value added tax. The costs are set in stone. MasterCard or Visa credit cards, PayPal, Giropay, or cash are all acceptable forms of payment. We are willing to take credits to our PayPal account on account of performance if the customer selects PayPal as the method of payment.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Obligations of the customer')}</h4>
                                                <p>
                                                {t("The Customer promises to assist Auto-vignette in completing the order. In particular, the Customer is required to give auto-vignette accurate information about themselves and the car they want registered.")} 
                                                </p>
                                                <p>
                                                {t("The customer guarantees that he or she is the registered driver or owner of the car, or that the registered owner has given the customer complete permission to request a motorway toll sticker on behalf of the registered owner of the car whose license plate the customer is requesting.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Liability')}</h4>
                                                <p>
                                                {t("We will only be responsible for damages if we have violated a crucial contractual term (cardinal obligation) in a way that harmed the agreement's goals, or if the auto-vignette caused the harm intentionally or with gross negligence. Our liability shall be limited to the damage that was reasonably foreseeable to us at the time of the completion of the contract if the culpable breach of an essential contractual commitment (cardinal obligation) is not the result of gross negligence or willful misconduct. Our liability for guaranteed features, for personal injury, and for violations of mandatory legal requirements is unaffected.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Cancellation policy')}</h4>
                                                <p>
                                                {t("Consumers have a fourteen-day window in which they may withdraw from a contract without providing a justification providing that services is not activated yet(Depending on the country). From the day the contract was signed, the revocation period is fourteen days. You must give us (ILTERLER Ltd, 50 victory road, Shirley, Southampton, UK Mail: info@auto-vignette.com) clear notice (such as a letter sent by mail or email) of your decision to revoke this contract in order to exercise your right of revocation. You are free to utilise the sample revocation form that is supplied for this purpose; it is not required. It is sufficient to send the notification of the exercise of the right of revocation prior to the revocation period's expiration in order to comply with the revocation period.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Revocation process')}</h4>
                                                <p>
                                                {t("If you revoke this contract, we must immediately and at the latest within fourteen days of the day we received notice of your decision to do so, refund all payments we have received from you, including the cost of delivery (aside from any additional fees because you chose a delivery option other than the least expensive standard delivery we offer). Unless otherwise specifically agreed with you, we will use the same method of payment that you used for the first transaction for this reimbursement; under no circumstances will you be charged for this payback.If you've asked for the services to start during the withdrawal period, you must pay us a reasonable sum that corresponds to the portion of the services that have already been rendered up until the point that you notify us that you're exercising your right to cancel this contract's obligations, as compared to the full scope of the services covered by the agreement.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Sample cancellation form')}</h4>
                                                <p>
                                                {t("(To terminate the agreement, send us an email specified below.)")} 
                                                </p>
                                                <p>
                                                {t("email info@auto-vignette.com")} 
                                                </p>
                                                <p>
                                                {t("I/we (*) hereby revoke the agreement we (*) agreed into to buy the things from you (*) or render the services to you (*) set out below.  the provision of the foregoing goods or services (*) ordered or received on (*)")} 
                                                </p>
                                                <p>
                                                {t("Name(s) of the consumer(s)")} 
                                                </p>
                                                <p>
                                                {t("Address for the Client")} 
                                                </p>
                                                <p>
                                                {t("Signature of the customer (only needed for paper correspondence)")} 
                                                </p>
                                                <p>
                                                {t("Date")} 
                                                </p>
                                                <p>
                                                {t("License plate Number")} 
                                                </p>
                                                <p>
                                                {t("Remove where it doesn't apply- (*).")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Early termination of the right to withdraw:')}</h4>
                                                <p>
                                                {t("When it comes to a contract for the provision of services, the right to cancel also expires if we have fully performed the service and have only begun to do so after receiving your express consent and confirmation that you are aware that you will lose your right to cancel once we have completed the contract's performance.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Amendment')}</h4>
                                                <p>
                                                {t("The agreement between the Customer and auto-vignette may only be amended or supplemented by written agreement. This also holds true for the demand for a written form(email). Collateral verbal agreements are not considered to have been made.")} 
                                                </p>
                                            </div>
                                            <div className="wt-post-text p-2">
                                                <h4>{t('Place of Jurisdiction/Applicable Law/ Miscellaneous')}</h4>
                                                <p>
                                                {t("(1) Auto-vignette's registered office shall serve as the sole venue for all present and future claims resulting from commercial dealings with fully qualified merchants, including claims based on bills of exchange and checks. In any case, Auto-vignette may also bring its cases before the courts that have universal jurisdiction over the Customer. Any exclusive jurisdictional location shall not be impacted. Southampton, UK will host the performance.")} 
                                                </p>
                                                <p>
                                                {t("(2) If the customer does not have a general place of jurisdiction in UK, moves his place of residence or habitual abode outside of UK after the contract is signed, or if his place of residence or habitual abode is unknown at the time the action is brought, the location determined above shall also apply.")} 
                                                </p>
                                                <p>
                                                {t("(3) To the exclusion of the UN Convention on Contracts for the International Sale of Goods, the legal relationship between the contracting parties shall be governed solely by UK law.")} 
                                                </p>
                                                <p>
                                                {t("(4) The customer only has a right of retention for claims from this contract that are counterclaims. Only uncontested or legally established claims may be offset by the customer.")} 
                                                </p>
                                                <p>
                                                {t("(5) The Customer may get all declarations from auto-vignette electronically. This also holds true for bills that fall under the purview of the business arrangement.")} 
                                                </p>
                                                <p>
                                                {t("(6) If any term of this Agreement is found to be or to be rendered wholly or partially invalid, the validity of the remaining sections shall not be impacted. In any situation, the parties are required to agree on a replacement clause to fill the vacuum left by an invalid or unenforceable term in a way that is acceptable and enforceable in the context of the contract")} 
                                                </p>
                                                <p>
                                                {t("- End of general terms and conditions-")} 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};