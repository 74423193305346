import React from 'react';

export default function AutomaticExtensions() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div className="page-content">
        <h1 className='text-center'>automatic extensions</h1>
    </div>
  )
};