import { toast } from 'react-toastify';

export const successToast = (msg) => {
    toast(msg, {
        position: "top-right",
        type:'success',
        autoClose: 5000,
        theme:'colored'
    })
};

export const errorToast = (msg) => {
    toast(msg, {
        position: "top-right",
        type:'error',
        autoClose: 7000,
        theme:'colored'
    })
};

export const warningToast = (msg) => {
    toast(msg, {
        position: "top-right",
        type:'warning',
        autoClose: 5000,
        theme:'colored'
    })
};