import React from 'react';
import { Link } from 'react-router-dom';

export default function CustomerAccount() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div className="page-content">
      <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
        <div className="overlay-main site-bg-white opacity-01"></div>
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title">Customer Account</h2>
              </div>
            </div>

            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li><Link to="/home">Home</Link></li>
                <li>Customer Account</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
};