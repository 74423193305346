// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import { swiperdata, homedata } from '../Redux/Action/Action';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { warningToast } from '../components/toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default (props) => {
  const { countryData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [country, setCountry] = useState("")
  const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";

  const onItemSelect = (data) => {
    if (data.status !== 'ACTV') {
      console.log("test");
      // warningToast("Vehicles not available for this Country")
      return;
    }
    dispatch(homedata({
      country: data._id,
      vehiclecategory: "",
      duration: ""
    }))
    localStorage.removeItem('saveAsDraft');
    navigate("/order");
  }


  return (
    <>
      {/* <Swiper
        pagination={{
            type: "fraction",
          }}
          navigation={true}
            spaceBetween={20}
            slidesPerView={props.slides}
            
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            breakpoints={{
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1700: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
        >
            
            {countryData.map((item, index) => (
                
                <SwiperSlide className='slide_mobileview' key={index}>
                    
                    <div className="swiper-slide pointer" >
                        <div onClick={() => onItemSelect(item)} className="job-categories-home-5"  >
                            <div className="twm-media cat-bg-clr-1" >
                                <div className="flags" style={{
                                    
                                    backgroundImage: `url('${imageURL}${item.filename}')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    borderColor: 'rgba(0,0,0,.1)',
                                    borderRadius: '0.375rem',
                                    borderWidth: '1px',
                                    height: '38px',
                                    width: '50px'
                                }}>
                                </div>
                            </div>
                            <div className="twm-content" key={index+2}>
                                <a>{item.value}</a> 
                                {item.status !== 'ACTV' ? <p className='m-0 text-danger'>Coming soon...</p>:<p className='courtyactv m-0'>Active</p>}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper> */}

      <Swiper
        pagination={{
          type: "fraction",
        }}
        autoplay={{
          delay: 3000, // Delay between slides in milliseconds
          disableOnInteraction: false, // Allow user interaction to pause autoplay
        }}
        navigation={true}
        // loop={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
        onSwiper={(swiper) => { }}
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1700: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
      >
        {countryData.map((item, index) => (

          <SwiperSlide className='slide_mobileview' key={index}>

            <div className="swiper-slide pointer" >
              <div onClick={() => onItemSelect(item)} className="job-categories-home-5"  >
                <div className="twm-media cat-bg-clr-1" >
                  <div className="flags" style={{

                    backgroundImage: `url('${imageURL}${item.filename}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderColor: 'rgba(0,0,0,.1)',
                    borderRadius: '0.375rem',
                    borderWidth: '1px',
                    height: '38px',
                    width: '50px'
                  }}>
                    {/* <img src={item.icon} alt="" /> */}
                  </div>
                  {/* <div className={item.icon} ></div> */}
                </div>
                <div className="twm-content" key={index + 2}>
                  <a>{t(item.value)}</a>
                  {item.status !== 'ACTV' ? <p className='m-0 text-danger'>{t("Coming soon")}...</p> : <p className='courtyactv text-them m-0'>{t("Active")}</p>}

                  {/* <div className={item.icon} ></div> */}
                  {/* <div className="twm-jobs-available" >{item.price}</div> */}

                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};