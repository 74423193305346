import React, { useState, useEffect } from "react";
import GooglePayButton from '@google-pay/button-react';
import { successToast, errorToast } from '../components/toastify';
import { paypalPayment, digitollPost, createRecord } from "../Redux/Action/Action";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import PopupDialog from './mypopup';
import envData from '../env.json'; 

const stripePk = envData.stripePk;

const MyGooglePay = ({callback, loginUser, amount, vehicleName, allInfo}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  
  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const saveErrorLogs = (data) => {
    // add
    const Body = {
      "record": {
        site: "Vignette frontend",
        error: JSON.stringify(data.error || {}),
        function_name: data.function_name,
        type: data.type,
        create_user: loginUser['_id'],
        status: "ACTV",
        deleted: null,
        deletedby: null,
      },
      "table": "error_logs",
      "uniqueFields": {},
      "uniqueFieldErrMsg": "Error in error_logs save"
    }

    createRecord(Body).then(async (res) => {
      setIsLoading(false)

    }, err => { setIsLoading(false) })
  }

  return (
      <div>
        {/* Other content on your payments page */}
        <GooglePayButton
          environment="TEST" // Use "PRODUCTION" for live environment
          buttonColor="default"
          buttonType="buy"
          buttonRadius="4"
          buttonSizeMode="fill"
          style={{width: "100%"}}
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER'],
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    gateway: 'stripe',
                    "stripe:version": '2018-10-31',
                    "stripe:publishableKey": stripePk,
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: 'BCR2DN4TYGE7Z33T',
              merchantName: 'Ilterler Ltd',
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPriceLabel: 'Total',
              totalPrice: amount.toFixed(2),
              currencyCode: allInfo.currency,
              countryCode: allInfo.registrationCountry.countryCode,
            },
          }}
          onLoadPaymentData={(paymentRequest) => {
            console.log('load google pay data', paymentRequest);
            let openPopupNeeded = false;
            let savePartnersSaleRes = null;
            let vigForBulgaria = null;
            if (allInfo.allVignettes != null && allInfo.allVignettes.length > 0) {
                setIsLoading(true);
                for (let i in allInfo.allVignettes) {
                  let vigInfo = allInfo.allVignettes[i];
                  console.log(">>> Now processing VigInfo",vigInfo);
                  if (vigInfo.processing == 'PaymentOnly') {  // For PaymentOnly eg. Austria just complete the payment and invoke REST to send email
                     openPopupNeeded = true;
                     continue;
                  }
                  if (vigInfo.processing !== 'PaymentAndDigitoll') {
                     console.log("AAAAA - Unknown processing option or not implemented - "+vigInfo.processing+" for country "+vigInfo.countryName);
                     continue;
                  }
                  vigForBulgaria = vigInfo;
                } // end of for
                if (vigForBulgaria != null) {
                  let vigInfo = vigForBulgaria;
                  console.log("AAAAA - Now processing digitoll for Destination country "+vigInfo.countryName);
                  digitollPost({
                   "url": "user/authenticate",
                   "delvyBody": {}
                 }).then(async (tokenRes) => {
                   if (tokenRes.status) {
                     // Get digitoll products
                     digitollPost({
                       "token": tokenRes.data.token,
                       "url": "products",
                       "method": "GET",
                     }).then(async (res1) => {
                       let kapschProduct = {};
                       if (res1.status) {
                         for (let i in res1.data) {
                           if (res1.data[i].validityTypeText === vigInfo.durationName) {
                               if (res1.data[i].vehicleType === 'car' && vigInfo.vehicleName === 'Car') {
                                 kapschProduct = res1.data[i];
                               }
                               if (res1.data[i].vehicleType === 'trailer' && vigInfo.vehicleName !== 'Car') {
                                 kapschProduct = res1.data[i];
                               }
                            }
                         }
                       } else {
                         saveErrorLogs({
                           error: res1?.message,
                           function_name: "products",
                           type: "Digitoll products get",
                         })
                       }
                       let startDate = new Date();
                       if (vigInfo.isBuyNow) {
                          startDate.setMinutes(startDate.getMinutes() + 2);
                       } 
                       else {
                          startDate = vigInfo.startDate;
                       }
                       digitollPost({
                         "token": tokenRes.data.token,
                         "url": "partners/sale",
                         "delvyBody": {
                           "posId": "123",
                           "saleRows": [
                             {
                               "activationDate": startDate.toISOString(),
                               "email": allInfo.userInfo.email,
                               "kapschProductId": kapschProduct.id,
                               "remoteClientId": "",
                               "vehicle": {
                                 "countryCode": allInfo.registrationCountry.countryCode || "BG",
                                 "lpn": allInfo.license_plate.license_plate
                               }
                             }
                           ]
                         }
                       }).then(async (partnersSaleRes) => {
                         savePartnersSaleRes = partnersSaleRes;
                         if (partnersSaleRes.status) {
                            let body = {};
                            if (openPopupNeeded) {
                               openPopup();
                            }
                            body['googlepay_order'] = paymentRequest;
                            callback(body, "PayPal", savePartnersSaleRes);
                         } 
                         else {
                           let errorMsg = "";
                           let errorMsg2 = "";
                           if (partnersSaleRes?.message) {
                            errorMsg = partnersSaleRes.message;
                          }
                          if (partnersSaleRes?.message?.message) {
                            errorMsg2 = partnersSaleRes.message.message;
                          }
                           Swal.fire({
                             icon: 'error',
                             title: 'Oops...',
                             titleText: errorMsg2 || 'Something went wrong',
                             text: errorMsg || 'Invalid License plate!',
                           })
                           errorToast("Order not placed");
                           saveErrorLogs({
                             error: partnersSaleRes?.message,
                             function_name: "Digitoll Place order",
                             type: "Digitoll (partners/sale)",
                           })
                           setIsLoading(false);
                         }
                       }, err => {
                       })
                     }, err => {
                       setIsLoading(false);
                     })
                   } else {
                     setIsLoading(false);
                   }
                 }, err => {
                   setIsLoading(false);
                 })
                } // end of if Bulgaria
            } // if condition allVignettes
            else {
              let body = {};
              if (openPopupNeeded) {
                  openPopup();
              }
              body['googlepay_order'] = paymentRequest;
              callback(body, "PayPal", savePartnersSaleRes);
            }
            // paymentToken = paymentRequest.paymentMethodData.tokenizationData.token;
          } // onLoadPayment function body ends
        } // onLoadPayent declaration ends
        />
       {showPopup && <PopupDialog message={t("GooglePay Payment was successful, an email will be sent to you near the activation date or time. Please wait for your vignette activation email before crossing the border.")} onClose={closePopup} />}
      </div>
    );
  };

  export default MyGooglePay;
  