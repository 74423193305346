import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { homedata } from "../Redux/Action/Action";
// import { homedata, readRecordJoin, readRecord } from "../Redux/Action/Action";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import data from '../data.json'; 

const Footer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const { t } = useTranslation();
    const [countrylist, setCountry] = useState(data.Countries)

    const onItemSelect = (data) =>{
        // if(data.status !== 'ACTV'){
        //     warningToast("Vehicles not available for this Country")
        //     return;
        // }
        dispatch(homedata({
            country : data._id,
            vehiclecategory : "",
            duration : ""
        }))
        navigate("/order");
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        let Body = {
            table1: "codedomains",
            table2: "codedomain_values",
            table1JoinColumn: "_id",
            table2JoinColumn: "codedomain",
            table1SelectFields: { "name": 1, "code": 1 },
            table2SelectFields: { "value": 1, "code": 1, "_id": 1, "status": 1, "deleted": 1, "filename": 1 },
            whereCondition: { "code": "COUNTRY", "deleted": null, "codedomain_values.deleted": null },
            sortBy: { "codedomain_values.position": 1 },
        }
        {/*
        readRecordJoin(Body).then(async (res) => {
            if (res.data && res.data.length) {
                for (let i in res.data[0].items) {
                    res.data[0].items[i].label = res.data[0].items[i].value;
                    if (res.data[0].items[i].status !== "ACTV") {
                        res.data[0].items[i].isDisabled = true;
                    }
                }
                setCountry(res.data[0].items);               
            }
        })
    */}
    }, []);

  

    return (
        <footer className="footer-dark">
            <div className="footer-section-content">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="widget widget_about">
                                <div className="logo-footer clearfix">
                                    <Link to="/home">
                                        <img src="assets/images/logo-white.png" alt="" />
                                    </Link>
                                </div>
                                <p>{t('auto-vignette is your popular toll portal for Europe’s motorways. Millions of motorists use the e-vignette every year. Motorists benefit from secure online registration of the motorway vignette. No queuing at the toll station')}.</p>
                                {/* <ul className="ftr-list">
                                    <li>
                                        <p>
                                            <span>E-vignettes for Europe’s motorways</span>
                                        </p>
                                    </li>
                                    <li>
                                        {countrylist.map((item, index)=>{
                                            return (
                                                <span className='me-3 d-inline-flex text-them' key={index} style={{cursor:"pointer"}} onClick={()=>onItemSelect(item)}>{item.value}</span>
                                            )
                                        })}
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="widget widget_services ">
                                        <h3 className="widget-title">{t('About us')}</h3>
                                        <ul>
                                            <li>
                                                <Link to="/how-it-works">{t('How it works')}</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/affiliate">
                                                    Affiliate Partner Programme
                                                </Link>
                                            </li> */}
                                            {/* <li>
                                                <Link to="/careers">
                                                    Careers with auto-vignette
                                                </Link>
                                            </li> */}
                                            <li>
                                                <Link to="/flex_service">{t('Flex service')}</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/automatic_renewal">Automatic renewal</Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="widget widget_services ">
                                        <h3 className="widget-title">{t('Service')}</h3>
                                        <ul>
                                            <li>
                                                <Link to="/faqs">{t('FAQs')}</Link>
                                            </li>
                                            <li>
                                                <Link to="/help_support">{t('Help & contact')}</Link>
                                            </li>
                                            <li>
                                                <Link to="/dashboard/orders">{t('My customer account')}</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/search_order">Search for an order</Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom-info">
                        <div className="footer-copy-right">
                            <span className="copyrights-text">
                                <Link className='text-light me-3' to="/terms-and-conditions">{t('Terms and conditions / Right to cancellation')}</Link>
                                <Link className='text-light me-3' to="/info-consumers">{t('Info for consumers')}</Link>
                                <Link className='text-light me-3' to="/privacy-notice">{t('Privacy notice')}</Link>
                                {/* <Link className='text-light me-3' to="/cookie-settings">{t('Cookie settings')}</Link> */}
                                <Link className='text-light me-3' to="/company-info">{t('Company info')}</Link>
                                <Link className='text-light'>{t('Version')} : 0.53</Link>
                                </span>
                        </div>
                        <ul className="social-icons">
                            <li>
                                <a className="fab fa-facebook-f"></a>
                            </li>
                            <li>
                                <a className="fab fa-twitter"></a>
                            </li>
                            <li>
                                <a className="fab fa-instagram"></a>
                            </li>
                            <li>
                                <a className="fab fa-youtube"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
