import React from 'react';
import { Link } from 'react-router-dom';

export default function AutomaticRenewal() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Automatic renewal</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">Home</Link></li>
                                <li>Automatic renewal</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full p-t90 p-b90 site-bg-white">
                <div className="">
                    <div className="section-content">
                        <div className="twm-blog-post-h5-wrap container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer mb-5">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    You can sit back and relax - with our service, your e-vignette is renewed year after year.
                                                </h4>
                                                <p>
                                                    With automatic renewal of your annual vignette, you will never miss the expiry date again. Your annual vignette will be extended automatically for another year in good time before it expires.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{backgroundImage: 'url(assets/images/ftr-bg.jpg)'}}>
                            <div className="container p-5">
                                <h4 className="twm-s-title">The advantages to you of automatic renewal</h4>
                                <ul className="description-list-2">
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        You will never forget another renewal and you will avoid expensive toll penalties.
                                    </li>
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        Don’t want to renew it? You can cancel in good time - without any risk to you!
                                    </li>
                                    <li>
                                        <i className="feather-check"></i>
                                        You automatically have a valid vignette every year.
                                    </li>
                                </ul>
                                <div className="twm-job-self-bottom">
                                    <a className="site-button" data-bs-toggle="modal" href="#apply_job_popup" role="button">
                                        Activate automatic renewal
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <h4 className="twm-s-title">FAQ about automatic renewal</h4>
                            <ul className="description-list-2">
                                <li>
                                    <i className="feather-help-circle"></i>
                                    What are the benefits of automatic renewal?
                                </li>
                                <li>
                                    <i className="feather-help-circle"></i>
                                    What is the deadline for cancelling automatic renewal?
                                </li>
                                <li>
                                    <i className="feather-help-circle"></i>
                                    Can I activate automatic renewal at a later date?
                                </li>
                                <li>
                                    <i className="feather-help-circle"></i>
                                    Is there an extra charge for the automatic renewal service?
                                </li>
                            </ul>
                            <div className="twm-job-self-bottom">
                                <a className="site-button" data-bs-toggle="modal" href="#apply_job_popup" role="button">
                                    Activate automatic renewal
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};