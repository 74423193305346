import { HOME_DATA, SWIPER_DATA} from "../Action/Type"


const initialvalues={
    homedata:[],
    swiperdata:""
   
    
}


export const homereducer=(state=initialvalues, action)=>{
    
    switch(action.type){
        case HOME_DATA: 
        return{
            ...state, homedata:[...state.homedata, action.payload]
        }
       
        
    
    default: return state
    }
}