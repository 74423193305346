import React from 'react';

const AppContext = React.createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  loggedUser: {},
  setLoggedUser: () => {},
  defaultCurrency: {},
  setDefaultCurrency: () => {},
  exchangeRates: {},
  setExchangeRates: () => {},
  orderSaveAsDraft: {},
  setOrderSaveAsDraft: () => {},
});

export default AppContext;
