import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from '../appContext';
import { useTranslation } from 'react-i18next';

const Thank = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isGuestUser, setIsGuestUser] = useState(false);
    let { homedata } = useState(null);
    const { isLoggedIn } = useContext(AppContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderNumber = queryParams.get('ordersId');
    const mystyle = {
        fontSize: "90px"
    };
    const handlebackhome = () => {
        if (isLoggedIn) {
            navigate("/dashboard/orders");
        } else {
            const queryString = new URLSearchParams({ params: 'orders' }).toString();
            navigate(`/login?${queryString}`);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('saveAsDraft')) {
            homedata = (JSON.parse(localStorage.getItem('saveAsDraft')));
            if (homedata.isGuestLogin) {
                console.log("Yes its a guest user");
                setIsGuestUser(true);
            }
            else {
                console.log("No its a regular user");
                setIsGuestUser(false);
            }
        }
       setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
       }, 10);
    }, [])
    return (
        <>
            <div className="section-full p-t120 p-b120 twm-for-employee-area site-bg-white">
                <div className="container">

                    <div className="section-content thankyou_page">
                        <div className="row align-items-center">

                            {/* <div className="col-lg-5 col-md-12 d-none d-md-block">
                                <div className="twm-explore-media-wrap">
                                    <div className="twm-media me-0">
                                        <img src="assets/images/thankyou.jpg" alt="" />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-lg-12 col-md-24">
                                <div className="twm-explore-content-outer-3">

                                    <div className="twm-explore-content-3">
                                        <div className="twm-explore-media-wrap d-block d-md-none">
                                            <div className="twm-media">
                                                <img src="assets/images/thankyou.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="twm-title-small">{t('Order number')} :- {orderNumber}</div>
                                        <div className="twm-title-large">
                                            <h2 style={mystyle} className="mb-2 d-none d-md-block">{t('Thank You')}.</h2>
                                            <p className='fs-4'>{t('Your Order was completed successfully')}</p>
                                            <p className='fs-7'>{t('We have sent an email to the provided email address with the details of your order. Please check your inbox, including the spam or junk folder, for the confirmation email')}</p>
                                        </div>
                                        <div className="twm-upload-file">
                                            {!isGuestUser &&
                                            <button type="button" className="site-button" onClick={handlebackhome}>{t("Go To My Order")}</button>
                                            }
                                        </div>

                                    </div>

                                    <div className="twm-l-line-1"></div>
                                    <div className="twm-l-line-2"></div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Thank;

