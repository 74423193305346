import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './mypopup.css'; 

function PopupDialog({ message, onClose }) {
    const [isOpen, setIsOpen] = useState(true); // Assume initial visibility from props
  
    const handleClose = () => {
      setIsOpen(false);
      onClose(); // Call the passed onClose function
    };
  
    return (
      <Popup open={isOpen} onClose={handleClose}>
        <div className="mypopup">
              <br/>
              <h5>{message}</h5>
              <br/>
              <button className="mypopup-close" onClick={onClose}>
                Close
              </button>
        </div>
      </Popup>
    );
  }
    
  export default PopupDialog;
  