import React, { useState, useEffect, useRef, useContext } from 'react';
import { readRecord, readCDValuesByCode, createRecord, updateRecord, deleteRecord } from "../../Redux/Action/Action";
import { successToast, errorToast } from '../../components/toastify'
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import AppContext from '../../appContext';
import Swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const licensePlateData = require('../../assets/files/licensePlate.json');

export default function AddVehicle() {
   const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";
   const licensePlatePattern = /^(?=.*[A-Za-z\d])[A-Za-z\d—-]{1,15}$/;
   const { loggedUser } = useContext(AppContext);
   const { t } = useTranslation();

   const [myAllVehicle, setMyAllVehicle] = useState([]);
   const [vehicleCat, setVehicleCat] = useState([]);
   const [vehicleDetail, setVehicleDetail] = useState({});
   const [noOfRecords, setNoOfRecords] = useState(0);
   const [isLoading, setIsLoading] = useState(true);
   const [isAdd, setIsAdd] = useState(true);
   const [isSubmit, setSubmit] = useState(false);
   const [show, setShow] = useState(false);

   // add/edit
   const colourStyles = {
      control: styles => ({ ...styles, border: '1px solid #97a5ba' })
   }
   const [license_plate, setLicense_plate] = useState({ license_plate: "", clicense_plate: "" });
   const [registrationCountry, setRegistrationCountry] = useState(null);
   const [vehicleType, setVehicleType] = useState(null);
   const [licensePlateerror, setLicensePlateerror] = useState(false);

   // Pagination
   const [itemsPerPage] = useState(10);
   const [currentItems, setCurrentItems] = useState([]);

   let loginUser = {};
   if (localStorage.getItem('vignetteLoginUser')) {
      loginUser = JSON.parse(localStorage.getItem('vignetteLoginUser'))
   }

   useEffect(() => {
      for (let i in licensePlateData) {
         licensePlateData[i].value = licensePlateData[i].id;
      }
      readCDValuesByCode({ code: ['GLOBALVEHICLETYP'] }).then(async (res) => {
         for (let i in res.data['GLOBALVEHICLETYP']) {
            res.data['GLOBALVEHICLETYP'][i].label = res.data['GLOBALVEHICLETYP'][i].value;
         }
         setVehicleCat(res.data['GLOBALVEHICLETYP']);
      })
      getMyVehicles();
   }, []);

   const getMyVehicles = () => {
      let Body = {
         "table": "customer_vehicles",
         "selectFields": {},
         "whereCondition": {
            customerId: loggedUser['_id'],
            deleted: null,
         },
         "sortBy": {
            "createdAt": "-1"
         },
         "limit": "0",
         "populate": [{ path: "vehicle_type", select: "value code" }],
         "page": "1"
      }
      readRecord(Body).then(async (res) => {
         for (let i in res.data) {
            if (res.data[i].vehicle_country_of_registration) {
               res.data[i].vehicle_country_of_registration = JSON.parse(res.data[i].vehicle_country_of_registration);
            }
            if (res.data[i].vehicle_type) {
               res.data[i].vehicle_type.label = res.data[i].vehicle_type.value;
            }
         }
         setMyAllVehicle(res.data);
         setNoOfRecords(res.noOfRecords);
         const lastIndex = 1 * itemsPerPage;
         const firstIndex = lastIndex - itemsPerPage;
         const currentItems = res.data.slice(firstIndex, lastIndex);

         setCurrentItems(currentItems);
         setIsLoading(false)
      })
   }

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const handlePageClick = (event) => {
      const newOffset = ((event.selected + 1) * itemsPerPage);
      const lastIndex = newOffset === 0 ? 1 : newOffset;
      const firstIndex = lastIndex - itemsPerPage;
      const currentItems = myAllVehicle.slice(firstIndex, lastIndex);
      setCurrentItems(currentItems);
   };

   const changeLicense = (e) => {
      const { name, value } = e.target;
      setLicense_plate(license_plate => ({ ...license_plate, [name]: value.toUpperCase() }));

      if (licensePlatePattern.test(value.toUpperCase())) {
         setLicensePlateerror(false)
      } else {
         setLicensePlateerror(true)
      }
   }

   const editOrderView = (item) => {
      if (!item) { // add
         setRegistrationCountry(null);
         setVehicleType(null);
         setLicense_plate(license_plate => ({ ...license_plate, license_plate: "", clicense_plate: "" }));
         setIsAdd(true);
      } else { // edit
         setIsAdd(false);
         setRegistrationCountry(item.vehicle_country_of_registration);
         setVehicleType(item.vehicle_type);
         setLicense_plate(license_plate => ({ ...license_plate, license_plate: item.vehicle_number, clicense_plate: item.vehicle_number }));
         // const data = {
         //    license_plate: item.license_plate,
         // }
         setVehicleDetail(item);
      }
      handleShow();
   }

   const vehicleAddEditSubmit = (e) => {
      setSubmit(true);
      e.preventDefault();
      if (!license_plate.clicense_plate || !license_plate.license_plate) {
         return;
      }
      if (license_plate.clicense_plate !== license_plate.license_plate) {
         return;
      }
      if (licensePlateerror) {
         setLicensePlateerror(true)
         return;
      }
      if (isAdd) {
         // add
         setIsLoading(true);
         console.log("vehicleType >>>>>", vehicleType);
         const Body = {
            "record": {
               vehicle_country_of_registration: JSON.stringify(registrationCountry),
               vehicle_type: vehicleType._id,
               country: registrationCountry.label,
               vehicle_number: license_plate.license_plate.trim(),
               customerId: loggedUser['_id'],
               create_user: loggedUser['_id'],
               status: "ACTV",
               deleted: null,
               deletedby: null,
            },
            "table": "customer_vehicles",
            "uniqueFieldsContionsOperator": "and",
            "uniqueFields": {
               "vehicle_number": license_plate.license_plate.trim(),
               "country": registrationCountry.label,
               "customerId": loggedUser['_id'],
               "deleted": null,
            },
            "uniqueFieldErrMsg": "Vehicle number alrady exist."
         }

         // return;
         createRecord(Body).then(async (res) => {
            setIsLoading(false);
            if (res.status) {
               successToast("Vehicle added successfully");
               setRegistrationCountry();
               getMyVehicles();
               setSubmit(false);
               handleClose();
            } else {
               errorToast(res.message);
            }
         }, err => { setIsLoading(false) })
      } else {
         // edit
         setIsLoading(true);
         setSubmit(true);
         const Body2 = {
            "record": {
               vehicle_country_of_registration: JSON.stringify(registrationCountry),
               vehicle_type: vehicleType._id,
               vehicle_number: license_plate.license_plate.trim(),
               country: registrationCountry.label,
            },
            "table": "customer_vehicles",
            "_id": vehicleDetail._id,
            "uniqueFieldsContionsOperator": "and",
            "uniqueFields": {
               vehicle_number: license_plate.license_plate.trim(),
               deleted: null,
               // "country": registrationCountry.label,
            },
            "uniqueFieldErrMsg": "Vehicle number alrady exist."
         }
         updateRecord(Body2).then(async (upRes) => {
            if (upRes.status) {
               successToast("Vehicle updated successfully");
               getMyVehicles();
               setSubmit(false);
               handleClose();
            } else {
               errorToast(upRes.message);
            }
            setIsLoading(false)
         });
      }
   }

   const deleteAccount = (item) => {
      Swal.fire({
         title: 'Are you sure?',
         text: "You want delete this vehicle!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
         if (result.isConfirmed) {
            setIsLoading(true);
            deleteRecord({
               "record": {
                  "deleted": new Date().getTime(),
                  "deletedby": loggedUser['_id']
               },
               "table": "customer_vehicles",
               "scanTables": {},
               "_id": item._id,
               "deleterecord": "0"
            }).then(async (res) => {
               // setMsd(res.message);
               if (res.status) {
                  successToast("Vehicle updated successfully");
                  getMyVehicles();
               } else {
                  errorToast(res.message);
               }
               setIsLoading(false)
            })
         }
      })
   };


   return (
      <>
         <div className="twm-D_table table-responsive panel panel-default p-0">
            <div className="text-end pt-3 pe-3">
               <button type="submit" className="site-button py-2 px-4" onClick={(e) => editOrderView("")}>
                  <i className="fa-solid fa-add" style={{ fontSize: '16px' }}></i> {t("Add")}
               </button>
            </div>
            <table id="jobs_bookmark_table" className="table twm-candidate-save-job-list-wrap">
               <thead>
                  <tr>
                     <th>{t('Country of Registration')}</th>
                     <th>{t('Vehicle type')}</th>
                     <th>{t('Vehicle number')}</th>
                     <th>{t('Action')}</th>
                  </tr>
               </thead>
               <tbody>
                  {currentItems && currentItems.map((item, index) => (
                     <tr key={index}>
                        <td>
                           <div>{item?.vehicle_country_of_registration?.label}</div>
                        </td>
                        <td>
                           <div>{item?.vehicle_type?.value}</div>
                        </td>
                        <td>
                           <div>{item.vehicle_number}</div>
                        </td>
                        <td>
                           <div className="twm-table-controls d-block">
                              <ul className="twm-DT-controls-icon list-unstyled">
                                 <li>
                                    <button className="custom-toltip p-3" onClick={(e) => editOrderView(item)}>
                                       <i className="fa-solid fa-pencil" style={{ fontSize: '16px' }}></i>
                                       <span className="custom-toltip-block">{t('Edit Vehicle')}</span>
                                    </button>
                                 </li>
                                 <li>
                                    <button className="custom-toltip p-3" onClick={(e) => deleteAccount(item)}>
                                       <i className="fa-solid fa-trash" style={{ fontSize: '16px' }}></i>
                                       <span className="custom-toltip-block">{t('Delete Vehicle')}</span>
                                    </button>
                                 </li>
                              </ul>
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
            {!currentItems.length &&
               <div className="twm-job-title text-center my-4">
                  <h4>{t('No')}</h4>
               </div>
            }
            {/* BEGIN: Pagination */}
            <div className="react-paginate d-flex justify-content-center mt-2">
               <ReactPaginate
                  breakLabel="..."
                  nextLabel={(<i className="fa-solid fa-chevron-right" style={{ padding: "5px 0px" }}></i>)}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={(noOfRecords / itemsPerPage)}
                  previousLabel={(<i className="fa-solid fa-chevron-left" style={{ padding: "5px 0px" }}></i>)}
                  renderOnZeroPageCount={null}
               />

            </div>
            {/* END: Pagination */}

         </div>

         <Modal className="modal fade twm-sign-up" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>{t(isAdd ? "Add" : "Edit")}{t('Vehicle')}</Modal.Title>
            </Modal.Header>
            <form onSubmit={vehicleAddEditSubmit}>

               <div className="modal-body">
                  <div className="twm-tabs-style-2">
                     {/* Signup Candidate Content */}
                     <div className="row">
                        {/* <h4>Select your Vehicle Country of registration <span className="required">*</span></h4>
                        <small>Country where your Vehicle is registered</small> */}
                        <div className="col-md-6 mb-3">
                           <label className="form-label">{t('Country of registration')}<span className="required">*</span> </label>
                           <Select className="select-border"
                              // isDisabled={true}
                              options={licensePlateData} // set list of the data
                              value={registrationCountry}
                              onChange={(e) => setRegistrationCountry(e)}
                              styles={colourStyles}
                              placeholder="Select Country"
                           />
                           {(isSubmit && !registrationCountry) ? <p className="text-danger">Country of registration is mandatory</p> : ''}
                        </div>

                        <div className="col-md-6 mb-3">
                           <label className="form-label">{t('Vehicle type')}<span className="required">*</span> </label>
                           <Select className="select-border"
                              // isDisabled={true}
                              options={vehicleCat} // set list of the data
                              value={vehicleType}
                              onChange={(e) => setVehicleType(e)}
                              styles={colourStyles}
                              placeholder="Select Vehicle type"
                           />
                           {(isSubmit && !vehicleType) ? <p className="text-danger">Vehicle type is mandatory</p> : ''}
                        </div>

                        <div className="col-lg-12">
                           <div className="form-group mb-3">
                              <label className="form-label">{t('Enter license plate')}<span className="required">*</span> </label>
                              <div className="ls-inputicon-box">

                                 <input type="text" name="license_plate" value={license_plate.license_plate} onChange={changeLicense} className="form-control ps-5" placeholder={registrationCountry?.placeholder} />
                                 {registrationCountry?.flag?.img &&
                                    <div className="license-plate-flag" style={{ backgroundColor: registrationCountry.flag.color }}>
                                       <img src={imageURL + registrationCountry.flag.img} style={{ maxWidth: '22px' }} className="" />
                                       <span className="country-name">{registrationCountry.code}</span>
                                    </div>
                                 }
                              </div>
                              {(isSubmit && !license_plate.license_plate) ? <p className="text-danger">License plate is mandatory</p> : ''}
                           </div>
                        </div>
                        <div className="col-lg-12">
                           <div className="form-group">
                              <label className="form-label">{t('Confirm license plate')} <span className="required">*</span> </label>
                              <div className="ls-inputicon-box">
                                 {registrationCountry?.flag?.img &&
                                    <div className="license-plate-flag" style={{ backgroundColor: registrationCountry.flag.color }}>
                                       <img src={imageURL + registrationCountry.flag.img} style={{ maxWidth: '22px' }} className="" />
                                       <span className="country-name">{registrationCountry.code}</span>
                                    </div>
                                 }
                              </div>
                              <input type="text" name="clicense_plate" value={license_plate.clicense_plate} onChange={changeLicense} className="form-control ps-5" placeholder={registrationCountry?.placeholder} />
                              {(isSubmit && !license_plate.clicense_plate) ? <p className="mb-0 text-danger">Confirm license plate is mandatory</p> : ''}
                              {(((license_plate.clicense_plate !== license_plate.license_plate) && license_plate.clicense_plate)) ? <p className="text-danger mb-0">License and Confirm License not matching</p> : ''}
                              {(licensePlateerror) ? <p className="text-danger mt-1">The registration number does not correspond.</p> : ""}
                           </div>
                        </div>
                        <div className="col-md-12 text-center">
                           {(license_plate.license_plate && license_plate.clicense_plate && registrationCountry && vehicleType) ?
                              <button type="submit" data-bs-dismiss="modal" aria-label="Close" className="site-button w-auto" style={{ display: 'unset' }}>
                                 {t(isAdd ? "Save" : "Update")}
                              </button> :
                              <button type="button" disabled className="site-button w-auto btn-disabled" style={{ display: 'unset' }} >{isAdd ? "Save" : "Update"}</button>
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </Modal>

         {isLoading &&
            <div className="sub-loading">
               <div className="loader" >
                  <span className="text-large font-weight-bolder">
                     <img src="/assets/images/loader.svg" width={100} />
                  </span>
               </div>
            </div>
         }
      </>
   )
};