import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
function Auth(props) {
    const { Component } = props;
  const navigate = useNavigate();
  
  useEffect(() => {
    let login=localStorage.getItem('vignetteLoginUser');
    if(!login){
        navigate("/login")
    }
  }, []);

  return (
    <>
    <Component/>
    </>
  )
}

export default Auth;