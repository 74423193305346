import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PrivacyNotice() {
    const { t } = useTranslation();
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{t('Privacy Notice')}</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">{t('Home')}</Link></li>
                                <li>{t('Privacy Notice')}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full p-t90 p-b90 site-bg-white">
                <div className="">
                    <div className="section-content">
                        <div className="twm-blog-post-h5-wrap container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer mb-5">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t('General remarks')}
                                                </h4>
                                                <p>
                                                    {t("When you visit our website www.auto-vignette.com, this privacy statement explains in detail what happens to your personal data. Any information that can be used to identify you personally is considered personal data. We take great care to ensure that your visit to our website is completely secure and rigorously abide by the law when processing your data, especially the Data Protection Regulation ('GDPR').")}
                                                </p>

                                            </div>
                                        </div>
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t("Accountable entity")}
                                                </h4>
                                                <p>{t("The following person is accountable under data protection law for the handling of personal data on this website:")}</p>
                                                <p>{t("ILTERLER Ltd")}</p>
                                                <p>{t("Represented by: Nurettin ilter, Managing Director Address: 50 Victory Road, Shirley, Southampton, SO15 8QZ, United Kingdom")}</p>
                                                <p>{t("Telephone: +44 7711481171 email: info@auto-vignette.com")}</p>
                                                <p>{t("Obtain information (server log files)")}</p>
                                                <p>{t("We automatically gather and preserve in so-called server log files access information that your browser sends to us whenever you access our website. Which are as stated below:")}</p>
                                                <p>{t("- Your computer's browser type and version.")}</p>
                                                <p>{t("- operating system that your computer runs on.")}</p>
                                                <p>{t("- Referrer URL (the source or link that directed you to our website).")}</p>
                                                <p>{t("- The IP address that your computer is currently using (if applicable, in anonymized form), together with the date and time the server requested it.")}</p>
                                                <p>{t("We cannot, and do not intend to, make personal references as a general matter. This data is processed in line with GDPR Article 6 Paragraph 1 which is used to safeguard our legitimate interest in enhancing the security and functioning of our website.")}</p>
                                            </div>
                                        </div>
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t("")}
                                                </h4>
                                                <p>{t("")}</p>
                                            </div>
                                        </div>
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t("Internet hosting and content delivery")}
                                                </h4>
                                                <h4>
                                                    {t("Google Cloud Platform")}
                                                </h4>
                                                <p>{t("Our website's security and delivery speed are improved by using the Amazon CloudFront content delivery network (CDN) from Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855 Luxembourg (AWS). This aligns with our legitimate interest as defined by GDPR Article 6 Paragraph 1 Lit. F. A global network of computers known as a CDN allows for the delivery of optimal material to website visitors. Personal information may be processed by AWS for this reason in server log files. Compare the details under 'Access data' if possible. Your personal information is transferred to AWS, which also serves as our processor. This is consistent with our legitimate interest, as defined by GDPR Art. 6 (1) sentence 1 lit. f, to refrain from running our own content delivery network.")}</p>
                                                <p>{t("The process may be objected to by you. The outcome of the objection will be decided in the context of an interest balance.")}</p>
                                                <p>{t("There is no legal or contractual requirement for the processing of the data given under this section. Without processing, the website's operation cannot be assured.")}</p>
                                                <p>{t("AWS will keep your personal information for however long is required to fulfil the outlined objectives.")}</p>
                                                <p>{t("Visit https://aws.amazon.com/de/compliance/gdpr-center/ for further details on how to object to and remove your data from AWS.")}</p>
                                                <p>{t("AWS has put in place compliance safeguards for cross-border data transfers. These apply to all international activities when AWS handles personal data of EU citizens. The EU Standard Contractual Clauses (SCCs), upon which these measures are based. Please visit https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf for further details.")}</p>
                                                <p>{t("The United States-based company Amazon Web Services Inc. has obtained 'Privacy Shield' certification, which ensures conformity with the EU's standards for data protection.")}</p>
                                            </div>
                                        </div>
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t("Cookies")}
                                                </h4>
                                                <p>{t("We use 'cookies' to enhance user experience on our website and make it possible to use specific features. On your terminal device, these are little text files that are kept. Cookies are unable to run programmes or spread viruses on your computer.")}</p>
                                                <p>{t("Cookies are stored in accordance with GDPR Article 6(1)(f) because they are necessary for electronic communication or to provide certain activities you have requested. For the technically flawless and optimal delivery of our services, we have a legitimate interest in storing cookies. Other cookies, such as those used to assess your browsing habits, are handled individually in this data protection statement insofar as they are stored.")}</p>
                                                <p>{t("We use a large majority of so-called 'session cookies' in our cookies. When you leave, they are automatically deleted. Until you delete them, other cookies stay on your terminal device. We can recognise your browser on your subsequent visit thanks to these cookies.")}</p>
                                                <p>{t("You can configure your browser so that it notifies you when cookies are being placed, that you only permit cookies in specific circumstances, that you do not accept cookies generally or in specific circumstances, and that it deletes cookies automatically when the browser is closed. The operation of this website might be limited if you disable cookies.")}</p>
                                                <p>{t("Web analysis tools and advertising")}</p>
                                                <p>{t("Google Analytics")}</p>
                                                <p>{t("Google Analytics is a web analytics tool we utilise on our website. Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland ('Google') is the service provider.")}</p>
                                                <p>{t("So-called 'cookies' are used by Google Analytics. These text files, which are kept on your computer, allow us to examine how you use our website. Typically, a Google server in the USA receives and stores the data produced by cookies on how you use our website.")}</p>
                                                <p>{t("The consent you provided via the cookie consent tool in accordance with Art. 6 para. 1 sentence 1 lit. a) GDPR serves as the legal foundation for the processing of your data.")}</p>
                                                <p>{t("Anonymization of IP")}</p>
                                                <p>{t("On this website, we've turned on the IP anonymization feature. This implies that before Google transmits your IP address to the USA, it first shortens it within member states of the European Union or in other parties to the Agreement on the European Economic Area. The complete IP address will only ever be sent to a Google server in the USA in rare circumstances, when it will be truncated. In order to assess your use of our website, create reports on website activity, and provide other services to us regarding website activity and internet usage, Google will use your information on our behalf. Your browser's IP address that is sent to Google as part of Google Analytics will not be combined with other Google information.")}</p>
                                                <p>{t("Job processing")}</p>
                                                <p>{t("When utilising Google Analytics, we completely adhere to the stringent guidelines set forth by the UK data protection authorities because we have an order data processing agreement with Google.")}</p>
                                                <p>{t("Storage time")}</p>
                                                <p>{t("After 14 months, Google anonymizes or deletes data associated with cookies, user identifiers (such as user IDs) or advertising IDs (such as DoubleClick cookies or Android advertising IDs) that are stored at the user and event level. Information is available at the following link: https://support.google.com/analytics/answer/7667196?hl=en")}</p>
                                                <p>{t("Reaction against collecting data")}</p>
                                                <p>{t("By choosing the proper options in your browser, you can choose to reject the usage of cookies, but keep in mind that you might not be able to access this website's full functionality if you do so.")}</p>
                                                <p>{t("By downloading and installing the browser extension provided under the following link to deactivate Google Analytics, you can also prevent the collection of data created by cookies and related to your use of the website (including your IP address) by Google and the processing of this data by Google. You will need to set the opt-out cookie once more if you delete the cookies from your machine.")}</p>
                                                <p>{t("Visit https://support.google.com/analytics/answer/6004245?hl=en to view Google's privacy statement for additional details on how Google Analytics manages user data.")}</p>
                                                <p>{t("Google's privacy statement is available at this link:")}</p>
                                                <p>{t("https://policies.google.com/privacy?hl=en&gl=en for more details on data protection.")}</p>
                                                <p>{t("Contact form")}</p>
                                                <p>{t("Your contact information will be retained if you contact us by email or using a contact form so that we can process your inquiry or be prepared for any subsequent enquiries. No one else will receive this information without your permission.")}</p>
                                                <p>{t("Your consent is the only legal basis for processing the data you give in the contact form (Article 6 para. 1 lit. a GDPR). Your permission may be withdrawn at any time. For the revocation, a casual email exchange is sufficient. The revocation has no effect on the legality of the data processing actions that were done up until it was issued.")}</p>
                                                <p>{t("Data given to us via the contact form will be kept on file until you ask us to remove it, you decide not to allow us to keep it, or we no longer need the data. Retention periods and other mandatory legal provisions are unchanged.")}</p>
                                                <p>{t("Customer account")}</p>
                                                <p>{t("By creating a customer account, you consent to the storage of your usage data (username, password), inventory data (name, address, and email address), and inventory data (inventory data). This makes it possible for you to place orders with us using your email address and unique password.")}</p>
                                                <p>{t("Register for our email newsletter.")}</p>
                                                <p>{t("If you sign up for our email newsletter, we'll give you updates on our news and deals on a regular basis. Your email address is the only thing needed to send the newsletter. Any additional information that is provided voluntarily will be utilised to address you specifically. We transmit the newsletter using the so-called double opt-in method. Once you have confirmed your desire to receive future emails, we will send you a confirmation email with a link. You give us your consent to use your personal data in accordance with GDPR Article 6 Paragraph 1 Lit. a by clicking the confirmation link. In order to track your permission and potential abuse of your email address down the road, we save your IP address entered by your internet service provider (ISP) along with the date and time of registration when you sign up for the newsletter. You can unsubscribe from the newsletter at any time by sending a corresponding message to the person designated as being responsible at the beginning of the newsletter or by using the link provided for this purpose in the newsletter. Unless you have specifically agreed to further use of your data or we reserve the right to use your data in a manner that goes beyond this and is permitted by law and about which we inform you in this declaration, your email address will be immediately removed from our newsletter distribution list after you unsubscribe.")}</p>
                                                <p>{t("Online transactions")}</p>
                                                <p>{t("Your personal information must be provided in order to process your order when you order goods or services from our online store in order for the contract to be fulfilled. The mandatory information needed to process the contract is identified separately. The information necessary for processing payments will be sent to the appropriate payment service providers, depending on the payment method chosen. Your data processing is done in accordance with GDPR Article 6 Paragraph 1 First Lit. B.")}</p>
                                                <p>{t("Google Pay")}</p>
                                                <p>{t("On our website, Google Pay, a supplier of online payments, is used. The service is being offered by Google Inc., an American business. Google Ireland Ltd, Gordon House, Barrow Street Dublin 4, Ireland, is in charge of all Google services for the continent of Europe.")}</p>
                                                <p>{t("Google Pay primarily handles the data processing. As a result, data may not be processed or kept anonymously. Additionally, US government officials might have access to personal information. This information might also be connected to information from other Google services if you have an account.")}</p>
                                                <p>{t("More information regarding the data handled by Google Pay can be found in the privacy statement at https://policies.google.com/privacy.")}</p>
                                                <p>{t("Card payments")}</p>
                                                <p>{t("On our website, we accept majority of card payments through utilizing the STRIPE(STRUKL) payment gateway made Stripe Payments UK, Ltd. which has its principal place of business at 7th Floor, The Bower Warehouse, 211 Old Street, London EC1V 9NR, United Kingdom")}</p>
                                                <p>{t("Read the Stripe Privacy Policy at https://stripe.com/gb/privacy")}</p>
                                                <p>{t("PayPal")}</p>
                                                <p>{t("On our website, we accept payments using PayPal. The service is being offered by PayPal Inc., an American business. The organisation in charge of the European region is PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg.")}</p>
                                                <p>{t("PayPal primarily handles the data processing. As a result, data may not be processed or kept anonymously. Additionally, the US government may have access to personal information. Additionally, it is possible that this data will be connected to information from other PayPal services where you have an account.")}</p>
                                                <p>{t("Learn more about how PayPal handles data processing in its Privacy Policy at https://www.paypal.com/de/webapps/mpp/ua/privacy-full.")}</p>
                                                <p>{t("TLS encryption or SSL")}</p>
                                                <p>{t("For security purposes and to safeguard the transfer of confidential content, such as orders or inquiries that you make to us as the site operator, our website uses SSL or TLS encryption. An encrypted connection can be identified by the lock symbol in your browser line and by the address line of the browser changing from 'http://' to 'https://'.")}</p>
                                                <p>{t("The data you provide to us cannot be viewed by outside parties if SSL or TLS encryption is enabled.")}</p>
                                                <p>{t("Storage period")}</p>
                                                <p>{t("When someone sends us personal information through our website, we only keep it around until the goal for which it was given to us has been achieved. The storage term for some data may be up to 10 years insofar as retention obligations under commercial and tax law must be respected.")}</p>
                                                <p>{t("Rights of data subjects")}</p>
                                                <p>{t("As a data subject, you have the following legal rights against the data controller with regard to the personal data that relates to you:")}</p>
                                                <p>{t("Right of withdrawal")}</p>
                                                <p>{t("Many data processing processes require your express consent in order to be completed. According to Art. 7(3) GDPR, you have the right to revoke your permission to the processing of your data at any time with future effects if it is based on your consent. The lawfulness of the processing done on the basis of the consent up until the revocation is unaffected by the consent's revocation. A revocation has no impact on data storage used for billing and accounting.")}</p>
                                                <p>{t("Right to information")}</p>
                                                <p>{t("According to Article 15 of the GDPR, you have the right to ask us to confirm if we are processing any of your personal data. If such processing is taking place, you have the right to learn about it, including the categories of personal data processed, the recipients or categories of recipients to whom your data have been or will be disclosed, the intended storage period or criteria for determining the storage period, the existence of a right of rectification, erasure, or access to your personal data, and the reasons for the processing. the criteria used to determine the storage period, the existence of a right to rectification, erasure, restriction of processing, objection to processing, complaint to a supervisory authority, the source of your data if it wasn't obtained directly from you, the use of automated decision-making, including profiling, and, if applicable, meaningful information about the logic involved and the scope and intended effects of such processing concerning you.")}</p>
                                                <p>{t("Right of rectification")}</p>
                                                <p>{t("You have the right to request the prompt rectification of any of your personal data that is erroneous at any time, in line with Art. 16 of the GDPR, as well as the completion of any data that is incomplete.")}</p>
                                                <p>{t("Right to erasure")}</p>
                                                <p>{t("If one of the following circumstances applies, you have the right to request the deletion of your personal data in line with Art. 17 GDPR:")}</p>
                                                <p>{t("Your personal information is no longer required for the activities for which it was gathered or otherwise processed;")}</p>
                                                <p>{t("If there is no other legal basis for the processing, you revoke the permission that was the basis for it in accordance with GDPR Articles 6(1)a or 9(2)a;")}</p>
                                                <p>{t("You object to the processing in accordance with GDPR Article 21(1) and there are no compelling legal justifications for the processing, or you object to the processing in accordance with GDPR Article 21(2);")}</p>
                                                <p>{t("The processing of the personal data was unlawful;")}</p>
                                                <p>{t("A legal duty under Union legislation or the law of the Member State to which we are subject requires the erasure of personal data;")}</p>
                                                <p>{t("Personal information was gathered in connection with services provided by the information society in accordance with GDPR Article 8(1);")}</p>
                                                <p>{t("This right, however, does not apply if the processing is required:")}</p>
                                                <p>{t("to exercise our right to freedom of speech and information; to fulfil a legal obligation that requires processing under Union or Member State law; to carry out a task in the public interest; or to use official authority that has been delegated to us;")}</p>
                                                <p>{t("archiving in the public interest, scientific or historical research purposes, or statistical purposes in accordance with Article 89(1) of the GDPR, insofar as the right of the data subject is likely to make it impossible or seriously impair the achievement of the purposes of such processing, or for the establishment, exercise, or defence of legal claims are all examples of reasons for processing that fall under the purview of the public interest in the area of public health.")}</p>
                                                <p>{t("If we have shared your personal information and are required to delete it in accordance with the aforementioned, we will take reasonable steps, including technical measures, to notify the data controllers handling the personal information that you, as the data subject, have asked them to delete all links to, copies of, or replications of, your personal information. We will do this while taking into account the state of the technology and the implementation cost.")}</p>
                                                <p>{t("Right to restrict processing")}</p>
                                                <p>{t("According to Article 18 of the GDPR, you have the right to request that your personal data be processed (or blocked). You can get in touch with us about this whenever you like at the address provided in the imprint. The following circumstances give rise to the entitlement to processing restriction:")}</p>
                                                <p>{t("We typically need time to investigate any claims regarding the correctness of your personal data that we have saved. You have the right to request a restriction on the processing of your personal data during the verification procedure. Instead than asking for your personal data to be erased if it has been or is being processed improperly, you can request its restriction.")}</p>
                                                <p>{t("Instead of asking for your personal data to be deleted if we no longer need it but you still need it to exercise, defend, or enforce legal claims, you have the right to request that the processing of your personal data be restricted.")}</p>
                                                <p>{t("If you have raised an objection in accordance with Article 21 (1) of the GDPR, we must weigh your interests against your objection. You have the right to request that the processing of your personal data be restricted, pending the determination of whose interests predominate.")}</p>
                                                <p>{t("Your personal data may only be processed with your consent, for the establishment, exercise, or defence of legal claims, for the protection of the rights of another natural person or legal entity, or for reasons of important public interest of the European Union or a Member State, if you have restricted the processing of your personal data. Restricted processing also prevents those data from being shared with third parties.")}</p>
                                                <p>{t("Right to information")}</p>
                                                <p>{t("We are required to notify any recipients of your personal data to whom we have disclosed your right to rectification, erasure, or restriction of processing if you have made such a claim against us, unless doing so would be impractical or require disproportionate effort. You have the right to learn more about these recipients upon request in accordance with GDPR Article 19 of the Regulation.")}</p>
                                                <p>{t("Right to be shielded from judgements made exclusively by automated means, including profiling")}</p>
                                                <p>{t("In line with Article 22 of the GDPR, you have the right to be free from decisions that are purely based on automated processing, including profiling, and that have legal repercussions for you or otherwise significantly affect you.")}</p>
                                                <p>{t("In the event that the decision is required to enter into or carry out a contract between you and us, is permitted by Union or Member State law to which the controller is subject and which contains adequate safeguards for your rights, freedoms, and legitimate interests, or is carried out with your express consent.")}</p>
                                                <p>{t("However, under Article 9(1) of the GDPR, decisions in the situations mentioned in (a) through (c) may not be based on special categories of personal data unless Article 9(2)(a) or (g) applies and sufficient steps have been taken to protect your rights, freedoms, and legitimate interests.")}</p>
                                                <p>{t("We will take reasonable measures to protect your rights, freedoms, and legitimate interests in the situations mentioned in (a) and (c), including at the very least the freedom to request the involvement of a relevant party, to voice your opinion, and to challenge the decision.")}</p>
                                                <p>{t("Rights to data portability")}</p>
                                                <p>{t("In accordance with Art. 20 GDPR, you have the right to obtain your personal data that you have provided to us in a structured, common, and machine-readable format and to have it transferred to another controller, or to request that it be transferred to another controller, insof the processing is based on your consent in accordance with Art. 6 (1) lit. an or Art. 9 (2) lit. a GDPR, or on a contract in accordance with Art. 6 (1) lit. b GDPR.")}</p>
                                                <p>{t("Right of Objection")}</p>
                                                <p>{t("You have the right to object to the processing of your personal data at any time for reasons arising from your specific situation insofar as we base the processing of your personal data on the balance of interests in accordance with Art. 6 (1) lit. f GDPR; this also applies to profiling based on this provision. This data protection declaration contains the relevant legal justification for processing. In the event that you object, we will no longer process the personal data in question unless we can show compelling legitimate grounds for the processing that prevail over your interests, rights, and freedoms or the processing is necessary for the establishment, exercise, or defence of legal claims (objection under Article 21(1) of the GDPR).")}</p>
                                                <p>{t("You have the right to object at any time to the processing of any of your personal data for direct marketing purposes, including any profiling that may be done in connection with such direct marketing. This right exists if your personal data is processed for this purpose. Your personal information will no longer be used for direct marketing if you object (objection in accordance with Art. 21(2) GDPR).")}</p>
                                                <p>{t("Despite Directive 2002/58/EC, you have the option to employ automated processes based on technological specifications to exercise your right to object while utilising services provided by the information society.")}</p>
                                                <p>{t("Rights of appeal to the appropriate supervisory authority under Article 77 GDPR")}</p>
                                                <p>{t("In the event of GDPR violations, data subjects have the right to file an appeal with a supervisory body, particularly in the Member State of their place of usual residence, place of employment, or the alleged violation. Any alternative administrative or judicial remedy is not prejudiced by the ability to appeal.")}</p>
                                                <p>{t("Validity and modifications to this privacy statement")}</p>
                                                <p>{t("This privacy statement will take effect on December 6, 2022. In accordance with the relevant data protection laws, we reserve the right to change this privacy statement at any moment. This can be required, for instance, to abide by new legal requirements, account for changes to our website, or implement new services on our website.")}</p>
                                                <p>{t("If this privacy statement is changed, we'll update it here so you'll always be aware of what information we gather about you, how we use it, and under what circumstances we might release it.")}</p>
                                            </div>
                                        </div>
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t("")}
                                                </h4>
                                                <p>{t("")}</p>
                                            </div>
                                        </div>
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                    {t("")}
                                                </h4>
                                                <p>{t("")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};