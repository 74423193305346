import React from 'react';
import { Link } from 'react-router-dom';

export default function Careers() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content">

            {/* <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">The Most Exciting Vehicles</h2>
                            </div>
                        </div>
                            <div>
                                <ul className="wt-breadcrumb breadcrumb-style-2">
                                    <li><Link to="/home">Home</Link></li>
                                    <li>Vehicles List</li>
                                </ul>
                            </div>
                        
                    </div>
                </div>
            </div>


            <div className="section-full p-t50 p-b50 site-bg-white">
                

                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-4 col-md-12 rightSidebar">

                            <div className="side-bar">

                                <div className="sidebar-elements search-bx">
                                                                            
                                    <form>

                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Category</h4>
                                            <select className="wt-select-bar-large selectpicker"  data-live-search="true" data-bv-field="size">
                                                <option>All Category</option>
                                                <option>Web Designer</option>
                                                <option>Developer</option>
                                                <option>Acountant</option>
                                            </select>
                                        </div>

                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Keyword</h4>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Vehicle Title or Keyword" />
                                                <button className="btn" type="button"><i className="feather-search"></i></button>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4">
                                            <h4 className="section-head-small mb-4">Location</h4>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search location" />
                                                <button className="btn" type="button"><i className="feather-map-pin"></i></button>
                                            </div>
                                        </div>

                                        <div className="twm-sidebar-ele-filter">
                                            <h4 className="section-head-small mb-4">Vehicle Type</h4>
                                            <ul>
                                                <li>
                                                    <div className=" form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                        <label className="form-check-label" for="exampleCheck1">Freelance</label>
                                                    </div>
                                                    <span className="twm-job-type-count">09</span>
                                                </li>

                                                <li>
                                                    <div className=" form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck2" />
                                                        <label className="form-check-label" for="exampleCheck2">Full Time</label>
                                                    </div>
                                                    <span className="twm-job-type-count">07</span>
                                                </li>

                                                <li>
                                                    <div className=" form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck3" />
                                                        <label className="form-check-label" for="exampleCheck3">Internship</label>
                                                    </div>
                                                    <span className="twm-job-type-count">15</span>
                                                </li>

                                                <li>
                                                    <div className=" form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck4" />
                                                        <label className="form-check-label" for="exampleCheck4">Part Time</label>
                                                    </div>
                                                    <span className="twm-job-type-count">20</span>
                                                </li>

                                                <li>
                                                    <div className=" form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck5" />
                                                        <label className="form-check-label" for="exampleCheck5">Temporary</label>
                                                    </div>
                                                    <span className="twm-job-type-count">22</span>
                                                </li>

                                                <li>
                                                    <div className=" form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck6" />
                                                        <label className="form-check-label" for="exampleCheck6">Volunteer</label>
                                                    </div>
                                                    <span className="twm-job-type-count">25</span>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="twm-sidebar-ele-filter">
                                            <h4 className="section-head-small mb-4">Date Posts</h4>
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="exampleradio1" />
                                                        <label className="form-check-label" for="exampleradio1">Last hour</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="exampleradio2" />
                                                        <label className="form-check-label" for="exampleradio2">Last 24 hours</label>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="exampleradio3" />
                                                        <label className="form-check-label" for="exampleradio3">Last 7 days</label>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="exampleradio4" />
                                                        <label className="form-check-label" for="exampleradio4">Last 14 days</label>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="exampleradio5" />
                                                        <label className="form-check-label" for="exampleradio5">Last 30 days</label>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="exampleradio6" />
                                                        <label className="form-check-label" for="exampleradio6">All</label>
                                                    </div>
                                                </li>
                             
                                            </ul>
                                        </div>

                                        <div className="twm-sidebar-ele-filter">
                                            <h4 className="section-head-small mb-4">Type of employment</h4>
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="Freelance1" />
                                                        <label className="form-check-label" for="Freelance1">Freelance</label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="FullTime1" />
                                                        <label className="form-check-label" for="FullTime1">Full Time</label>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="Intership1" />
                                                        <label className="form-check-label" for="Intership1">Intership</label>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id="Part-Time1" />
                                                        <label className="form-check-label" for="Part-Time1">Part Time</label>
                                                    </div>
                                                </li>
                             
                                            </ul>
                                        </div>
                                        
                                    </form>
                                    
                                </div>

                                <div className="widget tw-sidebar-tags-wrap">
                                    <h4 className="section-head-small mb-4">Tags</h4>
                                    
                                    <div className="tagcloud">
                                        <a href="job-list.html">General</a>
                                        <a href="job-list.html">Vehicles </a>
                                        <a href="job-list.html">Payment</a>                                            
                                        <a href="job-list.html">Application </a>
                                        <a href="job-list.html">Work</a>
                                        <a href="job-list.html">Recruiting</a>
                                        <a href="job-list.html">Employer</a>
                                        <a href="job-list.html">Income</a>
                                        <a href="job-list.html">Tips</a>
                                    </div>
                                </div>
                            </div>

                            <div className="twm-advertisment" style={{ backgroundImage: 'url("assets/images/banner/add-bg.jpg")' }}>
                               <div className="overlay"></div>
                               <h4 className="twm-title">Recruiting?</h4>
                               <p>Get Best Matched Vehicles On your <br/>
                                Email. Add Resume NOW!</p>
                                <a href="about-1.html" className="site-button white">Read More</a> 
                            </div>

                        </div>

                        <div className="col-lg-8 col-md-12">
                            <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                                <span className="woocommerce-result-count-left">Showing 2,150 jobs</span>
                               
                                <form className="woocommerce-ordering twm-filter-select" method="get">
                                    <span className="woocommerce-result-count">Short By</span>
                                    <select className="wt-select-bar-2 selectpicker"  data-live-search="true" data-bv-field="size">
                                        <option>Most Recent</option>
                                        <option>Freelance</option>
                                        <option>Full Time</option>
                                        <option>Internship</option>
                                        <option>Part Time</option>
                                        <option>Temporary</option>
                                    </select>
                                    <select className="wt-select-bar-2 selectpicker"  data-live-search="true" data-bv-field="size">
                                        <option>Show 10</option>
                                        <option>Show 20</option>
                                        <option>Show 30</option>
                                        <option>Show 40</option>
                                        <option>Show 50</option>
                                        <option>Show 60</option>
                                    </select>
                                </form>

                            </div>

                            <div className="twm-jobs-list-wrap">
                                <ul>
                                     <li>
                                         <div className="twm-jobs-list-style1 mb-5">
                                             <div className="twm-media">
                                                 <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                                             </div>
                                             <div className="twm-mid-content">
                                                 <a href="job-detail.html" className="twm-job-title">
                                                     <h4>Senior Web Designer<span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                                 </a>
                                                 <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                 <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                             </div>
                                             <div className="twm-right-content">
                                                 <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                                                 <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                                 <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                             </div>
                                         </div>
                                     </li>
     
                                     <li>
                                         <div className="twm-jobs-list-style1 mb-5">
                                             <div className="twm-media">
                                                 <img src="assets/images/jobs-company/pic2.jpg" alt="#" />
                                             </div>
                                             <div className="twm-mid-content">
                                                 <a href="job-detail.html" className="twm-job-title">
                                                     <h4>Sr. Rolling Stock Technician<span className="twm-job-post-duration">/ 15 days ago</span></h4>
                                                 </a>
                                                 <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                 <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                             </div>
                                             <div className="twm-right-content">
                                                 <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                                                 <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                                 <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                             </div>
                                         </div>
                                     </li>
     
                                     <li>
                                         <div className="twm-jobs-list-style1 mb-5">
                                             <div className="twm-media">
                                                 <img src="assets/images/jobs-company/pic3.jpg" alt="#" />
                                             </div>
                                             <div className="twm-mid-content">
                                                 <a href="job-detail.html" className="twm-job-title">
                                                     <h4 className="twm-job-title">IT Department Manager<span className="twm-job-post-duration"> / 6 Month ago</span></h4>
                                                 </a>
                                                 <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                 <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                             </div>
                                             <div className="twm-right-content">
                                                 <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                                                 <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                                 <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                             </div>
                                         </div>
                                     </li>
     
                                     <li>
                                         <div className="twm-jobs-list-style1 mb-5">
                                             <div className="twm-media">
                                                 <img src="assets/images/jobs-company/pic4.jpg" alt="#" />
                                             </div>
                                             <div className="twm-mid-content">
                                                 <a href="job-detail.html" className="twm-job-title">
                                                     <h4 className="twm-job-title">Art Production Specialist   <span className="twm-job-post-duration">/ 2 days ago</span></h4>
                                                 </a>
                                                 <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                 <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                             </div>
                                             <div className="twm-right-content">
                                                 <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                                                 <div className="twm-jobs-amount">$1500 <span>/ Month</span></div>
                                                 <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                             </div>
                                         </div>
                                     </li>
     
                                     <li>
                                         <div className="twm-jobs-list-style1 mb-5">
                                             <div className="twm-media">
                                                 <img src="assets/images/jobs-company/pic5.jpg" alt="#" />
                                             </div>
                                             <div className="twm-mid-content">
                                                 <a href="job-detail.html" className="twm-job-title">
                                                     <h4 className="twm-job-title">Recreation &amp; Fitness Worker   <span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                                 </a>
                                                 <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                 <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                             </div>
                                             <div className="twm-right-content">
                                                 <div className="twm-jobs-category green"><span className="twm-bg-golden">Temporary</span></div>
                                                 <div className="twm-jobs-amount">$800 <span>/ Month</span></div>
                                                 <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                             </div>
                                         </div>
                                     </li>

                                     <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4>Senior Web Designer<span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                                                <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                     </li>
    
                                     <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic2.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4>Sr. Rolling Stock Technician<span className="twm-job-post-duration">/ 15 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                                                <div className="twm-jobs-amount">$1500 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                     </li>
    
                                     <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic3.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4 className="twm-job-title">IT Department Manager<span className="twm-job-post-duration"> / 6 Month ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                                                <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                     </li>
    
                                     <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic4.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4 className="twm-job-title">Art Production Specialist   <span className="twm-job-post-duration">/ 2 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                                                <div className="twm-jobs-amount">$3000 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                     </li>

                                     <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4>Senior Web Designer<span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                                                <div className="twm-jobs-amount">$2000 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                    </li>
    
                                    <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic2.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4>Sr. Rolling Stock Technician<span className="twm-job-post-duration">/ 15 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                                                <div className="twm-jobs-amount">$2000 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                    </li>
    
                                    <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic3.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4 className="twm-job-title">IT Department Manager<span className="twm-job-post-duration"> / 6 Month ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                                                <div className="twm-jobs-amount">$1800 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                    </li>
    
                                    <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic4.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4 className="twm-job-title">Art Production Specialist   <span className="twm-job-post-duration">/ 2 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                                                <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                    </li>
    
                                    <li>
                                        <div className="twm-jobs-list-style1 mb-5">
                                            <div className="twm-media">
                                                <img src="assets/images/jobs-company/pic5.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="job-detail.html" className="twm-job-title">
                                                    <h4 className="twm-job-title">Recreation &amp; Fitness Worker   <span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                                </a>
                                                <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                                <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-category green"><span className="twm-bg-golden">Temporary</span></div>
                                                <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                                <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                       <div className="twm-jobs-list-style1 mb-5">
                                           <div className="twm-media">
                                               <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                                           </div>
                                           <div className="twm-mid-content">
                                               <a href="job-detail.html" className="twm-job-title">
                                                   <h4>Senior Web Designer<span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                               </a>
                                               <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                               <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                           </div>
                                           <div className="twm-right-content">
                                               <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                                               <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                               <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                           </div>
                                       </div>
                                    </li>
   
                                    <li>
                                       <div className="twm-jobs-list-style1 mb-5">
                                           <div className="twm-media">
                                               <img src="assets/images/jobs-company/pic2.jpg" alt="#" />
                                           </div>
                                           <div className="twm-mid-content">
                                               <a href="job-detail.html" className="twm-job-title">
                                                   <h4>Sr. Rolling Stock Technician<span className="twm-job-post-duration">/ 15 days ago</span></h4>
                                               </a>
                                               <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                               <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                           </div>
                                           <div className="twm-right-content">
                                               <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                                               <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                               <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                           </div>
                                       </div>
                                    </li>
   
                                    <li>
                                       <div className="twm-jobs-list-style1 mb-5">
                                           <div className="twm-media">
                                               <img src="assets/images/jobs-company/pic3.jpg" alt="#" />
                                           </div>
                                           <div className="twm-mid-content">
                                               <a href="job-detail.html" className="twm-job-title">
                                                   <h4 className="twm-job-title">IT Department Manager<span className="twm-job-post-duration"> / 6 Month ago</span></h4>
                                               </a>
                                               <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                               <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                           </div>
                                           <div className="twm-right-content">
                                               <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                                               <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                               <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                           </div>
                                       </div>
                                    </li>
   
                                    <li>
                                       <div className="twm-jobs-list-style1 mb-5">
                                           <div className="twm-media">
                                               <img src="assets/images/jobs-company/pic4.jpg" alt="#" />
                                           </div>
                                           <div className="twm-mid-content">
                                               <a href="job-detail.html" className="twm-job-title">
                                                   <h4 className="twm-job-title">Art Production Specialist   <span className="twm-job-post-duration">/ 2 days ago</span></h4>
                                               </a>
                                               <p className="twm-job-address">ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom</p>
                                               <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                           </div>
                                           <div className="twm-right-content">
                                               <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                                               <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                               <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Vehicle</a>
                                           </div>
                                       </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="pagination-outer">
                                <div className="pagination-style1">
                                    <ul className="clearfix">
                                        <li className="prev"><a href="javascript:;"><span> <i className="fa fa-angle-left"></i> </span></a></li>
                                        <li><a href="javascript:;">1</a></li>
                                        <li className="active"><a href="javascript:;">2</a></li>
                                        <li><a href="javascript:;">3</a></li>
                                        <li><a className="javascript:;" href="javascript:;"><i className="fa fa-ellipsis-h"></i></a></li>
                                        <li><a href="javascript:;">5</a></li>
                                        <li className="next"><a href="javascript:;"><span> <i className="fa fa-angle-right"></i> </span></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>    */}
        </div>
  )
};