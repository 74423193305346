import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { readRecord, updateRecord } from "../../Redux/Action/Action";
import { successToast, warningToast, errorToast } from '../../components/toastify';
import { useTranslation } from 'react-i18next';
import AppContext from '../../appContext';

export default function ChangeEmail() {
   const { t } = useTranslation();
   const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,1000}$/;
   const { setIsLoggedIn, setLoggedUser } = useContext(AppContext);
   const [isChangePassword, setChange] = useState(false);
   const [customerForm, setCustomerdata] = useState({ new_email: "", re_email: "", password: "" })
   const [changePassForm, setChangePassdata] = useState({ previous_password: "", new_password: "", confirm_password: "" })
   const [changePasswordd, setChangePasswordd] = useState(false);
   const [isPassError, setIsPassError] = useState(false);
   const [isPassOneError, setIsPassOneError] = useState(false);
   const [isPassTwoError, setIsPassTwoError] = useState(false);
   const [isPassThreeError, setIsPassThreeError] = useState(false);

   const showhidepaswd = () => {
      setChangePasswordd(!changePasswordd)
   }
   const changePassword = (e) => {
      e.preventDefault();
      const isShow = !isChangePassword;
      setChange(isShow);
   }

   useEffect(() => {

   }, []);

   const handleChange = (e) => {
      const { name, value } = e.target
      setCustomerdata({ ...customerForm, [name]: value });
      if (name === 'password') {
         const isValid = passwordPattern.test(value);
         setIsPassError(!isValid);
      }
   }

   const handleChangePass = (e) => {
      const { name, value } = e.target
      setChangePassdata({ ...changePassForm, [name]: value });
      const isValid = passwordPattern.test(value);
      if (name === 'previous_password') {
         setIsPassOneError(!isValid);
      }
      if (name === 'new_password') {
         setIsPassTwoError(!isValid);
      }
      if (name === 'confirm_password') {
         setIsPassThreeError(!isValid);
      }
   }

   const changeEmailSubmit = (e) => {
      e.preventDefault();
      if (customerForm.new_email !== customerForm.re_email) {
         warningToast("Repeat E-Mail address is different");
         return;
      }
      if (isChangePassword && (changePassForm.new_password !== changePassForm.confirm_password)) {
         warningToast("Confirm password are not matching");
         return;
      }
      if (!isChangePassword && isPassError) {
         warningToast("Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet.");
         return;
      }
      if (isChangePassword && (isPassOneError || isPassTwoError || isPassThreeError)) {
         warningToast("Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet.");
         return;
      }

      let loginUser = {};
      if (localStorage.getItem('vignetteLoginUser')) {
         loginUser = JSON.parse(localStorage.getItem('vignetteLoginUser'))
      }

      let Body = {
         "table": "customers",
         "selectFields": { email: 1, password: 1 },
         "whereCondition": {
            "_id": loginUser._id
         },
         "sortBy": {},
         "limit": "0",
         "populate": [],
         "page": "1"
      }
      readRecord(Body).then(async (res) => {
         if (!isChangePassword && (res['data'][0]['password'] !== customerForm.password)) {
            errorToast("Password is incorrect");
            return;
         } else if (!isChangePassword && (res['data'][0]['email'] === customerForm.new_email)) {
            warningToast("Previous email and New email both are same!");
            return;
         } else if (isChangePassword && (res['data'][0]['password'] !== changePassForm.previous_password)) {
            errorToast("Previous password is incorrect");
            return;
         } else if (isChangePassword && (changePassForm.previous_password === changePassForm.new_password)) {
            warningToast("Previous password and New password both are same!");
            return;
         }
         let Body2 = {
            "record": {
               "email": customerForm.new_email
            },
            "table": "customers",
            "_id": loginUser._id,
            "uniqueFields": {
               "code": customerForm.new_email
            },
            "uniqueFieldErrMsg": "Email alrady exist."
         }
         if (isChangePassword) {
            Body2["record"] = { password: changePassForm.new_password };
         }

         // return;
         updateRecord(Body2).then(async (upRes) => {
            if (upRes.status) {
               if (!isChangePassword) {
                  successToast("Email address changed successfully");
                  loginUser.email = customerForm.new_email;
               } else {
                  successToast("Password changed successfully");
                  loginUser.password = changePassForm.new_password;
               }
               localStorage.setItem("vignetteLoginUser", JSON.stringify(loginUser))
               setLoggedUser(loginUser)
               setCustomerdata({ new_email: "", re_email: "", password: "" })
               setChangePassdata({ previous_password: "", new_password: "", confirm_password: "" })
            } else {
               errorToast(upRes.message);
            }
         })
      })
   }

   if (!isChangePassword) {
      return (
         <form onSubmit={changeEmailSubmit}>
            <div className="panel panel-default">
               <div className="panel-heading wt-panel-heading p-a20">
                  <h4 className="panel-tittle m-a0">{t('Change Email')}</h4>
               </div>
               <div className="panel-body wt-panel-body p-a20 m-b30 ">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="form-group">
                           <label>{t('New email address')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.new_email} onChange={handleChange} name="new_email" type="email"
                                 placeholder="" />
                              <i className="fs-input-icon fas fa-envelope"></i>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-group">
                           <label>{t('E-Mail address (repeat entry)')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.re_email} onChange={handleChange} name="re_email" type="email"
                                 placeholder="" />
                              <i className="fs-input-icon fas fa-at"></i>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-group">
                           <label>{t('Password')}</label>
                           <div className="ls-inputicon-box position-relative d-flex">
                              <input className="form-control" value={customerForm.password} onChange={handleChange} name="password" type={changePasswordd ? "text" : "password"}
                                 placeholder="**********" />
                              <i className="fs-input-icon fas fa-lock"></i>
                              <i className={"position-absolute fa-paswd-show-hide fa-regular " + `${changePasswordd ? "fa-eye" : "fa-eye-slash"}`} onClick={() => { showhidepaswd() }}></i>
                           </div>
                           {isPassError ? <p className="text-danger text-start">{t('Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet')}.</p> : ''}
                        </div>
                     </div>

                     <div className="col-md-12">
                        <div className="text-left">
                           <button type="submit" className="site-button">{t('Save Changes')}</button>
                        </div>
                     </div>

                     <div className="col-md-12 mt-4">
                        <button type="button" onClick={changePassword} className="twm-nav-sign-up site-button outline-primary py-2">{t('Change password')}</button>
                     </div>

                     {/* <div className="col-md-12 mt-2">
                                 <button  type="button" className="twm-nav-sign-up site-button outline-primary py-2">Delete customer account</button>
                              </div> */}

                  </div>
               </div>
            </div>
         </form>
      )
   } else {
      return (
         <form onSubmit={changeEmailSubmit}>
            <div className="panel panel-default">
               <div className="panel-heading wt-panel-heading p-a20">
                  <h4 className="panel-tittle m-a0">
                     <button type="button" onClick={changePassword} className="btn outline-primary py-2"><i className="fs-input-icon fas fa-arrow-left"></i></button>
                     {t('Change password')}
                  </h4>
               </div>
               <div className="panel-body wt-panel-body p-a20 m-b30 ">
                  <div className="row">

                     <div className="col-md-12">
                        <div className="form-group">
                           <label>{t('Your previous password')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={changePassForm.previous_password} onChange={handleChangePass} name="previous_password" type="password"
                                 placeholder="**********" />
                              <i className="fs-input-icon fas fa-lock"></i>
                           </div>
                           {isPassOneError ? <p className="text-danger text-start">{t('Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet')}.</p> : ''}
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-group">
                           <label>{t('New password')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={changePassForm.new_password} onChange={handleChangePass} name="new_password" type="password"
                                 placeholder="**********" />
                              <i className="fs-input-icon fas fa-lock"></i>

                           </div>
                           {isPassTwoError ? <p className="text-danger text-start">{t('Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet')}.</p> : ''}
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-group">
                           <label>{t('New password (repeat)')}</label>
                           <div className="ls-inputicon-box ">
                              <input className="form-control" value={changePassForm.confirm_password} onChange={handleChangePass} name="confirm_password" type="password"
                                 placeholder="**********" />
                              <i className="fs-input-icon fas fa-lock"></i>
                           </div>
                           {isPassThreeError ? <p className="text-danger text-start">{t('Password MUST contain at least 8 characters. MUST contain at least one number and one alphabet')}.</p> : ''}
                        </div>
                     </div>

                     <div className="col-md-12">
                        <div className="text-left">
                           <button type="submit" className="site-button">{t('Submit')}</button>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </form>
      )
   }
};