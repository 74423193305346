import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducers from './Redux/Reducers'

const middleware=[thunk]

if(process.env.NODE_ENV=="development"){
    middleware.push(logger)
}


const store=createStore(rootReducers, composeWithDevTools(applyMiddleware(...middleware)))

export default store