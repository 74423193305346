import React from 'react';
import { Link } from 'react-router-dom';

export default function Affiliate() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (

        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">Affiliate Partner Program from auto-vignette</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li>
                                    <Link to="/home">Home</Link>
                                </li>
                                <li>Affiliate</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full p-t90 p-b90 site-bg-white">
                <div className="">
                    <div className="section-content">
                        <div className="twm-blog-post-h5-wrap container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer mb-5">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text ">
                                                <p>
                                                auto-vignette offers an Affiliate Partner Programme for registration of e-vignettes via our international websites.
                                                </p>
                                                <p>
                                                    With the auto-vignette Affiliate Partner Programme, travel agencies or travel bloggers, for example, can earn an <b> attractive income</b> for registering e-vignettes in Europe.
                                                    Sign up to our Affiliate Partner Programme and start earning an attractive income from e-vignette registrations completed through our international websites. Registration is quick, free of charge and without risk!
                                                </p>
                                                <p>
                                                    Benefit from a strong brand that enjoys the highest reputation among motorists throughout Europe.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{backgroundImage: 'url(assets/images/ftr-bg.jpg)'}}>
                            <div className="container p-5">
                                <h4 className="twm-s-title">The advantages to you of our Affiliate Partner Programme</h4>
                                <ul className="description-list-2">
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        Earn an attractive income on a regular basis
                                    </li>
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        Use of individual promotional material in several languages
                                    </li>
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        Long cookie duration of 30 days
                                    </li>
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        High level of user-friendliness and conversion rate
                                    </li>
                                    <li>
                                        <i className="feather-check-circle"></i>
                                        We are happy to support you with integration
                                    </li>
                                </ul>
                                <div className="twm-job-self-bottom">
                                    <a className="site-button" data-bs-toggle="modal" href="#apply_job_popup" role="button">
                                        Join now
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="twm-blog-post-h5-wrap container pt-5">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text ">
                                                <p>
                                                    Our Affiliate Partner Programme connects your company or your personal blog with the online portal used throughout Europe for electronic vehicle tolls. Millions of motorists use the e-vignette every year for their journeys through Europe.
                                                </p>
                                                <p>
                                                    As a auto-vignette partner, you can integrate individual advertising material on your website and receive payments when a customer registers an e-vignette via our website. Thanks to our optimised website, we have above-average conversion rates that will benefit you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

