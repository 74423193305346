import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CompanyInfo() {
    const { t } = useTranslation();
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{t('Company info')}</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link to="/home">{t('Home')}</Link></li>
                                <li>{t('Company info')}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full p-t90 p-b90 site-bg-white">
                <div className="">
                    <div className="section-content">
                        <div className="twm-blog-post-h5-wrap container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="blog-post twm-blog-post-h5-outer mb-5">
                                        <div className="wt-post-info">
                                            <div className="wt-post-text p-2">
                                                <h4>
                                                {t('www.auto-vignette.com is an internet offering provided by:')}
                                                </h4>
                                                <p>
                                                {t('ILTERLER Ltd, 50 Victory Road,')} <br></br>
                                                {t('SO15 8QZ, Southampton,')} <br></br>
                                                {t('United Kingdom')}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};