import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { successToast, warningToast, errorToast } from '../../components/toastify';
import { sendEmail } from "../../Redux/Action/Action";
import { useTranslation } from 'react-i18next';


export default function HelpSupport() {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email_add, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setSubmit] = useState(false);
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     setLogindata({ ...logindata, [name]: value })
    // }

    const submitHandle = (e) => {
        e.preventDefault();
        setSubmit(true);
        if(!name || !email_add || !description){
            return;
        }
        setIsLoading(true);
        sendEmail({
            name: name,
            email_add: email_add,
            description: description,
            userId: '000000000000000000000000',
            eventname: "Website Enquiry"
        }).then(async (res) => {
            if (res['status']) {
                successToast('You inquiry submitted successfully');
                setName("");
                setEmail("");
                setDescription("");
            } else {
                errorToast(res['message']);
            }
            setIsLoading(false);
            setSubmit(false);
            // navigate("/login");
        }, err => {
            setIsLoading(false);
            errorToast(err);
            console.log("err >>>", err);
        })
    }
    return (
        <div className="page-content">
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
                <div className="overlay-main site-bg-white opacity-01"></div>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{t('Customer service from auto-vignette')}</h2>
                            </div>
                        </div>

                        <div>
                            <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><Link>{t('Home')}</Link></li>
                                <li>{t('Contact Us')}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section-full twm-contact-one">
                <div className="section-content">
                    <div className="container">

                        <div className="contact-one-inner">
                            <div className="twm-blog-post-h5-wrap container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="blog-post twm-blog-post-h5-outer mb-5">
                                            <div className="wt-post-info">
                                                <div className="wt-post-text p-2">
                                                    <p>
                                                    {t('Using the e-vignette is really easy. Please take a look at our')}
                                                         <Link to="/faqs"><b>{t('frequently asked questions')}</b></Link> {t('before contacting our customer service. You’ll generally find the answer to your question there quickly. If you have any other questions about your registration or if you need help, our friendly customer service team will be happy to help you at any time.')}

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    {/* <div className="alert alert-info" role="alert">
                                        Please note: Our support service is available only in German and English.
                                    </div> */}
                                    <div className="contact-form-outer">
                                        <div className=" shadow mb-3 rounded">
                                            <div className="twm-log-reg-form-wrap pb-0">
                                                {/* <div className="twm-log-reg-logo-head">
                                                    <Link to="/home">
                                                        <a>
                                                            <img src="assets/images/logo-dark.png" alt="" className="logo" />
                                                        </a>
                                                    </Link>
                                                </div> */}

                                                <div className="twm-log-reg-inner">
                                                    <div className="twm-log-reg-head">
                                                        <div className="twm-log-reg-logo">
                                                            <span className="log-reg-form-title">{t('Enquiry')}</span>
                                                            {/* <p>Please enter your email address and your chosen password</p> */}
                                                        </div>
                                                    </div>
                                                    <div className='twm-sign-up'>
                                                        <form onSubmit={submitHandle}>
                                                            <div className="twm-tabs-style-2">
                                                                <div className="tab-content" id="myTabContent">
                                                                    <div className="tab-pane fade show active" id="sign-candidate">
                                                                        <div className="row text-start">
                                                                            <div className="col-lg-12">
                                                                                <div className="form-group mb-3">
                                                                                    <input name="Name" value={name} type="text" onChange={(e) => setName(e.target.value)} className="form-control" placeholder={t("Name*")} />
                                                                                    {(isSubmit && !name) ? <p className="mb-0 text-danger">{t('Name is mandatory')}</p> : ''}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <div className="form-group mb-3">
                                                                                    <input name="email" value={email_add} type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder={t("Email*")} />
                                                                                    {(isSubmit && !email_add) ? <p className="mb-0 text-danger">{t('Email address is mandatory')}</p> : ''}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <div className="form-group mb-3">
                                                                                    <textarea name="description" value={description} type="text" onChange={(e) => setDescription(e.target.value)} rows="4" className="form-control h-auto" placeholder={t("Message*")} ></textarea>
                                                                                    {(isSubmit && !description) ? <p className="mb-0 text-danger">{t('Message is mandatory')}</p> : ''}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-12">
                                                                                <div className="form-group">
                                                                                    {/* <Link to="/login"> */}
                                                                                    <button type="submit" className="site-button">{t('Submit')}</button>
                                                                                    {/* </Link> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-head left wt-small-separator-outer">
                                            <h2 className="wt-title">{t('E-mail support')}</h2>
                                            <p>{t('E-mail us. We’ll process your query as quickly as possible')}</p>
                                        </div>

                                        <div className="cons-contact-form">
                                            <div className="row">
                                                <div className="contact-info">
                                                    <div className="contact-info-section">
                                                        <div className="c-info-column">
                                                            <div className="c-info-icon"><i className=" fas fa-envelope"></i></div>
                                                            <p className="pt-2">{t('E-mail us. We’ll process your query as quickly as possible')}</p>
                                                            <h3 className="twm-title"><a href="mailto:info@auto-vignette.com">{t('Write e-mail »')}</a></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* comment by paras */}
                                                {/* <div className="col-md-12 mt-4">
                                                    <Link to="/search_order">
                                                        <button type="submit" className="site-button">Find order</button>
                                                    </Link>
                                                </div> 
                                                <div className="col-md-12 mt-4">
                                                    <Link to="/faqs">
                                                        <button type="button" className="twm-nav-sign-up site-button outline-primary">Go to our FAQs</button>
                                                    </Link>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="contact-info-wrap">

                                        <div className="contact-info">
                                            <div className="contact-info-section">
                                                <div className="c-info-column">
                                                    <div className="c-info-icon"><i className=" fas fa-map-marker-alt"></i></div>
                                                    <h3 className="twm-title">{t('In the bay area?')}</h3>
                                                    <p>{t('ILTERLER Ltd, 50 Victory Road, SO15 8QZ, Southampton, United Kingdom')}</p>
                                                </div>

                                                <div className="c-info-column">
                                                    <div className="c-info-icon custome-size"><i className="fa-brands fa-whatsapp"></i></div>
                                                    <h3 className="twm-title">{t('Feel free to contact us')}</h3>
                                                    <p><a target='_blank' href="https://api.whatsapp.com/send?phone=447711481171">{t('+44 7711481171')}</a></p>
                                                </div>

                                                <div className="c-info-column">
                                                    <div className="c-info-icon"><i className="fas fa-envelope"></i></div>
                                                    <h3 className="twm-title">{t('Support')}</h3>
                                                    <p>{t('info@auto-vignette.com')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

            {isLoading &&
                <div className="sub-loading">
                    <div className="loader">
                        <span className="text-large font-weight-bolder">
                            <img src="/assets/images/loader.svg" />
                        </span>
                    </div>
                </div>
            }
        </div>
    )
};