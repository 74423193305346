import React, { useState, useEffect, useContext } from 'react';
import { readRecord, updateRecord, readCDValuesByCode } from "../../Redux/Action/Action";
import { successToast, errorToast } from '../../components/toastify';
import Select from "react-select";
import AppContext from '../../appContext';
import { useTranslation } from 'react-i18next';

export default function MyProfile() {
   const { t } = useTranslation();
   const { setLoggedUser } = useContext(AppContext);
   const [customerForm, setCustomerdata] = useState({
      company_name: "",
      company_name: "",
      title: "",
      first_name: "",
      last_name: "",
      address: "",
      postcode: "",
      city: "",
      country: "",
      vat_id: ""
   })
   const [isLoading, setIsLoading] = useState(true)
   const [salutationList, setSalutation] = useState([])

   let loginUser = {};
   if (localStorage.getItem('vignetteLoginUser')) {
      loginUser = JSON.parse(localStorage.getItem('vignetteLoginUser'))
   }
   useEffect(() => {
      getUserDetials();
   }, []);


   const getUserDetials = (e) => {
      let Body = {
         "table": "customers",
         "selectFields": {},
         "whereCondition": {
            "_id": loginUser._id
         },
         "sortBy": {},
         "limit": "0",
         "populate": [],
         "page": "1"
      }
      readRecord(Body).then(async (res) => {
         setCustomerdata(res.data[0])
         setIsLoading(false)
      })

      readCDValuesByCode({ code: ['SALTT'] }).then(async (res) => {
         for (let i in res.data['SALTT']) {
            res.data['SALTT'][i].label = res.data['SALTT'][i].value;
         }
         setSalutation(res.data['SALTT'])
         setIsLoading(false)
      })
   }
   const handleChange = (e) => {
      const { name, value } = e.target;
      setCustomerdata({ ...customerForm, [name]: value })
   }
   const changeSalutation = (e, action) => {
      const inputName = action.name;
      console.log("e._id >>", e.code);
      console.log("inputName >>", inputName);
      setCustomerdata({ ...customerForm, [inputName]: e.code });
   }

   const updateCustomerSubmit = (e) => {
      e.preventDefault();

      const Body2 = {
         "record": customerForm,
         "table": "customers",
         "_id": loginUser._id,
         "uniqueFields": {},
         "uniqueFieldErrMsg": "Email alrady exist."
      }
      updateRecord(Body2).then(async (upRes) => {
         if (upRes.status) {
            successToast("Details updated successfully");
            loginUser.company_name = customerForm.company_name;
            loginUser.title = customerForm.title;
            loginUser.first_name = customerForm.first_name;
            loginUser.last_name = customerForm.last_name;
            loginUser.address = customerForm.address;
            loginUser.postcode = customerForm.postcode;
            loginUser.city = customerForm.city;
            loginUser.country = customerForm.country;
            loginUser.vat_id = customerForm.vat_id;
            localStorage.setItem("vignetteLoginUser", JSON.stringify(loginUser));
            setLoggedUser(loginUser);
         } else {
            errorToast(upRes.message);
         }
      })

   }

   return (
      <>
         <form onSubmit={updateCustomerSubmit}>
            <div className="panel panel-default">
               <div className="panel-heading wt-panel-heading p-a20">
                  <h4 className="panel-tittle m-a0">{t('My Profile')}</h4>
               </div>
               <div className="panel-body wt-panel-body p-a20 m-b30 ">
                  <div className="row">
                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                           <label>{t('Company name (optional)')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.company_name} onChange={handleChange} name="company_name" type="text"
                                 placeholder="Company name" />
                              <i className="fs-input-icon fa fa-user "></i>
                           </div>
                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                           <label>{t('Title')}</label>
                           <div className="ls-inputicon-box">
                              {/* <input className="form-control" value={customerForm.title} onChange={handleChange} name="title" type="text"
                                 placeholder="Mr/Ms" /> */}
                              <Select
                                 className="form-control selectInput"
                                 options={salutationList}
                                 value={salutationList.find(obj => obj.code === customerForm.title)}
                                 name="title"
                                 onChange={changeSalutation}
                              />
                              <i className="fs-input-icon fa fa-user "></i>
                           </div>
                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                           <label>{t('First name')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.first_name} onChange={handleChange} name="first_name" type="text"
                                 placeholder="First name" />
                              <i className="fs-input-icon fa fa-user "></i>
                           </div>
                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                           <label>{t('Last Name')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.last_name} onChange={handleChange} name="last_name" type="text"
                                 placeholder="Last Name" />
                              <i className="fs-input-icon fa fa-user "></i>
                           </div>
                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                           <label>{t('Email')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.email} disabled onChange={handleChange} name="email" type="text"
                                 placeholder="Last Name" />
                              <i className="fs-input-icon fa fa-envelope "></i>
                           </div>
                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                           <label>{t('House number and street')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.address} onChange={handleChange} name="address" type="text" placeholder="House number and street" />
                              <i className="fs-input-icon fa fa-user "></i>
                           </div>
                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group city-outer-bx has-feedback">
                           <label>{t('Postcode')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.postcode} onChange={handleChange} name="postcode" type="text" placeholder="75462" />
                              <i className="fs-input-icon fas fa-map-pin"></i>
                           </div>

                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group city-outer-bx has-feedback">
                           <label>{t('Town/city')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.city} onChange={handleChange} name="city" type="text"
                                 placeholder="Texas" />
                              <i className="fs-input-icon fa fa-globe-americas"></i>
                           </div>
                        </div>
                     </div>


                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group city-outer-bx has-feedback">
                           <label>{t('Country')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.country} onChange={handleChange} name="country" type="text"
                                 placeholder="USA" />
                              <i className="fs-input-icon fa fa-globe-americas"></i>
                           </div>

                        </div>
                     </div>

                     <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group city-outer-bx has-feedback">
                           <label>{t('VAT ID no. (optional)')}</label>
                           <div className="ls-inputicon-box">
                              <input className="form-control" value={customerForm.vat_id} onChange={handleChange} name="vat_id" type="text" placeholder="VAT ID no" />
                              <i className="fs-input-icon fas fa-map-marker-alt"></i>
                           </div>

                        </div>
                     </div>

                     <div className="col-lg-12 col-md-12">
                        <div className="text-left">
                           <button type="submit" className="site-button">{t('Submit')}</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </form>

         {isLoading &&
            <div className="sub-loading">
               <div className="loader">
                  <span className="text-large font-weight-bolder">
                     <img src="/assets/images/loader.svg" width={100} />
                  </span>
               </div>
            </div>
         }
      </>
   )
};