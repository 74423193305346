import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { readRecord } from "../Redux/Action/Action";
import { successToast, warningToast, errorToast } from '../components/toastify';
import AppContext from '../appContext';
import { useTranslation } from 'react-i18next';

export default function Login() {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = queryParams.get('params');
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [logindata, setLogindata] = useState({ email_address: "", password: "" });
    const { setIsLoggedIn, setLoggedUser } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false)
    const [changePassword, setChangePassword] = useState(false);


    const showhidepaswd = () => {
        setChangePassword(!changePassword)
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setLogindata({ ...logindata, [name]: value })
    }
    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     navigate("/dashboard/my-profile")
    // }

    const signInSubmit = (e) => {
        e.preventDefault();
        if (!logindata.email_address || !logindata.password) {
            warningToast("Please fill the required field");
            return;
        }
        setIsLoading(true)
        let Body = {
            "table": "customers",
            "selectFields": {},
            "whereCondition": {
                "email": logindata.email_address,
                "password": logindata.password,
            },
            "sortBy": {},
            "populate": [],
            "limit": "1",
            "page": "1"
        }
        readRecord(Body).then(async (res) => {
            setIsLoading(false)
            if (res.status && res.data.length) {
                localStorage.setItem("vignetteLoginUser", JSON.stringify(res.data[0]))
                successToast("Login successfully");
                setIsLoggedIn(true);
                setLoggedUser(res.data[0]);
                console.log("params >>", params);
                if (params && params === 'orders') {
                    navigate("/dashboard/orders");
                } else {
                    navigate("/dashboard/my-profile");  // "/home");
                }
            } else {
                errorToast("Email address and password not correct");
            }

        }, err => { setIsLoading(false) })
    }

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <>
            <div className="page-content p-t120">
                <div className="section-full site-bg-white">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            {/* <div className="col-xl-8 col-lg-6 col-md-5 twm-log-reg-media-wrap">
                            <div className="twm-log-reg-media">
                                <div className="twm-l-media">
                                    <img src="assets/images/login-bg.png" alt=""/>
                                </div>
                            </div>
                        </div> */}
                            <div className="col-xl-4 col-lg-6 col-md-7 shadow mb-3 rounded">
                                <div className="twm-log-reg-form-wrap pb-0">

                                    <div className="twm-log-reg-inner">
                                        <div className="twm-log-reg-head">
                                            <div className="twm-log-reg-logo">
                                                <span className="log-reg-form-title">{t('Log In')}</span>
                                            </div>
                                        </div>
                                        <div className="twm-tabs-style-2">

                                            <div className="tab-content" id="myTab2Content">
                                                <div className="tab-pane fade show active" id="twm-login-candidate">
                                                    <div className="row">
                                                        <form onSubmit={signInSubmit}>
                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-3">
                                                                    <input name="email_address" type="text" value={logindata.email_address} onChange={handleChange} className="form-control" placeholder={t("Email address*")} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-3 position-relative d-flex">
                                                                    <input name="password" type={changePassword ? "text" : "password"} value={logindata.password} onChange={handleChange} className="form-control" required="" placeholder={t("Password*")} />
                                                                    <i className={"position-absolute fa-paswd-show-hide fa-regular " + `${changePassword ? "fa-eye" : "fa-eye-slash"}`} onClick={() => { showhidepaswd() }}></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="twm-forgot-wrap">
                                                                    <div className="form-group mb-3">
                                                                        <div className="form-check">
                                                                            {/* <input type="checkbox" className="form-check-input" id="Password4" />
                                                                    <label className="form-check-label rem-forgot" for="Password4">Remember me  */}
                                                                            <span style={{ cursor: "pointer" }} onClick={() => navigate("/forgetpassword")} className="site-text-primary">{t('Forgot Password')}</span>
                                                                            {/* </label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    {/* <Link to="/dashboard/my-profile"> */}
                                                                    <button type="submit" className="site-button">{t('Log In')}</button>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <span className="center-text-or">{t('Or')}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <Link to="/registration">
                                                                        <button type="submit" className="site-button">
                                                                            {t('Create new Account')}
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="log_with_facebook">
                                                                <i className="fab fa-facebook"></i>
                                                                Continue with Facebook
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="log_with_google">
                                                                <img src="assets/images/google-icon.png" alt=""/>
                                                                Continue with Google
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                {/* <div className="tab-pane fade" id="twm-login-Employer">
                                                <div className="row">

                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input name="username" type="text" required="" className="form-control" placeholder="Usearname*"/>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <input name="email" type="text" className="form-control" required="" placeholder="Password*"/>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-12">
                                                        <div className="twm-forgot-wrap">
                                                            <div className="form-group mb-3">
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="Password4" />
                                                                    <label className="form-check-label rem-forgot" for="Password4">Remember me <a href="javascript:;" className="site-text-primary">Forgot Password</a></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="site-button">Log in</button>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <span className="center-text-or">Or</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="log_with_facebook">
                                                                <i className="fab fa-facebook"></i>
                                                                Continue with Facebook
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="log_with_google">
                                                                <img src="assets/images/google-icon.png" alt=""/>
                                                                Continue with Google
                                                            </button>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading &&
                <div className="sub-loading">
                    <div className="loader">
                        <span className="text-large font-weight-bolder">
                            <img src="/assets/images/loader.svg" />
                        </span>
                    </div>
                </div>
            }
        </>
    )
};