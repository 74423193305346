import React, { useState,useEffect,useContext,useRef } from 'react';
import $ from 'jquery';
import Popup from 'reactjs-popup';
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import './mypopup.css'; 
import { warningToast } from '../components/toastify';
import data from '../data.json'; 
import AppContext from '../appContext';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const licensePlateData = require('../assets/files/licensePlate.json');

function AddlVigDialog({ onClose, selVehicle }) {

    let minDate = new Date();
    let maxDate = new Date();
    const [isOpen, setIsOpen] = useState(true); // Assume initial visibility from props
    const [countryToAdd, setCountryToAdd] = useState(null);
    const [allCountries, setAllCountries] = useState(data.Countries);
    const [allVehicles, setAllVehicles] = useState(data.Vehicles);
    const [allDurations, setAllDurations] = useState(data.Durations);
    const [allSettings, setAllSettings] = useState(data.Settings);
    const imageURL = "https://d2m4rx9jjmzfgf.cloudfront.net/";
    const [selectedVehicleType, setSelectedVehicleType] = useState(selVehicle.value);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState({ _id: '', value: '', absolute_value: 1, price: 0 });
    const [currentDuration, setCurrentDuration] = useState("");
    const [durations, setDurations] = useState([]);
    const { isLoggedIn, setIsLoggedIn, exchangeRates, defaultCurrency, orderSaveAsDraft, setOrderSaveAsDraft } = useContext(AppContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [timeStamp, setTimeStamp] = useState(minDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })); // It's Time Stamp
    const [benefitsBy, setBenefitsBy] = useState("");
    const [flexiChange, setFlexiChange] = useState(0);
    const [flexiTepmChange, setTepmFlexiChange] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const addButtonRef = useRef(null);

    const filterWeekdays = (date) => {
      if (selectedDuration.value === "Weekend") {
          const day = date.getDay();
          return day === 0 || day === 6; // Enable only Sundays (0) and Saturdays (6)
      } else {
          return true; // Enable all the days
      }
    };

    useEffect(() => {
      console.log("countryToAdd",countryToAdd);
      if (countryToAdd !== null) {
         const selCtry = allCountries.find((country) => country._id === countryToAdd._id);
         const vehForCtr = allVehicles.filter((oneVeh) => oneVeh.Countries.includes(countryToAdd._id) && oneVeh.value === selectedVehicleType);
         console.log("Vehicle type "+selectedVehicleType+" for selected country",vehForCtr);
         if (vehForCtr && vehForCtr.length > 0) {
            setSelectedVehicle(vehForCtr[0]); 
            setSelectedDuration({ _id: '', value: '', absolute_value: 1, price: 0 });
            const vehDurKey = vehForCtr[0]._id;
            console.log("vehDurKey",vehDurKey);
            const selDur = allDurations.filter((duration) => duration.Vehicles.includes(vehDurKey));
            if (selDur) {
               const sortedSelDur = selDur.sort((a, b) => a.days - b.days);
               setDurations(sortedSelDur);
            }
            else {
               setDurations([]);    
            }
         }
         else {
            console.log("Sorry no matching vehicles available in the selected country");
         }
      }
    }, [countryToAdd]);

    useEffect(() => {
       console.log("selectedVehicle2",selectedVehicle);
    }, [selectedVehicle]);

    useEffect(() => {
      changeEndDateAsPerDuration(startDate);
    }, [currentDuration, startDate])

    useEffect(() => {
      {/*
      if (durations && durations.length > 0) {
         changeDuration(0);
         addButtonRef.current.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling
      }
      */}
    }, [durations])   

    const colourStyles = {
      control: styles => ({ ...styles, border: '1px solid #97a5ba' })
    };

      // flexi charge check box
    const flexichargefun2 = (e) => {
        if (e.target.checked) {
            setFlexiChange(allSettings.flexiCharge);
            setServiceCharge(0);
        } else {
            setFlexiChange(0);
            setServiceCharge(allSettings.serviceCharge);
        }
    }
  
    function startDateHandler(date) {
      if (date) {
          const checkDate = date.getDate()+''+date.getMonth()
          const checkDate1 = minDate.getDate()+''+minDate.getMonth()
          if(checkDate == checkDate1){
              // buyImmediately();
              // changeEndDateAsPerDuration(date);
          }else{
              // setIsBuyNow(false);
              // setSaveAsDraft('isBuyNow', false);
              setStartDate(date);
              // setSaveAsDraft('start_date', date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }));
              handlerFlexiChange(date);
          }
      }
    }

    function dateSameAsCurrentDate(cmpDate) {
      const checkDate = cmpDate.getDate()+''+cmpDate.getMonth()
      const checkDate1 = minDate.getDate()+''+minDate.getMonth()
      console.log(">>>> Now comparing dates are same?");
      console.log(checkDate,checkDate1);
      if (checkDate === checkDate1) {
          console.log("Yes they are same");
          return true;
      }
      else {
          console.log("Not not same");
          return false;
      }
    }

    function handlerFlexiChange(date) {
          let todayDate = new Date();
          let dateNew = new Date(allSettings.flexiTime * 1000);
          const specificTime = dateNew.toLocaleString([], { hour: 'numeric', minute: '2-digit', hour12: false });
          const [specificHour, specificMinute] = specificTime.split(':');

          todayDate.setHours(specificHour);
          todayDate.setMinutes(specificMinute);
          todayDate.setDate(todayDate.getDate() + Number(allSettings.flexiDays));
          setServiceCharge(allSettings.serviceCharge);
          if (date > todayDate) {
              const flexiDate = new Date(date);
              flexiDate.setHours(specificHour);
              flexiDate.setMinutes(specificMinute);
              flexiDate.setDate(flexiDate.getDate() - (Number(1)));
              const formattedDate = flexiDate.toLocaleString('en-GB', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
              const formattedTime = flexiDate.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true });
              setTimeStamp(formattedTime);
              setBenefitsBy(formattedDate);
              setTepmFlexiChange(allSettings.flexiCharge);
          } else {
              setBenefitsBy("");
              setFlexiChange(0);
              // setFlexiObj({});
          }
  }

    function changeEndDateAsPerDuration(startDate2) {
      if (startDate2 == null) {
        startDate2 = new Date();
      }
      let end_date = new Date(startDate2);
      if (currentDuration === "Weekly") {
          end_date.setDate(startDate2.getDate() + 7);
      }
      if (currentDuration === "Monthly") {
          end_date.setDate(startDate2.getDate() + 30);
      }
      if (currentDuration === "Quarterly") {
          end_date.setDate(startDate2.getDate() + 90);
      }
      if (currentDuration === "Yearly" || currentDuration == 'Annual') {
          end_date.setDate(startDate2.getDate() + 365);
      }
      if (currentDuration == "2-month") {
          end_date.setDate(startDate2.getDate() + 60);
      }
      if (currentDuration == "10-days") {
          end_date.setDate(startDate2.getDate() + 10);
      }
      if (currentDuration == "1-day") {
          end_date.setDate(startDate2.getDate() + 1);
      }
      if (currentDuration == "Weekend") {
          end_date.setDate(startDate2.getDate() + 2);
      }
      setEndDate(end_date);
    }

    const changeTargetCountry = (event) => {
      if (event.status !== 'ACTV') {
         warningToast(t("Vehicles not available for this Country"));
      }
      setCountryToAdd(event);
    };

    const changeDuration = (index) => {
      let clonedata = [...durations];
      if (startDate == null) setStartDate(new Date());
      setCurrentDuration(clonedata[index].value);
      setSelectedDuration(selectedDuration => ({ ...selectedDuration, _id: clonedata[index]._id, value: clonedata[index].value, absolute_value: clonedata[index].absolute_value, price: clonedata[index].price }));
      if (startDateRef.current && endDateRef.current) {
        endDateRef.current.setFocus();
      }
    }

    const { t } = useTranslation();

    {/*
    const handleVehicleClick = (index) => {
      console.log("Index of selected vehicle",index);
      let clonedata = [...vehiclesForCountry];
      setSelectedVehicle({ ...selectedVehicle, _id: clonedata[index]._id, value: clonedata[index].value, filename: clonedata[index].filename }); 
    };
    */}

    const handleClose = () => {
      setIsOpen(false);
      onClose(null); // Call the passed onClose function
    };
  
    const addNewVignette = () => {
      //
      // Build new JSON and return back to the caller to show a new Vignette Row
      //
      let isBuyNow = dateSameAsCurrentDate(startDate);
      console.log("isBuyNow is set as ",isBuyNow);
      const newVig = {
        countryId: countryToAdd._id,
        vehicleId: selectedVehicle._id,
        durationId: selectedDuration._id,
        countryName: countryToAdd.value,
        durationName: currentDuration,
        price: (selectedDuration.price * exchangeRates.price).toFixed(2),
        priceNum: selectedDuration.price,
        flexiCharge: flexiChange,
        startDate: startDate,
        endDate: endDate,
        vehicleImage: imageURL+selectedVehicle.filename,
        vehicleName: selectedVehicle.value,
        countryFilename: countryToAdd.filename,
        processing: countryToAdd.processing,
        isBuyNow: isBuyNow
      };
      console.log("Newly built vig",newVig);
      //
      // We are done, lets close it
      //
      setIsOpen(false);
      onClose(newVig); // Call the passed onClose function
    }

    return (
      <Popup open={isOpen} onClose={handleClose}>
        <div className="mypopup" style={{maxWidth: "700px",minHeight: "500px"}}>
              <br/>
              <h6>{t("Please select a new vignette from the below list")}:</h6>
              <div className="col-md-12 mb-6">
                <Select className="select-border"
                    options={allCountries}
                    onChange={changeTargetCountry}
                    styles={colourStyles}
                    placeholder={t("Select Country")}
                    formatOptionLabel={(option) => (
                    <div className={option.status=='ACTV'?"":"disabledListItem"}>
                      <img
                        src={imageURL+option.filename}
                        alt={option.code}
                        style={{ width: '20px', marginRight: '5px'}}
                      />
                      {option.code} {option.value}
                    </div>
                  )}
                                                            />
              </div>
              <br/>
              {(durations && durations.length>0) ? (
                <div className="col-md-12">
                    <h4>{t('Select duration')}</h4>
                    <div className="form-group">
                        <div className="twm-tabs-style-2">
                            <ul className="nav nav-tabs" id="durationTab" role="tablist">
                                {durations.map((item, index) => (
                                    <li className="nav-item" role="presentation" key={item.code}>
                                        <button onClick={() => changeDuration(index)} className={"nav-link check-btn " + (`${selectedDuration._id}` === item._id ? "active" : "")} data-bs-toggle="tab" data-bs-target="#sign-candidate" type="button">
                                            <div>{t(item.value)}</div>
                                            <small>
                                                {exchangeRates.absolute_value} {(item.price * exchangeRates.price).toFixed(2)}
                                            </small>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            {(!durations.length) ? <small className="text-muted">{t("Vehicles not available")}.</small> : ''}
                        </div>
                    </div>
                </div>
              ) : ''}
              <br/>
              {selectedDuration._id ? (
                <div className="row">  {/* Wrap the DatePickers in a row */}
                  <div className="col-md-6 com-sm-6 d-flex align-items-center"> {/* Adjust column size as needed */}
                    <h4 className='addlVigDate'>{t('Start Date')}</h4>
                    <div className="form-group">
                      <div className="ls-inputicon-box addlVigDate">
                        <DatePicker
                          selected={startDate}
                          filterDate={filterWeekdays}
                          className="form-control"
                          placeholderText={t('Select Start date')}
                          dateFormat="dd/MM/yyyy"
                          minDate={minDate}
                          ref={startDateRef}
                          onChange={(date) => startDateHandler(date)}
                        />
                        <i className="fs-input-icon fa-solid fa-calendar" style={{ top: 6 }}></i>
                      </div>
                      {timeStamp && <p className="mb-0 text-end addlVigDateHide">{t('From')} {timeStamp}</p>}
                    </div>
                  </div>
                  <div className="col-md-6 com-sm-6 d-flex align-items-center"> {/* Adjust column size as needed */}
                    <h4 className='addlVigDate'>{t('End Date')}</h4>
                    <div className="form-group">
                      <div className="ls-inputicon-box addlVigDate">
                        <DatePicker selected={endDate} className="form-control" dateFormat="dd/MM/yyyy" readOnly={true} ref={endDateRef} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : ''}
              {benefitsBy ? (
                  <div className="col-md-12">
                      <div className="twm-candidates-grid-style1 p-0 border-0 form-group">
                          <div className="twm-fot-content m-0 p-0">
                              <div className="wt-post-info text-start p-0">
                                  <div className="wt-post-text p-0">
                                      <h4>{t('Benefits')} <span style={{ fontSize: '14px', fontWeight: 400 }}>({benefitsBy})</span></h4>
                                  </div>
                                  <ul className="description-list-2 m-0 p-0">
                                      <li>
                                          <i className="feather-thumbs-up"></i>
                                          {t('Registration number can be changed')}
                                      </li>
                                      <li>
                                          <i className="feather-thumbs-up"></i>
                                          {t('Travel date can be changed')}
                                      </li>
                                      <li>
                                          <i className="feather-thumbs-up"></i>
                                          {t('Ability to cancel your vignette order')}
                                      </li>
                                  </ul>
                              </div>
                              <div className="twm-left-info">
                                  <div className="form-check mt-2 ">
                                      <input className="form-check-input mt-2" type="checkbox" defaultChecked={flexiChange ? true : false} onClick={flexichargefun2} id="flexCheckChecked2" />
                                      <label className="form-check-label text-them" htmlFor="flexCheckChecked2">
                                          <b>{t('Activate flex service for')} </b> <span style={{ fontSize: "17px" }}><b>{exchangeRates.absolute_value} {(flexiTepmChange * exchangeRates.price).toFixed(2)}</b></span>
                                      </label>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              ) : ''}
              <button type="button" className="mypopup-close" onClick={() => handleClose()}>
                {t("Close")}
              </button>
              <button type="button" ref={addButtonRef} className="mypopup-add" onClick={() => addNewVignette()}>
                {t("Add")}
              </button>
        </div>
      </Popup>
    );
  };
    
  export default AddlVigDialog;
  