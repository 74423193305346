import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AppContext from '../../appContext';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { setIsLoggedIn, loggedUser } = useContext(AppContext);

   let loginUser = {};
   if (localStorage.getItem('vignetteLoginUser')) {
      loginUser = JSON.parse(localStorage.getItem('vignetteLoginUser'))
   }
   const logout = (e) => {
      e.preventDefault();
      setIsLoggedIn(false);
      localStorage.clear();
      navigate("/login");
   }
   return (
      <div className="side-bar-st-1 site-bg-light-purple theiaStickySidebar">
         <div className="twm-candidate-profile-pic">
            {(loggedUser.title && loggedUser.title !== 'Mr.') ? <img src="../assets/images/user-avtar/avtar-girl.png" alt="" />  : <img src="../assets/images/user-avtar/avtar.png" alt="" />}
            <div className="upload-btn-wrapper">
               <div id="upload-image-grid"></div>
               {/* <button className="site-button button-sm">Upload Photo</button>
               <input type="file" name="myfile" id="file-uploader" accept=".jpg, .jpeg, .png" /> */}
            </div>
         </div>
         <div className="twm-mid-content text-center">
            <div className="twm-job-title">
               <h4>{loginUser.first_name} {loginUser.last_name} </h4>
            </div>
            {/* <p>IT Contractor</p> */}
         </div>

         <div className="twm-nav-list-1">
            <ul>
               <li>
                  <NavLink activeClassName="active" to="/dashboard/my-profile">
                     <i className="feather-user"></i>
                     {t('My Profile')}
                  </NavLink>
               </li>
               <li>
                  <NavLink activeClassName="active" to="/dashboard/orders">
                     <i className="feather-shopping-bag"></i>
                     {t('My orders')}
                  </NavLink>
               </li>
               <li>
                  <NavLink activeClassName="active" to="/dashboard/add-vehicle">
                     <i className="feather-truck"></i>
                     {t('Add Vehicle')}
                  </NavLink>
               </li>
               {/* <li>
                  <NavLink activeClassName="active" to="/dashboard/payment-methods">
                     <i className="feather-credit-card"></i> My payment methods
                  </NavLink>
               </li> */}
               {/* <li>
                 <NavLink activeClassName="active" to="/dashboard/automatic-extensions">
                     <a>
                        <i className="feather-rotate-cw"></i> My automatic extensions
                     </a>
                  </NavLink>
               </li> */}
               <li>
                  <NavLink activeClassName="active" to="/dashboard/change-email">
                     <i className="feather-map"></i> {t('Change Email/Password')}
                  </NavLink>
               </li>
               <li>
                  <Link onClick={logout}>
                     <i className="feather-log-out"></i> {t('Log out')}
                  </Link>
               </li>
            </ul>
         </div>
      </div>
   )
};