import React from 'react';

export default function PaymentMethods() {
  return (
   <form>
      <div className="panel panel-default">
         <div className="panel-heading wt-panel-heading p-a20">
            <h4 className="panel-tittle m-a0">My payment methods</h4>
         </div>
         <div className="panel-body wt-panel-body p-a20 m-b30 ">
            <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                     <div className="form-group">
                        <label>Add new payment method</label>
                        <div className="ls-inputicon-box">
                              <input className="form-control" name="company_name" type="text" placeholder="My payment methods"/>
                              <i className="fs-input-icon feather-credit-card"></i>
                        </div>
                     </div>
                  </div> 
                  <div className="col-lg-12 col-md-12">
                     <div className="text-left">
                        <button type="button" className="site-button">Add</button>
                     </div>
                  </div>
            </div>
         </div>
      </div>
   </form>
  )
};