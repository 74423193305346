import React from 'react';
import { Link } from 'react-router-dom';

export default function SearchOrder() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div className="page-content">
      <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: 'url("assets/images/banner/1.jpg")' }}>
        <div className="overlay-main site-bg-white opacity-01"></div>
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title">Find order</h2>
              </div>
            </div>

            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li><Link to="/home">Home</Link></li>
                <li>Find order</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div className='container m-t90 m-b90'>
        <div className="twm-log-reg-inner twm-blog-post-h5-outer">
          <div className="alert alert-info" role="alert">
            Once you’ve entered your order data, you’ll be able to view your order status and, if necessary, download your registration confirmation.
          </div>
          <div className="twm-log-reg-head">
            <div className="twm-log-reg-logo">
              <span className="log-reg-form-title text-left">Vehicle details</span>
            </div>
          </div>
          <div className='twm-sign-up'>
            <form>
              <div className="twm-tabs-style-2">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="sign-candidate">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                        <label className="form-check-label login-label">In which country is your vehicle registered?</label>
                          <input name="email" type="text" className="form-control" required="" placeholder="Country" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                        <label className="form-check-label login-label">Registration number (without country code)</label>
                          <input name="phone" type="text" className="form-control" required placeholder="Phone" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                        <label className="form-check-label login-label">Your e-mail address</label>
                        <input name="email" type="text" className="form-control" required="" placeholder="Email" />
                        </div>
                      </div>

                      <div className="twm-job-self-bottom w-auto">
                          <a className="site-button" role="button">
                            Find order
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
};